import { createReducer } from 'reduxsauce'
import { convertRawJob } from 'types/jobs'
// Local deps
import { INITIAL_STATE } from './initialState'
import { JobsTypes } from './actions'

export const getJobsForUserSuccess = (state, { jobs }) => {
  return state.merge({
    progressJobs: jobs.map(convertRawJob),
  })
}

export const getJobsForUserLoading = state => {
  return state.merge({})
}

export const getJobsForUserFailure = state => {
  return state.merge({})
}

export const reducer = createReducer(INITIAL_STATE, {
  [JobsTypes.GET_JOBS_FOR_USER_LOADING]: getJobsForUserLoading,
  [JobsTypes.GET_JOBS_FOR_USER_FAILURE]: getJobsForUserFailure,
  [JobsTypes.GET_JOBS_FOR_USER_SUCCESS]: getJobsForUserSuccess,
})
