import { showErrorMessage } from 'utils/api'
import axios from 'utils/axios'

/**
 * Retrieves a download token for a given mission
 * @param {String} missionId
 * @returns {String} download token
 */
export async function getDownloadMissionToken(missionId) {
  try {
    const { data: { data: { token } } } = await axios.get(`/missions/${missionId}/download_token`)
    return token
  } catch (e) {
    showErrorMessage(e)
  }
}