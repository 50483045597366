import { path } from 'ramda'
// Project deps
import { JobType } from 'types/jobs'
import { ArtifactTypes } from 'types/artifacts'
// Local deps
import { findById, existsById, filterByProjectId } from 'utils/list'
import { getJobsForArtifact, isJobRunDone, isJobRunSucceded, isSpatialFuserJob } from 'utils/jobs'
import { isInputJobIO, isOutputJobIO } from 'utils/jobIOs'
import { isJobRunSuccess } from './jobRuns'

export const getPipelineOutputArtifacs = jobs => {
  return jobs.reduce((allArtifacts, job) => [
    ...allArtifacts,
    ...job.job_ios
      .filter(job_io => isOutputJobIO(job_io.job_io_type))
      .map(job_io => job_io.artifact.id),
  ], [])
}
export const getPipelineInputArtifacs = jobs => {
  return jobs.reduce((allArtifacts, job) => [
    ...allArtifacts,
    ...job.job_ios
      .filter(job_io => isInputJobIO(job_io.job_io_type))
      .map(job_io => job_io.artifact.id),
  ], [])
}

/**
* Given the list of pipeline templates, returns a list of their names.
*
* TODO: This will be an error source, since the user can create multiple pipelines with the same name
* AND we're currently not checking for whether the pipelines have different names.
*/
export function getPipelineTemplateNames (pipelineTemplates) {
  return pipelineTemplates.map(pipelineTemplate => pipelineTemplate.name)
}

/**
* Given the list of pipeline templates and a pipeline template name, returns the corresponding template.
* @param name The name of the pipeline to search for.
* @param pipelineTemplates The list of pipeline templates.
* @return The pipeline with the name `name`.
*/
export function getPipelineTemplateByName (name, pipelineTemplates) {
  return pipelineTemplates.find(pipelineTemplate => pipelineTemplate.name === name)
}

/**
* Returns a list of all output artifacts in a pipeline template.
* @param template The pipeline template.
* @return A list of all output artifacts.
*/
export function getOutputArtifacts (template) {
  const { jobs, artifacts } = template
  const outputArtifacts = jobs.flatMap(job => (job.outputs || []).map(output => output.templateId))
  return artifacts.filter(templateArtifact => outputArtifacts.some(id => id === templateArtifact.templateId))
}

/**
* Returns a list of all output artifacts in a pipeline template.
* @param template The pipeline template.
* @return A list of all output artifacts.
*/
export function getVisibleOutputArtifacts (template) {
  const { jobs, artifacts } = template
  const outputArtifacts = jobs.flatMap(job => (job.outputs || []).map(output => output.templateId))
  return artifacts
    .filter(templateArtifact => outputArtifacts.some(id => id === templateArtifact.templateId))
    .filter(({ display = true }) => display)
}

/**
* Given a template returns all input artifacts.
* This function is used to find the artifacts which the user must configure.
* @param pipelineTemplate The pipeline template.
* @return The input artifacts of this template.
*/
export function getInputArtifacts (pipelineTemplate) {
  const { jobs, artifacts } = pipelineTemplate
  const inputArtifacts = jobs.flatMap(job => (job.inputs || []).map(input => input.templateId))
  return artifacts.filter(templateArtifact => inputArtifacts.some(id => id === templateArtifact.templateId))
}

/**
* Given a template returns all input artifacts which are not output artifacts of previous jobs.
* This function is used to find the artifacts which the user must select.
* @param pipelineTemplate The pipeline template.
* @return The user input artifacts of this template.
*/
export function getUserInputArtifacts (pipelineTemplate) {
  if (pipelineTemplate) {
    const { jobs, artifacts } = pipelineTemplate
    // Technically computing the input artifacts is not necessary, but for the sake of safety we do so anyway.
    const inputArtifacts = jobs.flatMap(job => (job.inputs || []).map(input => input.templateId))
    const outputArtifacts = jobs.flatMap(job => (job.outputs || []).map(output => output.templateId))
    return artifacts.filter(templateArtifact => {
      const templateArtifactId = templateArtifact.templateId
      return inputArtifacts.some(id => id === templateArtifactId) &&
        outputArtifacts.every(id => id !== templateArtifactId)
    })
  }
  return []
}

const getResult = func => (...args) => {
  return typeof func === 'function' ? func(...args) : func
}

function mergeRanges (a, b, ...restArgs) {
  return {
    min: Math.max(getResult(a.min)(...restArgs), getResult(b.min)(...restArgs)),
    max: Math.min(getResult(a.max)(...restArgs), getResult(b.max)(...restArgs)),
    missionMin: Math.max(getResult(a.missionMin)(...restArgs), getResult(b.missionMin)(...restArgs)),
    missionMax: Math.min(getResult(a.missionMax)(...restArgs), getResult(b.missionMax)(...restArgs)),
  }
}

export function getArtifactRange (templateId, pipelineTemplate, ...restArgs) {
  const { jobs } = pipelineTemplate
  const jobIOs = jobs.flatMap(
    job => [...(job.inputs || []), ...(job.outputs || [])]
      .filter(jobIO => jobIO.templateId === templateId)
      .map(jobIO => ({
        min: jobIO.min,
        max: jobIO.max,
        missionMin: path(['mission', 'min'], jobIO) || jobIO.min,
        missionMax: path(['mission', 'max'], jobIO) || jobIO.max,
      })),
  )
  return jobIOs.reduce((a, b) => mergeRanges(a, b, ...restArgs), {
    min: 0,
    max: 100,
    missionMax: 100,
    missionMin: 0,
  })
}

/**
 * Returns all pipelines which belong to the project with the id `projectId`.
 * @param projectId The id of the project.
 * @param pipelineList The state of the pipeline-list component.
 * @return All pipelines of the project with the id `projectId`.
 */
export function getPipelinesForProject (projectId, pipelines) {
  return filterByProjectId(projectId, pipelines)
}

export function getPipelineIdsForProject (projectId, pipelines) {
  return getPipelinesForProject(projectId, pipelines).map(pipeline => pipeline.id)
}

export function getNewPipelineIds (oldPipelineList, newPipelineList) {
  return newPipelineList
    .filter(pipeline => !existsById(pipeline.id, oldPipelineList))
    .map(pipeline => pipeline.id)
}

export function getJustCompletedPipelineIds (oldPipelineList, newPipelineList) {
  return newPipelineList
    .filter(newPipeline => {
      const oldPipeline = findById(newPipeline.id, oldPipelineList)
      return oldPipeline &&
        !isJobRunSuccess(oldPipeline.pipelineState) &&
        isJobRunSuccess(newPipeline.pipelineState)
    })
    .map(pipeline => pipeline.id)
}

export function getPipelinesForArtifact (artifactId, jobIOs, jobs, pipelines) {
  return getJobsForArtifact(artifactId, jobIOs, jobs).map(({ pipelineId }) => findById(pipelineId, pipelines))
}

export function getProducerPipelinesForArtifact (artifactId, jobIOs, jobs, pipelines) {
  const outputIOs = jobIOs.filter(jobIO => isOutputJobIO(jobIO.ioType))
  return getPipelinesForArtifact(artifactId, outputIOs, jobs, pipelines)
}

export function getPotreesForPipeline (
  currentPipeline,
  pipelines,
  artifacts,
  outputArtifactsOfPipeline,
  jobIOs,
  relatedJobs,
  relatedJobIOs,
) {
  const potreeArtifacts = []
  let potreePipelines = []
  let spatialFuserProducerId = ''
  if (currentPipeline.job_types.indexOf(JobType.SPATIAL_FUSER) >= 0 && isJobRunSuccess(currentPipeline.pipeline_state)) {
    const jobIO = jobIOs.find(currentJobIO => isOutputJobIO(currentJobIO.job_io_type))
    if (jobIO) {
      const pipelineIdsForArtifact = relatedJobs.map(({ pipeline }) => pipeline.id)
      potreePipelines = pipelines.filter(pipeline => pipelineIdsForArtifact.indexOf(pipeline.id) >= 0).reverse()
      outputArtifactsOfPipeline.forEach(artifact => {
        const jobIoAsInputs = relatedJobIOs.filter(_jobIO =>
          _jobIO.artifact.id === artifact.id && isInputJobIO(_jobIO.job_io_type),
        )
        jobIoAsInputs.forEach(jobIoAsInput => {
          const outputPotreeArtifactJobIO = relatedJobIOs.find(_jobIO =>
            jobIoAsInput.job.id === _jobIO.job.id && isOutputJobIO(_jobIO.job_io_type),
          )
          if (outputPotreeArtifactJobIO) {
            const outputPotreeArtifact = artifacts.find(
              artifact => artifact.id === outputPotreeArtifactJobIO.artifact.id &&
                artifact.artifactType === ArtifactTypes.POTREE,
            )
            if (outputPotreeArtifact) {
              potreeArtifacts.push(outputPotreeArtifact)
            }
          }
        })
      })
    }
  }
  if (currentPipeline.job_types.indexOf(JobType.POTREE_CONVERTER) >= 0) {
    const inputJobIO = jobIOs.find(currentJobIO => isInputJobIO(currentJobIO.job_io_type))
    const outputJobIO = jobIOs.find(currentJobIO => isOutputJobIO(currentJobIO.job_io_type))
    if (inputJobIO) {
      const sfJob = relatedJobs.find(_job => isSpatialFuserJob(_job.job_type))
      if (sfJob) {
        spatialFuserProducerId = sfJob.pipeline.id
      }
    }
    if (outputJobIO) {
      const artifact = findById(outputJobIO.artifact.id, artifacts)
      potreeArtifacts.push(artifact)
    }
  }
  return {
    potreeArtifacts: potreeArtifacts.sort((a, b) => new Date(b.created).getTime() - new Date(a.created).getTime()),
    potreePipelines: potreePipelines.sort((a, b) => new Date(b.created).getTime() - new Date(a.created).getTime()),
    spatialFuserProducerId,
  }
}

export function isSfOrPotreePipeline (pipeline) {
  return pipeline.job_types.indexOf(JobType.SPATIAL_FUSER) >= 0 ||
    pipeline.job_types.indexOf(JobType.POTREE_CONVERTER) >= 0
}

export function isAnalyzePipeline ({ job_types }) {
  return job_types.indexOf(JobType.NL_ANALYZE_NAVIGATION_ROVER) >= 0 ||
    job_types.indexOf(JobType.NL_ANALYZE_REFERENCE_STATION) >= 0 ||
    job_types.indexOf(JobType.NL_ANALYZE_TRAJECTORY) >= 0 ||
    job_types.indexOf(JobType.NL_ANALYZE_LIDAR_DATA) >= 0
}

/**
 * Checks if some of the pipeline is processing
 * @param {array} pipelines
 */
export const isSomePipelineProcessing = pipelines => {
  return pipelines.some(({ pipeline_state }) => !isJobRunDone(pipeline_state))
}

export const isPipelineSucceded = pipeline => {
  const { pipeline_state } = pipeline
  return isJobRunSucceded(pipeline_state)
}

/**
 * Checks if some of the pipeline is processing
 * @param {array} pipelines
 */
export const isSomePipelineSucceded = pipelines => {
  return pipelines.some(({ pipeline_state }) => isJobRunSucceded(pipeline_state))
}
