import { createSelector } from 'reselect'
import { ArtifactTypes } from 'types/artifacts'

const getArtifactsByType = (state, type) => state.pipelineWizard.get('selectedArtifactsByType')[type] || []
const getDatumsByType = (state, type) => state.pipelineWizard.get('selectedDatumsByType')[type] || {}

export const getSelectedArtifactsByType = createSelector(
  [getArtifactsByType, (_, type) => type],
  (artifactsByType, type) => artifactsByType || []
)

export const getSelectedPolygons = state => getSelectedArtifactsByType(state, ArtifactTypes.POLYGON)

export const getCurrentCreationStage = state => state.pipelineWizard.get('pipelineCreation').creationStage

export const getPipelineTemplate = state => state.pipelineWizard.get('pipelineTemplate')

export const getSelectedTemplateArtifacts = state => state.pipelineWizard.get('selectedTemplateArtifacts')

export const getSelectedTrajectoryArtifacts = state => getSelectedArtifactsByType(state, ArtifactTypes.TRAJECTORY)
export const getSelectedTrajectoryDatums = state => getDatumsByType(state, ArtifactTypes.TRAJECTORY)

export const getSelectedReferenceStationArtifacts = state => getSelectedArtifactsByType(state, ArtifactTypes.REFERENCE_STATION)
export const getSelectedReferenceStationDatums = state => getDatumsByType(state, ArtifactTypes.REFERENCE_STATION)

export const getSelectedNavRoverArtifacts = state => getSelectedArtifactsByType(state, ArtifactTypes.NAVIGATION_ROVER)
export const getSelectedCameraArtifacts = state => getSelectedArtifactsByType(state, ArtifactTypes.CAMERA_DATA)
export const getSelectedLidarArtifacts = state => getSelectedArtifactsByType(state, ArtifactTypes.LIDAR_DATA)
export const getSelectedReconArtifacts = state => getSelectedArtifactsByType(state, ArtifactTypes.RECON_DATA)
export const getSelectedGCPArtifacts = state => getSelectedArtifactsByType(state, ArtifactTypes.GROUND_CONTROL_POINTS)
export const getSelectedPointcloudArtifacts = state => getSelectedArtifactsByType(state, ArtifactTypes.POINTCLOUD)
export const getSelectedGeotiffArtifacts = state => getSelectedArtifactsByType(state, ArtifactTypes.GEOTIFF)

export const getArtifactOptions = state => state.pipelineWizard.get('artifactOptions')

export const getJobOptions = state => state.pipelineWizard.get('jobOptions')

export const getConfigureStep = state => state.pipelineWizard.get('configureStep')
export const getWizardStep = state => state.pipelineWizard.get('wizardStep')

export const getCloneArtifactOptions = (state, artifactId) => state.pipelineWizard.get('cloneOptions').artifactOptions[artifactId]
export const getCloneJobOptions = (state, jobId) => state.pipelineWizard.get('cloneOptions').jobOptions[jobId]
export const getCloneJobRunOptions = state => state.pipelineWizard.get('cloneOptions').jobRunOptions

export const createPipelineIsLoading = state => state.pipelineWizard.get('createPipelineIsLoading')
export const getDoneSteps = (state, key) => state.pipelineWizard.get('doneSteps')[key] || []

export const getPreselectState = state => state.pipelineWizard.get('preselect')

export const getAdditionalJobRunOptions = state => state.pipelineWizard.get('additionalJobRunOptionsByArtifacts')
export const getAdditionalJobIoOptions = state => state.pipelineWizard.get('additionalJobIoOptionsByArtifacts')
export const getArtifactAdditionalJobRunOptions = (state, id) => state.pipelineWizard.get('additionalJobRunOptionsByArtifacts')[id]
export const getNavIntervalsIndex = state => state.pipelineWizard.get('navIntervalIndex')
export const getArtifactNavIntervalsIndex = (state, id) => state.pipelineWizard.get('navIntervalIndex')[id]
export const isCloningPipeline = state => !!state.pipelineWizard.get('cloningPipeline')

export const getGCPIsLoading = state => !!state.app.get('getGCPIsLoading')
