import React, { useEffect } from 'react'
import ReactDOM from 'react-dom'
import * as Sentry from '@sentry/react'
import { I18nextProvider } from 'react-i18next'
import { Router } from 'react-router-dom'
import { Provider } from 'react-redux'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import MomentUtils from '@date-io/moment'
import { PersistGate } from 'redux-persist/lib/integration/react'
import { ToastContainer } from 'react-toastify'
import ReactGA from 'react-ga4'
import 'react-toastify/dist/ReactToastify.css'
// Project deps
import App from 'components/App/App'
import i18next from 'i18n'
// Local deps
import * as serviceWorker from './serviceWorker'
import createStore from './store'
import './style.css'
import config, { isProductionEnvironment } from 'config'

const { store, persistor, history } = createStore()
const SentryApp = Sentry.withProfiler(App)

const Main = props => {
  const isProduction = isProductionEnvironment()
  useEffect(() => {
    if (isProduction) {
      ReactGA.initialize(config.GA_MEASUREMENT_ID)
    }
  }, [isProduction])
  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <I18nextProvider i18n={i18next}>
            <Router history={history}>
              <SentryApp />
            </Router>
            <ToastContainer autoClose={5000} />
          </I18nextProvider>
        </PersistGate>
      </Provider>
    </MuiPickersUtilsProvider>
  )
}

ReactDOM.render(<Main/>, document.getElementById('root'))
serviceWorker.unregister()
