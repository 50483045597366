import * as Sentry from '@sentry/react'
import { merge } from 'lodash'
import i18n from 'i18n'
import history from 'browserHistory'

export const lidarmillCookies = {
  'LIDARMILL_TERMS': 'lidarmill_terms_accepted',
  'LIDARMILL_UPDATE': 'lidarmill_update',
  'LIDARMILL_UPDATE_ACCEPTED': 'lidarmill_update_accepted',
}

export const storageId = {
  version: 'lidarmillVersion',
  settings: 'lidarmillUserSettings',
}
export const userManualUrl = 'https://docs.phoenixlidar.com/lidarmill-cloud/introduction'
export const trainingVideoUrl = 'https://www.youtube.com/watch?v=wKIlmJcoHsY&feature=youtu.be'
export const grafanaUrl = 'https://servermonitoring.phoenixlidar.com/d/YSs9MaDmz/node-exporter-full?orgId=1'

export const trainingVideos = [
  {
    label: i18n.t('tutorial.overview'),
    url: 'https://youtu.be/bgIK0jfE5Tc',
  },
]

export const Env = {
  LOCAL: 'local',
  DEVELOPMENT: 'development',
  PRODUCTION: 'production',
  BAYER: 'bayer',
}

const CESIUM_TOKEN = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiI1NTg0MjRkMC1mYzNjLTQ5ZGItOTJkMC0xZjNhZjQ3MTRmY2IiLCJpZCI6MTYyMjcsInNjb3BlcyI6WyJhc2wiLCJhc3IiLCJhc3ciLCJnYyJdLCJpYXQiOjE1Njk5NDYxNTd9.JpcKsC5AE9oANQ0euCuJ_l21Y5UZcgMM_sswSayAY2o'
const SENTRY = 'https://24edbc6c181b4b1d873fae68bd96b15a@o368987.ingest.sentry.io/5239201'
const DROPBOX_API_KEY = 'xz2urq6ecc1ywgb'
const DEV_STRIPE_API_KEY = 'pk_test_0EwcE1YJ8IpVD9nKhNsw7UV4'
const PROD_STRIPE_API_KEY = 'pk_live_k0wQ8EeEKKAJ4NsTU21j5ZS3'
const DATETIME_FORMAT = 'YYYY-MM-DD HH:mm:ss'
const DATE_FORMAT = 'YYYY-MM-DD'
const API_LICENSING_BASE_CLIENT = 'https://licensing.phoenixlidar.com/api/v1/client'
const RESOURCES_API_BASE = 'https://resources.phoenixlidar.com/api/v1'

const mergeWithDefaults = settings => {
  return merge(settings, {
    API_LICENSING_BASE_CLIENT,
    RESOURCES_API_BASE,
    CESIUM_TOKEN,
    DROPBOX_API_KEY,
    DATETIME_FORMAT,
    DATE_FORMAT,
    SENTRY,
    GA_MEASUREMENT_ID: 'G-L1JV2JEPXV',
    BASE_MAP_URL: 'https://osm.phoenixlidar.com/tile/{z}/{x}/{y}.png',
    SATELLITE_MAP_URL: 'https://api.maptiler.com/tiles/satellite-v2/{z}/{x}/{y}.jpg?key=DvTtxRsoL7joZW7j1E5G',
  })
}

const envs = {
  local: mergeWithDefaults({
    ENV: Env.LOCAL,
    API_BASE: 'https://api.develop.lidarmill.com',
    STRIPE_API_KEY: DEV_STRIPE_API_KEY,
    ACCOUNTS_BASE: 'https://accounts.develop.phoenixlidar.com',
    ACCOUNTS_API_BASE: 'https://api.accounts.develop.phoenixlidar.com',
    ACCOUNTS_API_BASE_CLIENT: 'https://api.accounts.develop.phoenixlidar.com/api/v1/client',
    HOSTNAMES: ['local.lidarmill', 'localhost', '127.0.0.1'],
  }),
  dev: mergeWithDefaults({
    ENV: Env.DEVELOPMENT,
    API_BASE: 'https://api.develop.lidarmill.com',
    STRIPE_API_KEY: DEV_STRIPE_API_KEY,
    ACCOUNTS_BASE: 'https://accounts.develop.phoenixlidar.com',
    ACCOUNTS_API_BASE: 'https://api.accounts.develop.phoenixlidar.com',
    ACCOUNTS_API_BASE_CLIENT: 'https://api.accounts.develop.phoenixlidar.com/api/v1/client',
    HOSTNAMES: ['dev.lidarmill', 'app.develop.lidarmill.com', 'develop.lidarmill.com'],
  }),
  testing: mergeWithDefaults({
    ENV: Env.DEVELOPMENT,
    API_BASE: 'https://api.testing.lidarmill.com',
    STRIPE_API_KEY: DEV_STRIPE_API_KEY,
    ACCOUNTS_BASE: 'https://accounts.testing.phoenixlidar.com',
    ACCOUNTS_API_BASE: 'https://api.accounts.testing.phoenixlidar.com',
    ACCOUNTS_API_BASE_CLIENT: 'https://api.accounts.testing.phoenixlidar.com/api/v1/client',
    HOSTNAMES: ['app.testing.lidarmill.com', 'testing.lidarmill.com', 'develop.lidarmill.com'],
  }),
  prod: mergeWithDefaults({
    ENV: Env.PRODUCTION,
    API_BASE: 'https://api2.lidarmill.com',
    STRIPE_API_KEY: PROD_STRIPE_API_KEY,
    ACCOUNTS_BASE: 'https://accounts.phoenixlidar.com',
    ACCOUNTS_API_BASE: 'https://api.accounts.phoenixlidar.com',
    ACCOUNTS_API_BASE_CLIENT: 'https://api.accounts.phoenixlidar.com/api/v1/client',
    HOSTNAMES: ['app2.lidarmill.com', 'app.lidarmill.com'],
  }),
  bayer: mergeWithDefaults({
    ENV: Env.BAYER,
    API_BASE: 'https://api.bayer.lidarmill.com',
    CESIUM_TOKEN,
    STRIPE_API_KEY: PROD_STRIPE_API_KEY,
    ACCOUNTS_BASE: 'https://accounts.phoenixlidar.com',
    ACCOUNTS_API_BASE: 'https://api.accounts.phoenixlidar.com',
    ACCOUNTS_API_BASE_CLIENT: 'https://api.accounts.phoenixlidar.com/api/v1/client',
    HOSTNAMES: ['bayer.lidarmill.com']
  }),
}

const calculateConfig = () => {
  const { hostname } = window.location
  const env = Object.keys(envs).find(key => (envs[key].HOSTNAMES || []).indexOf(hostname) > -1) || 'local'
  return envs[env]

}

const config = calculateConfig()
if (config.SENTRY && config.ENV !== Env.LOCAL) {
  Sentry.init({
    dsn: config.SENTRY,
    environment: config.ENV,
    release: process.env.REACT_APP_LIDARMILL_VERSION,
    integrations: [
      Sentry.reactRouterV5BrowserTracingIntegration({ history }),
      Sentry.browserProfilingIntegration()
    ],
    tracesSampleRate: 1.0,
    profilesSampleRate: 1.0,
  })
}
export const LidarmillSentry = Sentry
export const isProductionEnvironment = () => config.ENV === Env.PRODUCTION
export const isBayerEnvironment = () => config.ENV === Env.BAYER
export const isLocalEnvironment = () => config.ENV === Env.LOCAL
export const isLocalOrDevelopEnvironment = () => config.ENV === Env.LOCAL || config.ENV === Env.DEVELOPMENT

export default config
