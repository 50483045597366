import React from 'react'
import { path, omit } from 'ramda'
import i18n from 'i18n'
import moment from 'moment'
// Material UI
import IconSuccess from '@material-ui/icons/Done'
import IconFailure from '@material-ui/icons/Close'
import IconUploading from '@material-ui/icons/CloudUpload'
import IconWaiting from '@material-ui/icons/QueryBuilder'
import IconCancel from '@material-ui/icons/Cancel'
// Project deps
import {
  ArtifactTypes,
  isRefstationAnalyzeResult,
  isLdrFileParserInfo,
  isCamFileParserInfo,
  isPlpLdrInfo,
  ArtifactStatuses,
  PrettyArtifactStatuses,
  isReconDataArtifact,
  isTrajectoryArtifact,
  isPointcloudArtifact,
  isCameraArtifact,
  isLidarArtifact,
  isRefStationArtifact,
  isNavRoverArtifact,
  isGCPArtifact,
  isGeotiffArtifact,
  isDocumentationArtifact,
  isPolygonArtifact,
} from 'types/artifacts'
import config, { isProductionEnvironment } from 'config'
import {
  CRSUserDisplayFields,
  NavlabOptionsDisplayFields,
  SpatialFuserOptionsDisplayFields,
  ReferenceStationOptionsDisplayFields,
} from 'templates/CRS/constants'
import { isCamFileType } from 'modules/upload/file-types/cam'
import { isLdrFileType } from 'modules/upload/file-types/ldr'
// Local deps
import { filterByProjectId, findById, makeUnique } from 'utils/list'
import { getJobIdsForPipeline, isNavLabJob, isSpatialFuserJob } from 'utils/jobs'
import { getJobIOsForJob, isOutputJobIO } from 'utils/jobIOs'
import { gpsMin, gpsMax, GpsTimeIntervalState, gpsTimeToUTC, interpolateGpsTime, timeFromGpsSeconds, transformLegacyGpsTimeInterval, getTow, transformLegacyGpsTime } from 'utils/gpsTime'
import { keepProps } from 'utils/dict'
import { isSomePipelineProcessing } from 'utils/pipelines'
import { getNumberOfWeeks, getUTC, prettifyTime } from 'utils/dateTime'
import { GpsStandardTime } from 'utils/time'
import { MapBackendNameToFrontend, REF_STATION_BACKEND_NAME } from 'templates/constants'
import { LasTimeFormat } from 'modules/upload/file-types/las'
import { isLadybugCamera } from 'modules/upload/file-types/utils'
import { NUMBER_OF_IMAGES } from 'types/importWizard'
import { baseEnvironment } from './permissions'

const ArtifactTypeTranslations = Object.values(ArtifactTypes).reduce((allTranslations, value) => {
  return {
    ...allTranslations,
    [value]: i18n.t(`artifact.type.${value}`),
  }
}, {})

const ArtifactTypeAbbreviations = {
  [ArtifactTypes.NAVIGATION_ROVER]: 'NAV',
  [ArtifactTypes.NAVIGATION_ROVER_APPLANIX]: 'NAV',
  [ArtifactTypes.REFERENCE_STATION]: 'REF',
  [ArtifactTypes.GROUND_CONTROL_POINTS]: 'GCP',
  [ArtifactTypes.TRAJECTORY]: 'TRJ',
  [ArtifactTypes.CAMERA_DATA]: 'CAM',
  [ArtifactTypes.LIDAR_DATA]: 'LDR',
  [ArtifactTypes.POINTCLOUD]: 'LAS',
  [ArtifactTypes.POTREE]: 'PO',
  [ArtifactTypes.TEST_ARTIFACT_INPUT]: 'TSI',
  [ArtifactTypes.TEST_ARTIFACT_OUTPUT]: 'TSO',
  [ArtifactTypes.LOG]: 'LOG',
  [ArtifactTypes.TILES]: 'TLS',
  [ArtifactTypes.RECON_DATA]: 'REC',
  [ArtifactTypes.DOCUMENTATION]: 'DOC',
  [ArtifactTypes.GEOTIFF]: 'TIF',
  [ArtifactTypes.POLYGON]: 'POLY',
}

/**
 * A set of `ArtifactTypes`s which are using the directory Api for uploading instead of the default one.
 */
const artifactTypesUsingDirectoryApi = [
  ArtifactTypes.CAMERA_DATA,
  ArtifactTypes.POTREE,
  ArtifactTypes.TILES,
]

/**
 * Return properties of the cam artifact that should be used in uploader and other places
 * @param {string} subFolder All images are prefixed with this string before sent to backend
 * @param {number} numberOfImages The maximum number of images artifact can have
 * @param {string} createdFrom Indicate is artifact was created from plp or cam files
 */
export function getCamArtifactProperties (subFolder, numberOfImages, createdFrom) {
  return {
    sub_folder: subFolder,
    number_of_images: numberOfImages,
    created_from: createdFrom,
  }
}

/**
 * Converts `artifactType` identifier into a 3-letter abbreviation.
 */
export function abbreviateArtifactType (artifactType) {
  return ArtifactTypeAbbreviations[artifactType]
}

/**
 * Checks whether uploads to artifacts of the specified type need to be performed using the directory Api
 * instad of the default Api.
 * @param artifactType The type of the artifact that should be checked.
 * @return `true` if the directory Api should be used and `false` otherwise.
 */
// function isArtifactTypeUsingDirectoryApi (artifactType: ArtifactType): boolean
export function isArtifactTypeUsingDirectoryApi (artifactType) {
  return artifactTypesUsingDirectoryApi.indexOf(artifactType) !== -1
}

/**
 * Converts the `artifactType` identifier into a human-readable representation.
 */
// function prettifyArtifactType(artifactType: ArtifactType): string
export function prettifyArtifactType (artifactType) {
  return ArtifactTypeTranslations[artifactType]
}

function getTimeDifferenceInSeconds (startDate, endDate) {
  const diff = moment.duration(endDate.diff(startDate))
  return diff.asSeconds()
}

export function getTimeFromLas (format, time, acquisitionDate) {
  if (!format) { return { tow: GpsTimeIntervalState.INVALID_TIME, week: GpsTimeIntervalState.INVALID_TIME } }
  if (format === LasTimeFormat.GpsStandardTime) {
    const date = GpsStandardTime.clone().utc().add(time, 'seconds')
    const startOfWeek = date.clone().utc().startOf('week')
    return { tow: getTimeDifferenceInSeconds(startOfWeek, date), week: getNumberOfWeeks(date) }
  }
  if (format === LasTimeFormat.GpsStandardTimeAdjusted) {
    const date = GpsStandardTime.clone().utc().add(1000000000 + time, 'seconds')
    const startOfWeek = date.clone().utc().startOf('week')
    return { tow: getTimeDifferenceInSeconds(startOfWeek, date), week: getNumberOfWeeks(date) }
  }
  if (format === LasTimeFormat.GpsUsecs) {
    const seconds = time / 1000000
    const date = GpsStandardTime.clone().utc().add(seconds, 'seconds')
    const startOfWeek = date.clone().startOf('week')
    return { tow: getTimeDifferenceInSeconds(startOfWeek, date), week: getNumberOfWeeks(date) }
  }
  if (format === LasTimeFormat.TimeOfWeek) {
    const date = getUTC(acquisitionDate)
    return { tow: time, week: getNumberOfWeeks(date) }
  }
  if (format === LasTimeFormat.SecondsIntoUtcDay) {
    const date = getUTC(acquisitionDate).add(time, 'seconds')
    const startOfWeek = date.clone().utc().startOf('week')
    return { tow: getTimeDifferenceInSeconds(startOfWeek, date), week: getNumberOfWeeks(date) }
  }
}

/**
 * Extracts the artifact specific time intervals.
 */
// function getArtifactGpsIntervals(artifact: Artifact): GpsTimeInterval[] {
export function getArtifactGpsIntervals (artifact, withFileNames = false) {
  const { artifactType, properties } = artifact
  if (!properties) {
    return []
  }
  if (isReconDataArtifact(artifactType)) {
    const artifactInterval = properties.interval
    return (artifactInterval && [transformLegacyGpsTimeInterval(artifactInterval)]) || []
  }
  if (isNavRoverArtifact(artifactType)) {
    const artifactInterval = properties.interval
    return (artifactInterval && [transformLegacyGpsTimeInterval(artifactInterval)]) || []
  }
  if (isPointcloudArtifact(artifactType)) {
    // Extract from analyzed props.
    const fileProperties = properties.fileProperties
    const lasSettings = properties.las_settings
    if (!fileProperties || !lasSettings) {
      return []
    }
    return Object.keys(fileProperties).flatMap(
      fileName => {
        const fileProps = fileProperties[fileName]
        let interval = fileProps.interval
        if (interval) {
          interval = transformLegacyGpsTimeInterval(interval)
          const format = lasSettings.time_format
          const acquisition_date = lasSettings.acquisition_date
          const newInterval = {
            beginning: getTimeFromLas(format, interval.beginning, acquisition_date),
            end: getTimeFromLas(format, interval.end, acquisition_date),
          }
          return withFileNames ? [{ fileName: fileName, interval: newInterval }] : [newInterval]
        }
        return []
      },
    )
  }
  if (isTrajectoryArtifact(artifactType)) {
    const artifactInterval = properties.interval
    if (artifactInterval) {
      return [transformLegacyGpsTimeInterval(artifactInterval)]
    }
    // Extract from analyzed props.
    const fileProperties = properties.fileProperties
    if (!fileProperties) {
      return []
    }
    return Object.keys(fileProperties).flatMap(
      fileName => {
        const fileProps = fileProperties[fileName]
        let interval = fileProps.interval
        if (interval) {
          interval = transformLegacyGpsTimeInterval(interval)
          return withFileNames ? [{ fileName: fileName, interval }] : [interval]
        }
        return []
      },
    )
  }

  if (isRefStationArtifact(artifactType)) {
    // Extract from analyzed props.
    const fileProperties = properties.fileProperties
    if (!fileProperties) {
      return []
    }

    return Object.keys(fileProperties).flatMap(
      fileName => {
        const fileProps = fileProperties[fileName]
        if (isRefstationAnalyzeResult(fileProps)) {
          let interval = fileProps.interval
          if (interval) {
            interval = transformLegacyGpsTimeInterval(interval)
            return withFileNames ? [{ fileName: fileName, interval }] : [interval]
          }
        }
        return []
      },
    )
  }

  if (isCameraArtifact(artifactType)) {
    // Extract the interval from the fileparseinfo.
    const artifactInterval = properties.interval
    if (artifactInterval) {
      const interval = transformLegacyGpsTimeInterval(artifactInterval)
      return [interval]
    }
    const fileProperties = properties.fileProperties
    if (!fileProperties) {
      return []
    }
    return Object.keys(fileProperties).flatMap(
      fileName => {
        const fileProps = fileProperties[fileName]
        if (isCamFileParserInfo(fileProps)) {
          const { fileType, result } = fileProps
          if (!isCamFileType(fileType)) {
            return []
          }
          if (result.interval) {
            const interval = transformLegacyGpsTimeInterval(result.interval)
            return [interval]
          }
          return []
        }
        return []
      },
    )
  }

  if (isLidarArtifact(artifactType)) {
    // Extract the interval from the fileparseinfo.
    const fileProperties = properties.fileProperties
    const fileNames = properties.fileNames || []
    if (!fileProperties) {
      return []
    }
    return Object.keys(fileProperties).flatMap(
      fileName => {
        if (fileNames.length > 0 && !fileNames.includes(fileName)) return []
        const fileProps = fileProperties[fileName]
        if (isLdrFileParserInfo(fileProps)) {
          const { fileType, result } = fileProps
          if (!isLdrFileType(fileType)) {
            return []
          }
          if (isArtifactIntervalValid(result.interval)) {
            const interval = transformLegacyGpsTimeInterval(result.interval)
            return withFileNames ? [{ fileName: fileName, interval: interval }] : [interval]
          }
          if (isPlpLdrInfo(fileProps) && isArtifactIntervalValid(fileProps.interval)) {
            const interval = transformLegacyGpsTimeInterval(fileProps.interval)
            return withFileNames ? [{ fileName: fileName, interval: interval }] : [interval]
          }
          const interval = transformLegacyGpsTimeInterval(result.interval)
          return withFileNames ? [{ fileName: fileName, interval: interval }] : [interval]
        }
        if (isPlpLdrInfo(fileProps)) {
          const interval = transformLegacyGpsTimeInterval(fileProps.interval)
          return withFileNames ? [{ fileName: fileName, interval: interval }] : [interval]
        }
        return []
      },
    )
  }
  return []
}

export function getArtifactIntervals (artifact, range) {
  const artifactGpsIntervals = getArtifactGpsIntervals(artifact)
  if (range && artifactGpsIntervals) {
    return artifactGpsIntervals.map(interval => interpolateGpsTimeInterval(interval, range))
  } else {
    return []
  }
}

// Interval for file
export function getArtifactFileGpsInterval (artifact, fileName) {
  const { artifactType, properties } = artifact
  if (typeof properties === 'undefined') {
    return undefined
  }
  if (isPointcloudArtifact(artifactType)) {
    const fileProperties = properties.fileProperties
    const lasSettings = properties.las_settings
    if (typeof fileProperties === 'undefined' || typeof lasSettings === 'undefined') {
      return undefined
    }
    const fileProps = fileProperties[fileName]
    if (typeof fileProps === 'undefined') {
      return undefined
    }
    let interval = fileProps.interval
    if (interval) {
      interval = transformLegacyGpsTimeInterval(interval)
      const format = lasSettings.time_format
      const acquisition_date = lasSettings.acquisition_date
      const newInterval = {
        beginning: getTimeFromLas(format, interval.beginning, acquisition_date),
        end: getTimeFromLas(format, interval.end, acquisition_date),
      }
      return newInterval
    }
  }
  if (isRefStationArtifact(artifactType)) {
    const fileProperties = properties.fileProperties
    if (typeof fileProperties === 'undefined') {
      return undefined
    }
    const fileProps = fileProperties[fileName]
    if (isRefstationAnalyzeResult(fileProps)) {
      const interval = transformLegacyGpsTimeInterval(fileProps.interval)
      return interval
    }
  }
  if (isLidarArtifact(artifactType)) {
    // Extract the interval from the fileparseinfo.
    const fileProperties = properties.fileProperties
    if (typeof fileProperties === 'undefined') {
      return undefined
    }
    const fileProps = fileProperties[fileName]
    if (isLdrFileParserInfo(fileProps)) {
      const { fileType, result } = fileProps
      if (!isLdrFileType(fileType)) {
        return undefined
      }
      if (isArtifactIntervalValid(result.interval)) {
        const interval = transformLegacyGpsTimeInterval(result.interval)
        return interval
      }
      if (isPlpLdrInfo(fileProps) && isArtifactIntervalValid(fileProps.interval)) {
        const interval = transformLegacyGpsTimeInterval(fileProps.interval)
        return interval
      }
      return transformLegacyGpsTimeInterval(result.interval)
    }
    if (isPlpLdrInfo(fileProps)) {
      return transformLegacyGpsTimeInterval(fileProps.interval)
    }
  }
  return undefined
}

export function getArtifactFileInterval (artifact, fileName, range) {
  const interval = getArtifactFileGpsInterval(artifact, fileName)
  return (interval && range) ? interpolateGpsTimeInterval(interval, range) : undefined
}

// function isIntervalFieldInvalid(range: GpsTimeInterval, fieldName: FieldName) {
export function isIntervalFieldInvalid (range, fieldName) {
  const rangeField = range[fieldName]
  const tow = getTow(rangeField)
  const week = range[fieldName].week
  return (
    (
      tow === GpsTimeIntervalState.INVALID_TIME &&
      week === GpsTimeIntervalState.INVALID_TIME
    ) ||
    (
      tow < 0 ||
      week <= 0
    ) || (
      typeof tow === 'undefined' ||
      typeof week === 'undefined'
    )
  )
}

export function trimTimespan (time) {
  const splittedByDot = (time + '').split('.')
  return splittedByDot.length > 1
    ? +time.toFixed(1)
    : time
}

// function getTimespan(range: GpsTimeInterval, fieldName: FieldName) {
export function getTimespan (range, fieldName) {
  return `${trimTimespan(range[fieldName].week)} / ${trimTimespan(range[fieldName].tow)}`
}

// const readableFormat = 'MMM D, YYYY hh:mm:ss a'

export function getIntervalTooltip (interval) {
  if (!interval) return ''
  if (!isArtifactIntervalValid(interval)) return ''
  const labels = getLabels(interval)
  const beginning = interval.beginning
  const end = interval.end
  const beginningUTC = gpsTimeToUTC(interval.beginning)
  const endUTC = gpsTimeToUTC(interval.end)
  return <React.Fragment>
    {(labels.left && labels.right) && <div style={{ textAlign: 'center' }}>{labels.left} - {labels.right}</div> }
    <div style={{ textAlign: 'center' }}>{beginningUTC.format(config.DATETIME_FORMAT)} - {endUTC.format(config.DATETIME_FORMAT)}</div>
    <div style={{ textAlign: 'center' }}>{prettifyTime((end.tow - beginning.tow) * 1000, false)}</div>
  </React.Fragment>
  // return `${labels.left} - ${labels.right}\n${prettifyTime(moment.duration(end.diff(beginning)), false)}`
}

// get labels for multi progress bar
// export function getLabels(range: GpsTimeInterval) {
export function getLabels (range) {
  const isInvalidBeginning = !range.beginning ? false : isIntervalFieldInvalid(range, 'beginning')
  const isInvalidEnd = !range.end ? false : isIntervalFieldInvalid(range, 'end')
  const labels = {}
  const labelLeft = range.beginning && getTimespan(range, 'beginning')
  const labelRight = range.end && getTimespan(range, 'end')
  const labelCenter = isInvalidEnd && isInvalidBeginning
    ? i18n.t('gpsInterval.invalidBeginningEnd')
    : isInvalidBeginning ? i18n.t('gpsInterval.invalidBeginning')
      : isInvalidEnd ? i18n.t('gpsInterval.invalidEnd') : undefined
  if (labelCenter) {
    labels.center = labelCenter
  } else {
    labels.left = labelLeft
    labels.right = labelRight
  }
  return labels
}

// export function isArtifactIntervalValid(artifactInterval: GpsTimeInterval) {
export function isArtifactIntervalValid (artifactInterval) {
  const beginning = artifactInterval && artifactInterval.beginning
  const end = artifactInterval && artifactInterval.end
  return (
    beginning && end &&
    !isIntervalFieldInvalid(artifactInterval, 'beginning') &&
    !isIntervalFieldInvalid(artifactInterval, 'end')
    /*
    beginning.tow !== GpsTimeIntervalState.INVALID_TIME &&
    beginning.tow > 0 &&
    beginning.week !== GpsTimeIntervalState.INVALID_TIME &&
    beginning.week > 0 &&
    end.tow !== GpsTimeIntervalState.INVALID_TIME &&
    end.tow > 0 &&
    end.week !== GpsTimeIntervalState.INVALID_TIME &&
    end.week > 0
    */
  )
}

/**
 * Finds all artifacts which belong to a pipeline with a specified id.
 * @param projectId The id of the project to find the artifacts for.
 * @param artifactList The artifact list.
 * @return All artifacts in `artifactList`, which belong to project `projectId`.
 */
export function getArtifactsForPipeline (pipelineId, jobList, jobIOList, artifactList) {
  const jobIds = getJobIdsForPipeline(pipelineId, jobList)
  const jobIOs = jobIds.reduce((result, jobId) => [...result, ...getJobIOsForJob(jobId, jobIOList)], [])
  const artifactIds = jobIOs.map(jobIO => jobIO.artifactId)
  return artifactList.filter(({ id }) => artifactIds.indexOf(id) !== -1)
}

export function getArtifactIdsForPipeline (pipelineId, jobList, jobIOList, artifactList) {
  return getArtifactsForPipeline(pipelineId, jobList, jobIOList, artifactList)
    .map(artifact => artifact.id)
}

/**
 * Finds all artifacts which belong to a project with a specified id.
 * @param projectId The id of the project to find the artifacts for.
 * @param artifactList The artifact list.
 * @return All artifacts in `artifactList`, which belong to project `projectId`.
 */
export function getArtifactsForProject (projectId, artifactList) {
  return filterByProjectId(projectId, artifactList)
}

export function getArtifactIdsForProject (projectId, artifactList) {
  return getArtifactsForProject(projectId, artifactList).map(artifact => artifact.id)
}

/**
 * Finds all artifacts of a particular `artifactType`.
 * @param artifactType The type of the artifact to look up.
 * @param artifactList The artifact list.
 * @return All artifacts in `artifactList` with the type `artifactType`.
 */
export function getArtifactsOfType (artifactType, artifactList) {
  return artifactList.filter(artifact => artifact.artifactType === artifactType)
}

/**
 * Finds artifacts which are completed.
 * @param artifactList The artifact list.
 * @return All artifacts in `artifactList` which are completed.
 */
export function getCompletedArtifacts (artifactList) {
  return artifactList.filter(({ artifactStatus }) =>
    artifactStatus !== ArtifactStatuses.UNCOMPLETED &&
    artifactStatus !== ArtifactStatuses.UPLOADING
  )
}

/**
 * Finds all artifacts of a particular `artifactType` which are also in project `projectId`.
 * @param projectId The id of the project to find the artifacts for.
 * @param artifactType The type of the artifact to look up.
 * @param artifactList The artifact list.
 * @return All artifacts in `artifactList` with the type `artifactType` which are in project `projectId`.
 */
export function getArtifactsOfTypeForProject (artifactType, projectId, artifactList) {
  return getArtifactsOfType(artifactType, getArtifactsForProject(projectId, artifactList))
}

/*
export function hasCompletedArtifactsOfTypeForProject (artifactType, projectId, artifactList) {
  return getCompletedArtifacts(
    getArtifactsOfTypeForProject(
      artifactType,
      projectId,
      artifactList
    )
  )
    .length > 0
}
*/

/*
export function getArtifactsForJobRun (jobRunId, jobIOList, jobRunList, artifactList) {
  const jobId = findById(jobRunId, jobRunList).jobId
  return getJobIOsForJob(jobId, jobIOList)
    .map(io => findById(io.artifactId, artifactList))
    .filter(artifact => typeof artifact !== 'undefined')
}
*/

/*
export function getArtifactsForJobRunWithIOType (ioType, jobRunId, jobIOList, jobRunList, artifactList) {
  const jobId = findById(jobRunId, jobRunList).jobId
  return getJobIOsForJob(jobId, jobIOList)
    .filter(io => io.ioType === ioType)
    .map(io => findById(io.artifactId, artifactList))
    .filter(artifact => typeof artifact !== 'undefined')
}

export function getInputArtifactsForJobRun (jobRunId, jobIOList, jobRunList, artifactList) {
  return getArtifactsForJobRunWithIOType(IOType.INPUT, jobRunId, jobIOList, jobRunList, artifactList)
}

export function getOutputArtifactsForJobRun (jobRunId, jobIOList, jobRunList, artifactList) {
  return getArtifactsForJobRunWithIOType(IOType.OUTPUT, jobRunId, jobIOList, jobRunList, artifactList)
}
*/

/**
 * This function returns a list of all artifacts known to the software which are connected to the pipeline
 * with the specified id as outputs.
 *
 * **Example:**
 *
 * Consider the following pipelines, artifacts and their connections exist:
 *
 * ```
 * Artifact A: Connected as Input to Job 1
 * Artifact B: Connected as Output to Job 1
 * Artifact C: Connected as Output to Job 2 and as Input to job 3
 * Artifact D: Connected as Input to Job 2
 * Artifact E: Connected as Output to Job 3
 *
 * Job 1: Belongs to Pipeline I
 * Job 2: Belongs to Pipeline II
 * Job 3: Belongs to Pipeline II
 * ```
 *
 * If this function was called with the id for `Pipeline II`, it would return the artifacts C and E.
 * If this function was called with the id for `Pipeline I`, it would return the artifact B.
 *
 * @param state The application's state.
 * @param pipelineId The id of the pipeline for which the output artifacts should be found.
 * @return An array of all artifacts which are connected to the specified pipeline as output artifacts.
 */
/*
export function getOutputArtifactsOfPipeline (state, pipelineId) {
  const currentProject = state.projects.get('currentProject')
  const artifacts = currentProject.artifacts
  const currentPipeline = state.pipelineDetails.get('currentPipeline')
  const jobs = currentPipeline.jobs
  const jobIOs = currentPipeline.jobIOs

  return concatMap(
    artifact => {
      // Find all `JobIO`s which are connected to the current artifact in any way.
      const jobIOsCurrentArtifact = jobIOs.filter(({ artifact: { id } }) => id === artifact.id)
      // Check if at least one of the `JobIO`s for this artifact is of `IOType.OUTPUT`
      // and belongs to the specified pipeline.
      const artifactConnectedToPipelineAsOutput = jobIOsCurrentArtifact.some(jobIO => {
        if (jobIO.job_io_type !== IOType.OUTPUT) {
          return false
        }
        const job = findById(jobIO.jobId, jobs)
        if (typeof job === 'undefined') {
          return false
        }
        if (job.pipelineId !== pipelineId) {
          return false
        }
        return true
      })
      return artifactConnectedToPipelineAsOutput ? [artifact] : []
    },
    artifacts
  )
}
*/

/**
 * Finds all data directories which belong to an artifact with a specified id.
 * @param artifactId The id of the artifact to find the data files for.
 * @param dataDirectoryList The data directory list state of the application.
 */
export function getDataDirectoriesForArtifact (artifactId, dataDirectories) {
  return dataDirectories.filter(dataDirectory => (
    path(['artifact', 'id'], dataDirectory) || dataDirectory.artifactId
  ) === artifactId)
}
/**
 * Returns full url to the cloud viewer based on artifact id
 * @param {String} artifactId
 */
export function getCloudViewerURL (artifactId, single = true) {
  return `${config.API_BASE}/artifacts/${artifactId}/cloudviewer${single ? '?single=true' : ''}`
}

/**
 * Returns prettified artifact status
 * @param {String} status
 */
export function getPrettyArtifactStatus (status) {
  return PrettyArtifactStatuses[status] || status
}

/**
 * Returns the icon based on the artifact status
 * @param {String} status
 * @param {String} color - custom color to use
 */
export function getArtifactStatusIcon (status, color) {
  if (status === ArtifactStatuses.UPLOADING) {
    return <IconUploading style={{ color: color || 'orange' }} />
  }
  if (status === ArtifactStatuses.PROCESSING) {
    return <IconWaiting style={{ color: color || 'green' }} />
  }
  if (status === ArtifactStatuses.OUTPUT_OF_FAILED_PIPELINE) {
    return <IconFailure style={{ color: color || 'red' }} />
  }
  if (status === ArtifactStatuses.READY) {
    return <IconSuccess style={{ color: color || 'green' }} />
  }
  if (status === ArtifactStatuses.UNCOMPLETED) {
    return <IconCancel style={{ color: color || 'grey' }} />
  }
  if (status === ArtifactStatuses.SCHEDULED) {
    return <IconWaiting style={{ color: color || 'orange' }} />
  }
}

export const getOutputInputArtifactsOfPipeline = (artifacts = [], jobs, jobIOs, jobId) => {
  // This UPLOAD_FILES job needs special treatment
  /*
  const isUploadFilesJob = jobs.find(job => job.job_type === JobType.UPLOAD_FILES)
  if (isUploadFilesJob) {
    const jobOptions = path(['job_runs', 0, 'options'], isUploadFilesJob)
    // Omiting token hash as we need only artifact id's
    const artifactIds = Object.keys(omit(['dropbox_token_hash'], jobOptions)) || []
    // Output & input artifacts of this pipeline always be an empty array, thus we need
    // to show something to user
    const outputArtifacts = []
    artifactIds.forEach(id => {
        const artifact = artifacts.find(art => art.id === id)
        if (artifact) {
          outputArtifacts.push(artifact)
        }
      }
    )
    return {
      inputs: [],
      outputs: outputArtifacts
    }
  }
  */

  const artifactIds = (jobId
    ? jobIOs.filter(jobIO => jobIO.job.id === jobId)
    : jobIOs).map(jobIO => jobIO.artifact.id)
  const artifactsForPipeline = artifacts.filter(({ id }) => artifactIds.indexOf(id) !== -1)

  return artifactsForPipeline.reduce((accum, artifact) => {
    const artifactId = artifact.id
    // Find all `JobIO`s which are connected to the current artifact in any way.
    const jobIOsCurrentArtifact = jobIOs.filter(({ artifact: { id } }) => id === artifactId)
    // Check if at least one of the `JobIO`s for this artifact is of `IOType.OUTPUT`
    // and belongs to the specified pipeline.
    const artifactConnectedToPipelineAsOutput = jobIOsCurrentArtifact.some(jobIO => {
      if (!isOutputJobIO(jobIO.job_io_type)) {
        return false
      }
      const job = findById(jobIO.job.id, jobs)
      if (typeof job === 'undefined') {
        return false
      }
      return true
    })
    return artifactConnectedToPipelineAsOutput
      ? { ...accum, outputs: [...accum.outputs, artifact] }
      : { ...accum, inputs: [...accum.inputs, artifact] }
  }, { inputs: [], outputs: [] })
}

export const getOutputInputArtifactsToRetryPipeline = (artifacts = [], jobs, jobIOs, jobId) => {
  const [firstJob] = jobs
  let newJobIos = jobIOs
  if (firstJob && isSpatialFuserJob(firstJob.job_type)) {
    const artifactIds = (
      jobId
        ? jobIOs.filter(jobIO => jobIO.job.id === jobId)
        : jobIOs
    ).map(jobIO => jobIO.artifact.id)
    const artifactsForPipeline = artifacts.filter(({ id }) => artifactIds.indexOf(id) !== -1)
    const missionIds = makeUnique(artifactsForPipeline.map(art => art.missionId).filter(Boolean))
    newJobIos = [
      ...newJobIos,
      ...artifacts
        .filter(art => (
          isCameraArtifact(art.artifactType) &&
          missionIds.includes(art.missionId) &&
          art.artifactStatus !== ArtifactStatuses.UNCOMPLETED
        )).map(art => ({
          artifact: art,
          created: 'some-created-date',
          id: 'some-id',
          job: { id: jobId },
          job_io_type: 'input',
          options: undefined,
          updated: 'some-updated-date',
        })),
    ]
  }
  const artifactIds = (
    jobId
      ? newJobIos.filter(jobIO => jobIO.job.id === jobId)
      : newJobIos
  ).map(jobIO => jobIO.artifact.id)
  const artifactsForPipeline = artifacts.filter(({ id }) => artifactIds.indexOf(id) !== -1)

  return artifactsForPipeline.reduce((accum, artifact) => {
    const artifactId = artifact.id
    // Find all `JobIO`s which are connected to the current artifact in any way.
    const jobIOsCurrentArtifact = newJobIos.filter(({ artifact: { id } }) => id === artifactId)
    // Check if at least one of the `JobIO`s for this artifact is of `IOType.OUTPUT`
    // and belongs to the specified pipeline.
    const artifactConnectedToPipelineAsOutput = jobIOsCurrentArtifact.some(jobIO => {
      if (!isOutputJobIO(jobIO.job_io_type)) {
        return false
      }
      const job = findById(jobIO.job.id, jobs)
      if (typeof job === 'undefined') {
        return false
      }
      return true
    })
    return artifactConnectedToPipelineAsOutput
      ? { ...accum, outputs: [...accum.outputs, artifact] }
      : { ...accum, inputs: [...accum.inputs, artifact] }
  }, { inputs: [], outputs: [] })
}

/**
 * Return map of such structure: [artifactId]: { interval }
 * If there is no such artifactId in the map then interval is valid
 * @param {array} artifacts
 */
export function getInvalidArtifacts (artifacts) {
  return artifacts.reduce(
    (result, artifact) => {
      const artifactIntervals = [artifact].flatMap(getArtifactGpsIntervals)
      const invalidArtifactIntervals = artifactIntervals.filter(interval =>
        interval && !isArtifactIntervalValid(interval)
      )
      const validArtifactIntervals = artifactIntervals.filter(interval =>
        interval && isArtifactIntervalValid(interval)
      )
      // If at least one interval is valid then artifact is valid too
      return invalidArtifactIntervals.length > 0 && validArtifactIntervals.length <= 0
        ? {
          ...result,
          [artifact.id]: {
            interval: invalidArtifactIntervals.reduce((res, artifactInterval) => ({
              beginning: gpsMin(res.beginning, artifactInterval.beginning),
              end: gpsMax(res.end, artifactInterval.end),
            })),
          },
        }
        : result
    },
    {}
  )
}

/**
 * Checks if artifact is in use in pipelines
 * @param {Object} artifact
 */
export function isArtifactInUse (artifact) {
  const { input_pipelines, output_pipelines } = artifact
  if (isSomePipelineProcessing(input_pipelines || []) || isSomePipelineProcessing(output_pipelines || [])) {
    return true
  }
  return false
}

/**
 * Checks if artifact status is one of this: processing, waiting, scheduled
 * @param {Object} artifact
 */
export function isArtifactInProcessing (artifact) {
  const { artifactStatus } = artifact
  return artifactStatus === ArtifactStatuses.PROCESSING ||
    artifactStatus === ArtifactStatuses.WAITING ||
    artifactStatus === ArtifactStatuses.SCHEDULED
}

/**
 * Checks if artifact status is one of this: processing, waiting, scheduled, ready
 * @param {Object} artifact
 */
export function isArtifactCanBeSelectedForProcessing (artifact) {
  const { artifactStatus } = artifact
  return artifactStatus === ArtifactStatuses.READY || isArtifactInProcessing(artifact)
}

/**
 * Checks if artifact is uploaded by user
 * @param {Object} artifact
 */
export function isArtifactUploadedByUser (artifact) {
  return !artifact.isOutputArtifact
}

/**
 * Returns the number of images from the session camera properties
 * @param {Object} sessionCamera
 */
export function getNumberOfImagesForSessionCamera (sessionCamera, sensorModels = []) {
  // In case with Ladybug camera, we can not get number of images
  if (sensorModels.some(sm => isLadybugCamera(sm))) {
    return NUMBER_OF_IMAGES.ANY_AMOUNT
  }
  const { sessions } = sessionCamera
  const numberOfImages = sessions.reduce((totalNumberOfImages, { exposures }) =>
    totalNumberOfImages + Object.keys(exposures).reduce(
      (imagesCount, key) => imagesCount + exposures[key].length, 0
    )
  , 0
  )
  return numberOfImages
}

/**
 * Returns the model of the camera
 * @param {Object} camFile
 */
export function getCameraFileModel (camFile) {
  return path(['parseResult', 'result', 'cameraModel'], camFile)
}
/**
 * Check the equality of two gps intervals
 * @param {Object} a Interval1
 * @param {Object} b Interval2
 */
export function gpsIntervalsEquals (a, b) {
  return (
    a.beginning.tow === b.beginning.tow &&
    a.beginning.week === b.beginning.week &&
    a.end.tow === b.end.tow &&
    a.end.week === b.end.week
  )
}

/**
 * Returns interval from session camera
 * @param {Object} sessionCamera
 */
export function getIntervalFromSessionCamera (sessionCamera) {
  const { sessions } = sessionCamera
  const times = sessions.flatMap(
    session => Object.keys(session.exposures).flatMap(
      exposureKey => session.exposures[exposureKey].map(exposure => exposure.event.time)
    )
  )
  let weekMin = Infinity
  let weekMax = -Infinity
  let towMin = Infinity
  let towMax = -Infinity
  times.forEach(time => {
    const { tow, week } = transformLegacyGpsTime(time)
    if (tow > towMax) {
      towMax = tow
    }
    if (tow < towMin) {
      towMin = tow
    }
    if (week > weekMax) {
      weekMax = week
    }
    if (week < weekMin) {
      weekMin = week
    }
  })
  return {
    beginning: {
      tow: towMin,
      week: weekMin,
    },
    end: {
      tow: towMax,
      week: weekMax,
    },
  }
}
/**
 * Returns the properties of the artifacts that will be displayed in the JsonViewer
 * @param {String} artifactType
 * @param {Object} properties
 */
export const getJSONViewerDisplayArtifactOptions = (artifactType, properties) => {
  if (isRefStationArtifact(artifactType)) {
    return getUpdatedObject(properties, ReferenceStationOptionsDisplayFields)
  }
  if (isGCPArtifact(artifactType)) {
    return getUpdatedObject(properties, CRSUserDisplayFields)
  }
  return properties
}

const recursiveUpdateOptions = options => {
  if (typeof options === 'object' && options !== null && !Array.isArray(options)) {
    return Object.keys(options).reduce((allValues, key) => {
      const value = options[key]
      const newName = MapBackendNameToFrontend[key]
      if (newName) {
        return {
          ...omit([key], allValues),
          [newName]: typeof value === 'object' ? recursiveUpdateOptions(value) : value,
        }
      }
      return {
        ...allValues,
        [key]: typeof value === 'object' ? recursiveUpdateOptions(value) : value,
      }
    }, options)
  }
  return options
}

/**
 * Returns the properties of the jobs that will be displayed in the JsonViewer
 * @param {String} jobType
 * @param {Object} properties
 */
export const getJSONViewerDisplayJobOptions = (jobType, properties) => {
  if (!properties) return properties
  if (isNavLabJob(jobType)) {
    const updatedObject = getUpdatedObject(properties, NavlabOptionsDisplayFields)
    return recursiveUpdateOptions(updatedObject)
  }
  if (isSpatialFuserJob(jobType)) {
    const updatedObject = getUpdatedObject(properties, SpatialFuserOptionsDisplayFields, REF_STATION_BACKEND_NAME)
    return recursiveUpdateOptions(updatedObject)
  }
  return properties
}
/**
 * Returns the properties of the job-artifacts that will be displayed in the JsonViewer
 * @param {String} artifactType
 * @param {Object} properties
 */
export const getJSONViewerDisplayJobArtifactOptions = (artifactType, properties) => {
  if (!properties) return properties
  if (isRefStationArtifact(artifactType)) {
    const updatedObject = getUpdatedObject(properties, CRSUserDisplayFields)
    return recursiveUpdateOptions(updatedObject)
  }
  if (isNavRoverArtifact(artifactType)) {
    return recursiveUpdateOptions(properties)
  }
  return properties
}

/**
 *
 * Function will return new changed object based on `properties` and `template` arguments
 * @param {Object} properties Must represent an object with [key]:value pairs
 * @param {Object} template Must represent a map for the next object structure
 * {
 *    [key]: [`fieldName1`, `fieldName2`] -- will save only two fields: `fieldName1`, `fieldName2` for the key [key]
 *    [key]: { [anotherKey]: [`fieldName1`, `fieldName2`] } -- Nested items also available
 *    [key]: null -- Will set item to null
 * }
 * `NOTE`: items that are not listed in the map and exist into object on the same level of depth will be copied untouched
 * TODO: make possible to delete the item completely
 */
const getUpdatedObject = (properties, template, backendName) => {
  if (backendName && backendName in properties) {
    const backendNameProps = properties[backendName]
    return {
      ...properties,
      [backendName]: Object.keys(backendNameProps).reduce((allProps, key) => {
        if (key in template) {
          return {
            ...allProps,
            [key]: getUpdatedObject(backendNameProps[key], template[key]),
          }
        }
        return {
          ...allProps,
          [key]: backendNameProps[key],
        }
      }, {}),
    }
  }
  if (Array.isArray(template)) {
    if (template.length === 1) return properties[template[0]]
    else if (template.length > 0) return keepProps(template, properties)
    else if (template === null) return null
    return properties
  } else {
    return Object.keys(properties).reduce((allProperties, key) => {
      const property = properties[key]
      const newTemplate = template[key]

      if (key in template && property) {
        return {
          ...allProperties,
          [key]: getUpdatedObject(property, newTemplate),
        }
      } else {
        return {
          ...allProperties,
          [key]: property,
        }
      }
    }, {})
  }
}

/**
 * Returns the labels and intervals for the artifact
 * @param {Object} artifact
 * @param {Array} invalidArtifacts
 * @param {Object} interval
 */
export const getArtifactLabelsAndIntervals = (artifact, invalidArtifacts, interval) => {
  const { id } = artifact
  const invalidArtifact = invalidArtifacts && invalidArtifacts[id]
  const range = invalidArtifact ? invalidArtifact.interval : interval
  const artifactGpsIntervals = getArtifactGpsIntervals(artifact)
  const [firstGpsInterval] = artifactGpsIntervals
  const validArtifactIntervals = artifactGpsIntervals.filter(int => int && isArtifactIntervalValid(interval))
  const isValidInterval = validArtifactIntervals.length > 0
  const newRange = isValidInterval ? range : firstGpsInterval
  let labels
  let intervals
  let isValid
  if (newRange) {
    labels = getLabels(newRange)
    intervals = getArtifactIntervals(artifact, newRange)
    const isInvalidBeginning = !newRange.beginning ? false : isIntervalFieldInvalid(newRange, 'beginning')
    const isInvalidEnd = !newRange.end ? false : isIntervalFieldInvalid(newRange, 'end')
    isValid = !isInvalidBeginning && !isInvalidEnd
  }
  return {
    fullInterval: newRange,
    labels,
    intervals,
    gpsIntervals: artifactGpsIntervals,
    isValid,
  }
}

/**
 * Returns sensor index of the artifact based on the artifact type and properties
 * @param {*} artifact
 */
export function getArtifactSensorIndex (artifact) {
  const { properties, artifactType } = artifact
  const { fileProperties = {} } = properties
  if (isLidarArtifact(artifactType)) {
    // Find all indexes in artifact -> properties -> fileProperties
    const indexes = makeUnique(Object.keys(fileProperties).reduce((tempIndexes, key) => {
      const fileProperty = fileProperties[key]
      const sensorIndex = path(['result', 'sensorIndex'], fileProperty)
      const index = path(['index'], properties)
      const finalIndex =
        typeof sensorIndex === 'number'
          ? sensorIndex
          : typeof index === 'string'
            ? parseInt(index, 10)
            : undefined
      return [...tempIndexes, finalIndex]
    }, []).filter(index => typeof index !== 'undefined'))
    return indexes.length > 0 ? indexes[0] : 0
  }
  if (isCameraArtifact(artifactType)) {
    const index = properties.sensorIndex || properties.index || (properties.sub_folder || '').split('cam')[1]
    return typeof index === 'number'
      ? index
      : typeof index === 'string'
        ? parseInt(index, 10)
        : 0
  }
  return 0
}

/**
 * Returns sensor index of the artifact based on the artifact type and properties
 * @param {*} artifact
 */
export function getSensorIndexesFromPlp (plp, searchFor) {
  return searchFor.reduce((all, s) => {
    const { name, paths = [] } = s
    const indexes = paths.map(pathToIndexes => path(pathToIndexes, plp))
    const firstValidIndexes = indexes.find(indexesByPath =>
      indexesByPath &&
      typeof indexesByPath === 'object' &&
      Object.keys(indexesByPath).length > 0
    )
    return {
      ...all,
      [name]: firstValidIndexes ? Object.keys(firstValidIndexes) : [],
    }
  }, {})
}
/**
 * Checks if artifact status is done. Should be one of this: UNCOMPLETED, READY, OUTPUT_OF_FAILED_PIPELINE
 * @param {Object} artifact
 */
export const isArtifactDone = artifactStatus => {
  return artifactStatus === ArtifactStatuses.UNCOMPLETED ||
    artifactStatus === ArtifactStatuses.READY ||
    artifactStatus === ArtifactStatuses.OUTPUT_OF_FAILED_PIPELINE
}

// Checks if artifact has antenna_mismatch
export const isArtifactHasAntennaMismatch = artifact => {
  return !!artifact.properties.antenna_mismatch
}
// Checks if we should display a warning to fix antenna mismatch
export const shouldDisplayFixAntennaMissmatch = artifact => {
  return isRefStationArtifact(artifact.artifactType) && isArtifactHasAntennaMismatch(artifact) && !isArtifactInProcessing(artifact)
}

export const isCameraArtifactHasLadybugSensor = artifact => {
  return (path(['properties', 'sensorModels'], artifact) || []).some(sm => isLadybugCamera(sm))
}

export function interpolateGpsTimeInterval (interval, range) {
  if (!interval || !isArtifactIntervalValid(interval)) return { beginning: -1, end: -1 }
  const { beginning, end } = interval
  return interval ? {
    beginning: interpolateGpsTime(beginning, range),
    end: interpolateGpsTime(end, range),
    timeBeginning: timeFromGpsSeconds(beginning.week, beginning.tow),
    timeEnd: timeFromGpsSeconds(end.week, end.tow),
  } : { beginning: -1, end: -1 }
}

export function getArtifactDownloadLink (token) {
  return `https://download.phoenixlidar.com/artifacts/${token}?compress_level=0&base=${baseEnvironment}`
}

export function getPolygonArtifactCoordinates (polygonArtifact) {
  const { properties } = polygonArtifact
  if (!properties) {
    return []
  }
  const { fileProperties } = properties
  if (!fileProperties) {
    return []
  }
  return Object.keys(fileProperties).reduce((all, fileName) => {
    if (!fileProperties[fileName]) {
      return all
    }
    if (!fileProperties[fileName].result) {
      return all
    }
    return [
      ...all,
      ...fileProperties[fileName].result.coordinates[0],
    ]
  }, [])
}

export function getDeliverables (artifacts) {
  return artifacts.filter(artifact => (
    isTrajectoryArtifact(artifact.artifactType) ||
    isPointcloudArtifact(artifact.artifactType) ||
    isGeotiffArtifact(artifact.artifactType)
  ) &&
  !artifact.mission)
}

export function getDocumentations (artifacts) {
  return artifacts.filter(artifact => isDocumentationArtifact(artifact.artifactType))
}

export function getGCPs (artifacts) {
  return artifacts.filter(artifact => isGCPArtifact(artifact.artifactType) || isPolygonArtifact(artifact.artifactType))
}

export function getReferenceStations (artifacts) {
  return artifacts.filter(artifact => isRefStationArtifact(artifact.artifactType))
}
