import { call, put, takeLatest, takeEvery, fork, select, all, join } from 'redux-saga/effects'
import { omit, path } from 'ramda'
import { toast } from 'react-toastify'
// Project deps
import history from 'browserHistory'
import i18next from 'i18n'
import { createJob } from 'modules/jobs/api'
import { createJobRun } from 'modules/jobRuns/api'
import { getLoggedUser, getLoggedUser as getUser, token as getToken } from 'modules/users/selectors'
import ImportWizardActions from 'modules/importWizard/actions'
import ArtifactsActions from 'modules/artifacts/actions'
import UsersActions from 'modules/users/actions'
import ProjectWizardActions from 'modules/projectWizard/actions'
import { getFileTypeByFileExtension } from 'modules/upload/file-types'
import axios from 'utils/axios'
import { routeError, routeProjectMissions, routeProjectGCPs, routeUserProjects, routeDashboard, routeDashboardProjects } from 'utils/routing'
import { isArtifactTypeUsingDirectoryApi } from 'utils/artifacts'
import { getErrorMessage, showErrorMessage, resolveTasks, joinAll, getAuthentificationHeader } from 'utils/api'
import { concatenate, findById } from 'utils/list'
// import { isSfOrPotreePipeline } from '../../utils/pipelines'
import { isJobRunDone } from 'utils/jobs'
import { JobType } from 'types/jobs'
import { convertRawDataDirectory } from 'types/dataDirectories'
import { convertRawDataFile } from 'types/dataFiles'
import { convertRawPosition } from 'types/position'
import { TransferTypes } from 'types/transfer'
import {
  ArtifactStatuses,
  convertRawArtifact,
  isCameraArtifact,
  isTilesArtifact,
} from 'types/artifacts'
// Local deps
import { getCurrentProject, getDataDirectoriesForArtifact } from './selectors'
import ProjectsActions, { ProjectsTypes } from './actions'
import { getGCP } from 'modules/utils'
import { FILES_FIELD, PROTO_ARTIFACT_FIELD } from 'types/importWizard'
import { getCompanyUsers } from 'modules/companies/selectors'
import { createCRS } from 'modules/crs/api'
import { getProjectDownloadLink } from 'utils/projects'
import { getMissionDownloadLink } from 'utils/missions'
import { GCPType, PolygonType } from 'components/ProjectWizard/utils'
import { isUserAdminOrPLSUser } from 'utils/company'
import { convertRawRecursivePipeline } from 'types/pipelines'
import { getDownloadMissionToken } from 'modules/missions/api'

// Sagas
// generate data directories id list, and put on success getDataDirectoriesList
async function getDataDirectory (dataDirectoryId) {
  return axios.get(`/data_directories/${dataDirectoryId}`)
}

// generate data directories id list, and put on success getDataDirectoriesList
async function getDataDirectories (artifactId) {
  return axios.get(`/artifacts/${artifactId}/data_directories`)
}

async function getDataFile (dataFileId) {
  return axios.get(`/data_files/${dataFileId}`)
}

/**
 * Run parser function for files
 * Js has some limitations on the number of concurrently running functions.
 * TODO: May be it's a browser limitation or something like this. Investigate this
 */
function * getDataFilesInChunks (dataFiles) {
  // Choose the parser function
  let tasks = []
  const filesLimit = 10
  const filesCount = dataFiles.length

  let i = 1
  let endIndex = 0
  let result = []
  do {
    const startIndex = filesLimit * (i - 1)
    const step = (startIndex + filesLimit)
    endIndex = step > filesCount ? filesCount : step
    for (let i = startIndex; i < endIndex; i++) {
      tasks.push(yield fork(getDataFile, dataFiles[i].id))
    }
    const tempResult = yield resolveTasks(tasks)
    result = [...result, ...tempResult]
    tasks = []
    i++
  } while (endIndex < filesCount)
  return result
}

// Sagas
function * getProjects ({ query }) {
  yield put(ProjectsActions.getProjectsLoading())
  try {
    const [userId] = yield select(state => [getUser(state).id])
    const { data: { data: projects } } = yield call(axios.get, `/users/${userId}/projects?sort=created${query ? `&${query}` : ''}`)
    yield put(ProjectsActions.getProjectsSuccess(projects))
  } catch (e) {
    yield put(ProjectsActions.getProjectsFailure(getErrorMessage(e)))
  }
}

// https://api.develop.lidarmill.com/projects/?user_id=be5eeb21-68e3-4c91-922f-48fc186642cc
/*
function * getMinimalProjects () {
  yield put(ProjectsActions.getMinimalProjectsLoading())
  try {
    const [userId] = yield select(state => [getUser(state).id])
    // `projects/?user_id=${userId}`,
    const { data: { data: projects } } = yield call(axios.get, `/users/${userId}/projects?sort=created`)
    yield put(ProjectsActions.getMinimalProjectsSuccess(projects))
  } catch (e) {
    yield put(ProjectsActions.getMinimalProjectsFailure(getErrorMessage(e)))
  }
}
*/

/*
function * getProjectsByUser ({ userId, count, offset }) {
  yield put(ProjectsActions.getProjectsByUserLoading())
  try {
    const { data: { data: projects } } = yield call(
      axios.get, `/users/${userId}/projects?sort=created&count=${count}&offset=${offset}`
    )
    yield put(ProjectsActions.getProjectsByUserSuccess(projects))
  } catch (e) {
    yield put(ProjectsActions.getProjectsByUserFailure(getErrorMessage(e)))
  }
}
*/

// https://api.develop.lidarmill.com/users/0dc9276f-51e0-497d-9ab2-dafcb8baacdf/projects
/*
function * getProject ({ projectId }) {
  yield put(ProjectsActions.getProjectLoading())
  try {
    const [userId] = yield select(state => [getUser(state).id])
    const { data: { data: project } } = yield call(axios.get, `/users/${userId}/projects/${projectId}`)
    yield put(ProjectsActions.getProjectSuccess(project))
  } catch (e) {
    yield put(ProjectsActions.getProjectFailure(getErrorMessage(e)))
  }
}
*/

function * createProject ({ userId, projectData, crsData, gcpType, polygonType, onSuccess }) {
  let projectUserId = userId
  const loggedUser = yield select(state => getLoggedUser(state))
  // replace user id for which we will be create a project if it is not admin user
  if (!isUserAdminOrPLSUser(loggedUser)) {
    projectUserId = loggedUser.id
  }
  yield put(ProjectsActions.createProjectLoading())
  const uploadPolygon = polygonType === PolygonType[1].value
  const uploadGCP = gcpType === GCPType[1].value
  const importGCP = gcpType === GCPType[2].value
  try {
    const { data: { data: project } } = yield call(axios.post, `/users/${projectUserId}/projects`, projectData)
    const projectId = project.id
    const redirectTo = uploadGCP || importGCP || uploadPolygon
      ? routeProjectGCPs(projectId, importGCP ? '?upload=copy' : '')
      : routeProjectMissions(projectId)
    toast.success(i18next.t('toast.project.createSuccess'))
    const hasCRS = crsData.crs
    if (hasCRS) {
      yield put(ProjectsActions.createProjectCRS(
        projectId,
        crsData.crs,
        crsData.useDefaultCRS,
        crsData.isPredefined,
        redirectTo,
        true,
      ))
      yield put(ProjectWizardActions.resetProjectWizardState())
    }
    if (!hasCRS) {
      yield put(ProjectsActions.createProjectSuccess())
    }
    if (uploadGCP || uploadPolygon) {
      yield put(ImportWizardActions.confirm(
        [PROTO_ARTIFACT_FIELD.POLYGON, PROTO_ARTIFACT_FIELD.GCP],
        [PROTO_ARTIFACT_FIELD.POLYGON, FILES_FIELD.POLYGON, PROTO_ARTIFACT_FIELD.GCP, FILES_FIELD.GCP],
        true,
        undefined,
        { projectId: projectId, project: project },
      ))
      if (!hasCRS) history.push(routeProjectGCPs(projectId))
    } else {
      yield put(ImportWizardActions.resetState())
      if (!hasCRS) history.push(`${routeProjectMissions(projectId)}`)
    }
  } catch (e) {
    toast.error(i18next.t('toast.project.createError'))
    yield put(ProjectsActions.createProjectFailure(getErrorMessage(e)))
  }
}

function * transfer (projectId, projectUrlToTransfer) {
  const token = yield select(state => getToken(state))
  const headers = getAuthentificationHeader(token)
  if (projectId) {
    // Create pipeline
    const body = {
      name: `Transfer ${projectUrlToTransfer}`,
      email_notification: true,
    }
    const { data: { data: pipeline } } = yield call(axios.post, `/projects/${projectId}/pipelines`, body)
    const pipelineId = pipeline.id
    // Create job
    const lastCreatedJob = yield call(createJob, headers, pipelineId, JobType.TRANSFER)
    // Create jobRun for job:
    const options = {
      project_id: projectUrlToTransfer,
      new_project_id: projectId,
    }
    yield call(createJobRun, headers, lastCreatedJob.id, options)
    toast.success(i18next.t('toast.project.transferSuccess'))
    // history.push(routePipeline(pipelineId))
  }
}

function * transferProject ({ projectUrl, transferType, projectIdToMerge, userId }) {
  yield put(ProjectsActions.transferProjectLoading())
  try {
    let finalUserId = userId
    if (!finalUserId) {
      const [loggedUserId] = yield select(state => [getUser(state).id])
      finalUserId = loggedUserId
    }
    if (transferType === TransferTypes.COPY) {
      // Create project
      const body = {
        name: 'Copying to the new project...',
        description: `Project url: ${projectUrl}`,
      }
      const { data: { data: project } } = yield call(axios.post, `/users/${finalUserId}/projects`, body)
      yield call(transfer, project.id, projectUrl, transferType)
      if (userId) {
        const companyUsers = yield select(state => getCompanyUsers(state))
        const userInCompanyUsers = findById(userId, companyUsers)
        if (userInCompanyUsers) {
          yield put(ProjectsActions.transferProjectSuccess(userInCompanyUsers.companyId, project))
        }
      }
    }
    if (transferType === TransferTypes.MERGE) {
      yield call(transfer, projectIdToMerge, projectUrl, transferType)
      yield put(ProjectsActions.getPipelinesForCurrentProject(projectIdToMerge))
    }
    yield put(ProjectsActions.transferProjectSuccess())
  } catch (e) {
    showErrorMessage(e)
    yield put(ProjectsActions.transferProjectFailure(getErrorMessage(e)))
  }
}

function * updateProject ({ projectId, data }) {
  const { name, description, plp, gcps } = data
  try {
    let plpJson
    try {
      plpJson = JSON.parse(plp)
    } catch (_) {
      plpJson = undefined
    }
    const body = {
      ...(typeof name === 'string' ? { name } : {}),
      ...(typeof description === 'string' ? { description } : {}),
      ...(typeof plpJson !== 'undefined' ? { plp: plpJson } : {}),
      ...(typeof gcps !== 'undefined' ? { gcps } : {}),
    }
    const { data: { data: project } } = yield call(axios.post, `/projects/${projectId}`, body)
    yield put(ProjectsActions.updateProjectSuccess(project.id, project))
    toast.success(i18next.t('toast.project.updateSuccess'))
  } catch (e) {
    showErrorMessage(e)
  }
}
// Delete individual project
function * deleteProject ({ companyId, projectId }) {
  yield put(ProjectsActions.deleteProjectLoading(projectId))
  try {
    yield call(axios.delete, `/projects/${projectId}`)
    yield put(ProjectsActions.deleteProjectSuccess(projectId, companyId))
    toast.success(i18next.t('toast.project.deleteSuccess'))
    history.push(routeDashboard())
  } catch (e) {
    yield put(ProjectsActions.deleteProjectFailure(getErrorMessage(e), projectId))
    toast.error(i18next.t('toast.project.deleteError'))
  }
}
// Delete a list of projects
function * deleteProjects ({ companyId, projectIds, redirect = true }) {
  yield put(ProjectsActions.deleteProjectsLoading(projectIds))
  try {
    if (projectIds.length > 1) {
      yield call(axios.post, `/projects/multiple_deletions`, { project_ids: projectIds })
    } else {
      yield call(axios.delete, `/projects/${projectIds[0]}`)
    }
    yield put(ProjectsActions.deleteProjectsSuccess(projectIds, companyId))
    toast.success(i18next.t('toast.project.deleteProjectsSuccess', { number: projectIds.length }))
    if (redirect) history.push(routeDashboardProjects())
  } catch (e) {
    showErrorMessage(e)
    yield put(ProjectsActions.deleteProjectsFailure(getErrorMessage(e), projectIds))
  }
}

function * deleteUserProjects ({ companyId, userId, projectIds, redirect = true }) {
  yield put(ProjectsActions.deleteUserProjectsLoading(userId, projectIds))
  try {
    if (projectIds.length > 1) {
      yield call(axios.post, `/projects/multiple_deletions`, { project_ids: projectIds })
    } else {
      yield call(axios.delete, `/projects/${projectIds[0]}`)
    }
    yield put(ProjectsActions.deleteUserProjectsSuccess(userId, projectIds, companyId))
    toast.success(i18next.t('toast.project.deleteProjectsSuccess', { number: projectIds.length }))
    if (redirect) history.push(routeUserProjects(userId))
  } catch (e) {
    showErrorMessage(e)
    yield put(ProjectsActions.deleteUserProjectsFailure(userId, getErrorMessage(e), projectIds))
  }
}

function * getPipelinesForCurrentProject ({ projectId, withLoading = true }) {
  if (withLoading) {
    yield put(ProjectsActions.getPipelinesForCurrentProjectLoading())
  }
  try {
    const { data: { data: pipelines } } = yield call(axios.get, `/projects/${projectId}/pipelines?sort=created`)
    yield put(ProjectsActions.getPipelinesForCurrentProjectSuccess(projectId, pipelines))
  } catch (e) {
    yield put(ProjectsActions.getPipelinesForCurrentProjectFailure(getErrorMessage(e)))
  }
}

function * getRecursivePipelinesForCurrentProject ({ projectId, pipelinesIds }) {
  yield put(ProjectsActions.getRecursivePipelinesForCurrentProjectLoading(projectId, pipelinesIds))
  try {
    const tasks = []
    for (const pipelineId of pipelinesIds) {
      tasks.push(yield fork(axios.get, `/pipelines/${pipelineId}/recursive`))
    }
    const allPipelines = yield resolveTasks(tasks)
    const updatedPipelines = allPipelines.map(convertRawRecursivePipeline).map(pipeline => {
      let jobs = pipeline.jobs
      const omitJobsPipeline = omit(['jobs'], pipeline)
      const { allJobRuns, allJobIOs, allRelatedJobs } = jobs.reduce((accum, job) => ({
        allJobRuns: [...accum.allJobRuns, ...job.job_runs],
        allJobIOs: [...accum.allJobIOs, ...job.job_ios],
        allRelatedJobs: [...accum.allRelatedJobs, ...job.related_jobs],
      }), { allJobRuns: [], allJobIOs: [], allRelatedJobs: [] })
      const allRelatedJobIOs = []
      jobs = jobs.map(job => omit(['related_jobs'], job))
      return {
        ...omitJobsPipeline,
        jobs,
        jobRuns: allJobRuns,
        jobIOs: allJobIOs,
        relatedJobs: allRelatedJobs,
        relatedJobIOs: allRelatedJobIOs,
      }
    })
    yield put(ProjectsActions.getRecursivePipelinesForCurrentProjectSuccess(projectId, pipelinesIds, updatedPipelines))
  } catch (e) {
    yield put(ProjectsActions.getRecursivePipelinesForCurrentProjectFailure(projectId, pipelinesIds, getErrorMessage(e)))
  }
}

/*
function * getRecursiveForPipelines (pipelines) {
  const token = yield select(state => getToken(state))
  const resultPipelines = []
  for (const pipeline of pipelines) {
    const { data: { data: pipelineData } } = yield call(axios.get,
      `/pipelines/${pipeline.id}/recursive`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    let jobs = pipelineData.jobs
    const newPipeline = omit(['jobs'], pipelineData)
    const { allJobRuns, allJobIOs, allRelatedJobs } = jobs.reduce((accum, job) => ({
      allJobRuns: [...accum.allJobRuns, ...job.job_runs],
      allJobIOs: [...accum.allJobIOs, ...job.job_ios],
      allRelatedJobs: [...accum.allRelatedJobs, ...job.related_jobs],
    }), { allJobRuns: [], allJobIOs: [], allRelatedJobs: [] })

    const tasks = []
    for (const relatedJob of allRelatedJobs) {
      tasks.push(yield fork(axios.get,
        `/jobs/${relatedJob.id}/job_ios`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      ))
    }

    const tempResult = yield all(tasks.map(t => join(t)))
    const allRelatedJobIOs = [...tempResult.reduce((allJobIOs, result) => [
      ...allJobIOs,
      ...result.data.data,
    ], [])]

    let allRelatedJobIOs = []
    for (const relatedJob of allRelatedJobs) {
      const { data: { data: jobIOs } } = yield call(axios.get,
        `/jobs/${relatedJob.id}/job_ios`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      allRelatedJobIOs = [...allRelatedJobIOs, ...jobIOs]
    }

    jobs = jobs.map(job => omit(['related_jobs'], job))
    resultPipelines.push({
      ...newPipeline,
      jobs,
      jobRuns: allJobRuns,
      jobIOs: allJobIOs,
      relatedJobs: allRelatedJobs,
      relatedJobIOs: allRelatedJobIOs,
    })
  }
  return resultPipelines
}
*/

// https://api.develop.lidarmill.com/projects/0dc9276f-51e0-497d-9ab2-dafcb8baacdf/pipelines
function * getCurrentProjectData ({ projectId }) {
  yield put(ProjectsActions.getCurrentProjectDataLoading())
  try {
    const [project, pipes, artifacts, missions, jobs, jobIos] = yield all([
      call(axios.get, `/projects/${projectId}`),
      call(axios.get, `/projects/${projectId}/pipelines?sort=created`),
      call(axios.get, `/projects/${projectId}/artifacts?sort=created`),
      call(axios.get, `/projects/${projectId}/missions`),
      call(axios.get, `/projects/${projectId}/jobs`),
      call(axios.get, `/projects/${projectId}/job_ios`),
    ])
    const { data: { data: { coordinate_reference_system } } } = project
    if (coordinate_reference_system) {
      yield put(UsersActions.getCRS(coordinate_reference_system.id))
    }
    const pipelines = path(['data', 'data'], pipes) || []
    yield put(ProjectsActions.getCurrentProjectDataSuccess(
      projectId,
      pipelines,
      artifacts,
      missions,
      project,
      jobs,
      jobIos,
    ))
  } catch (e) {
    history.push(routeError())
    yield put(ProjectsActions.getCurrentProjectDataFailure(getErrorMessage(e)))
  }
}

// Artifact data loading
export function * getDataForArtifact ({ artifactId, withLoading = true, projectId }) {
  if (withLoading) {
    yield put(ProjectsActions.getDataForArtifactLoading(artifactId))
  }
  try {
    const { data: { data: artifact } } = yield call(axios.get, `/artifacts/${artifactId}`)
    let allDataDirectories = []
    let allDataFiles = []
    if (isArtifactTypeUsingDirectoryApi(artifact.artifact_type)) {
      let dataDirectories = []
      const { data: { data: dataDirectoriesFromServer } } = yield call(axios.get, `/artifacts/${artifactId}/data_directories`)
      dataDirectories = dataDirectoriesFromServer
      if (isCameraArtifact(artifact.artifact_type) || isTilesArtifact(artifact.artifact_type)) {
        const tasks = []
        for (const dataDirectory of dataDirectories) {
          tasks.push(yield fork(getDataDirectory, dataDirectory.id))
        }
        allDataDirectories = yield resolveTasks(tasks)
      } else {
        allDataDirectories = dataDirectories
      }
    } else {
      const { data: { data: dataFiles } } = yield call(axios.get, `/artifacts/${artifactId}/data_files`)
      const failedDataFiles = []
      for (const dataFile of dataFiles) {
        if (!dataFile.completed && artifact.artifact_status !== ArtifactStatuses.UPLOADING) {
          failedDataFiles.push(dataFile)
        } else {
          allDataFiles.push(dataFile)
        }
      }
      if (failedDataFiles.length > 0) {
        const uncompletedDataFiles = yield getDataFilesInChunks(failedDataFiles)
        allDataFiles = concatenate([allDataFiles, uncompletedDataFiles])
      }
    }
    yield put(ProjectsActions.getDataForArtifactSuccess(
      artifactId,
      artifact,
      allDataDirectories.map(convertRawDataDirectory),
      allDataFiles.map(dataFile => ({ ...convertRawDataFile(dataFile), projectId })),
    ))
  } catch (e) {
    yield put(ProjectsActions.getDataForArtifactFailure(artifactId, getErrorMessage(e)))
  }
}

// Loading data for artifacts
export function * getDataForEditMissionArtifacts ({ mission, artifacts, projectId }) {
  yield put(ProjectsActions.getDataForEditMissionArtifactsLoading())
  try {
    const { plp: plpFile, id: missionId } = mission

    const tasks = []
    for (const artifact of artifacts) {
      tasks.push(yield fork(axios.get, `/artifacts/${artifact.id}/data_files`))
    }
    const tempResult = yield all(tasks.map(t => join(t)))
    const allDataFiles = [...tempResult.reduce((allFiles, result) => [
      ...allFiles,
      ...result.data.data.map(convertRawDataFile),
    ], [])]
    // allDataFiles.push({ ...convertRawDataFile(dataFile), projectId })

    const files = allDataFiles.map(({ fileName, fileSize }) => ({
      fileType: getFileTypeByFileExtension({ name: fileName }),
      file: {
        name: fileName,
        size: fileSize,
        missionId,
        okay: true,
      },
      okay: true,
    }))

    if (plpFile) {
      files.unshift({
        fileType: 'plp',
        file: {
          name: `${mission.name}.plp`,
          size: 0,
          missionId,
          okay: true,
        },
        okay: true,
      })
    }

    yield put(ImportWizardActions.openEditDialog(
      missionId,
      mission,
      artifacts.map(artifact => ({ ...artifact, fileSuggestions: [] })),
      files,
    ))
    yield put(ProjectsActions.getDataForEditMissionArtifactsSuccess())
  } catch (e) {
    yield put(ProjectsActions.getDataForEditMissionArtifactsFailure(getErrorMessage(e)))
  }
}

// Loading data for artifacts
export function * getDataForArtifacts ({ artifacts, projectId }) {
  yield put(ProjectsActions.getDataForArtifactsLoading())
  try {
    let allDataFiles = []
    let allDataDirectories = []
    for (const artifact of artifacts) {
      const artifactId = artifact.id
      if (isArtifactTypeUsingDirectoryApi(artifact.artifactType)) {
        let dataDirectories = []
        const dataDirectoriesByArtifact = yield select(state => getDataDirectoriesForArtifact(state, artifactId))
        // If there is no dataDirectories already downloaded for this artifact we should firstly make a request for them
        if (!dataDirectoriesByArtifact || dataDirectoriesByArtifact.length <= 0) {
          const { data: { data: dataDirectoriesFromServer } } = yield call(axios.get, `/artifacts/${artifactId}/data_directories`)
          dataDirectories = dataDirectoriesFromServer
        } else {
          dataDirectories = dataDirectoriesByArtifact
        }
        if (isCameraArtifact(artifact.artifactType)) {
          const tasks = []
          for (const dataDirectory of dataDirectories) {
            tasks.push(yield fork(getDataDirectory, dataDirectory.id))
          }
          const cameraDataDirectories = yield resolveTasks(tasks, convertRawDataDirectory)
          allDataDirectories = concatenate([allDataDirectories, cameraDataDirectories])
        } else {
          allDataDirectories = concatenate([allDataDirectories, dataDirectories.map(convertRawDataDirectory)])
        }
      } else {
        const { data: { data: dataFiles } } = yield call(axios.get, `/artifacts/${artifactId}/data_files`)
        const failedDataFiles = []
        for (const dataFile of dataFiles) {
          if (!dataFile.completed && artifact.artifact_status !== ArtifactStatuses.UPLOADING) {
            failedDataFiles.push(dataFile)
          } else {
            allDataFiles.push(dataFile)
          }
        }
        if (failedDataFiles.length > 0) {
          const uncompletedDataFiles = yield getDataFilesInChunks(failedDataFiles)
          allDataFiles = concatenate([allDataFiles, uncompletedDataFiles])
        }
      }
    }
    yield put(ProjectsActions.getDataForArtifactsSuccess(
      artifacts,
      allDataDirectories,
      allDataFiles.map(dataFile => ({ ...convertRawDataFile(dataFile), projectId })),
    ))
  } catch (e) {
    yield put(ProjectsActions.getDataForArtifactsFailure(getErrorMessage(e)))
  }
}

// Pipeline data loading
export function * getDataForPipeline ({ pipelineId, withLoading = true, jobRunId, onSuccess = null }) {
  if (withLoading) {
    yield put(ProjectsActions.getDataForPipelineLoading(pipelineId))
  }
  try {
    const { data: { data: pipelineData } } = yield call(axios.get, `/pipelines/${pipelineId}/recursive`)
    const pipelineDataConverted = convertRawRecursivePipeline(pipelineData)
    let jobs = pipelineDataConverted.jobs
    const pipeline = omit(['jobs'], pipelineDataConverted)
    const { allJobRuns, allJobIOs, allRelatedJobs } = jobs.reduce((accum, job) => ({
      allJobRuns: [...accum.allJobRuns, ...job.job_runs],
      allJobIOs: [...accum.allJobIOs, ...job.job_ios],
      allRelatedJobs: [...accum.allRelatedJobs, ...job.related_jobs],
    }), { allJobRuns: [], allJobIOs: [], allRelatedJobs: [] })
    const allRelatedJobIOs = []
    jobs = jobs.map(job => omit(['related_jobs'], job))
    const jobRunsWithoutRequest = []
    const tasks = []
    for (const jobRun of allJobRuns) {
      if (isJobRunDone(jobRun.job_run_state) && jobRun.id !== jobRunId) {
        jobRunsWithoutRequest.push(jobRun)
      } else {
        tasks.push(yield fork(axios.get, `/job_runs/${jobRun.id}`))
      }
    }
    const jobRunsRequested = yield resolveTasks(tasks)
    const anotherJobRuns = concatenate([jobRunsWithoutRequest, jobRunsRequested])
    yield put(ProjectsActions.getDataForPipelineSuccess(
      pipelineId,
      {
        ...pipeline,
        jobs,
        jobRuns: anotherJobRuns,
        jobIOs: allJobIOs,
        relatedJobs: allRelatedJobs,
        relatedJobIOs: allRelatedJobIOs,
      },
    ))
    if (typeof onSuccess === 'function') onSuccess()
  } catch (e) {
    yield put(ProjectsActions.getDataForPipelineFailure(pipelineId, getErrorMessage(e)))
  }
}

// Pipeline data loading
export function * getDataForPipelineAndArtifacts ({ pipelineId }) {
  try {
    const { data: { data: pipelineData } } = yield call(axios.get, `/pipelines/${pipelineId}/recursive`)
    const pipelineDataConverted = convertRawRecursivePipeline(pipelineData)
    let jobs = pipelineDataConverted.jobs
    const pipeline = omit(['jobs'], pipelineDataConverted)
    const { allJobRuns, allJobIOs, allRelatedJobs } = jobs.reduce((accum, job) => ({
      allJobRuns: [...accum.allJobRuns, ...job.job_runs],
      allJobIOs: [...accum.allJobIOs, ...job.job_ios],
      allRelatedJobs: [...accum.allRelatedJobs, ...job.related_jobs],
    }), { allJobRuns: [], allJobIOs: [], allRelatedJobs: [] })
    const allRelatedJobIOs = []
    jobs = jobs.map(job => omit(['related_jobs'], job))
    const anotherJobRuns = []
    for (const jobRun of allJobRuns) {
      if (isJobRunDone(jobRun.job_run_state)) {
        anotherJobRuns.push(jobRun)
      }
    }

    yield put(ProjectsActions.getDataForPipelineAndArtifactsSuccess(
      pipelineId,
      {
        ...pipeline,
        jobs,
        jobRuns: anotherJobRuns,
        jobIOs: allJobIOs,
        relatedJobs: allRelatedJobs,
        relatedJobIOs: allRelatedJobIOs,
      },
    ))
  } catch (e) {
    yield put(ProjectsActions.getDataForPipelineFailure(pipelineId, getErrorMessage(e)))
  }
}

// get a complete list of pipelines
function * getAllPipelines () {
  yield put(ProjectsActions.getAllPipelinesLoading())
  try {
    const [userId] = yield select(state => [getUser(state).id])
    const { data: { data: allPipelines } } = yield call(axios.get, `/users/${userId}/pipelines?sort=created`)
    yield put(ProjectsActions.getAllPipelinesSuccess(allPipelines))
  } catch (e) {
    yield put(ProjectsActions.getAllPipelinesFailure(getErrorMessage(e)))
  }
}

// Finalize pipeline in the project
export function * finalizePipeline ({ pipelineId }) {
  yield put(ProjectsActions.finalizePipelineLoading())
  try {
    yield call(axios.post, `/pipelines/${pipelineId}`, { finalized: true })
    yield put(ProjectsActions.finalizePipelineSuccess(pipelineId))
  } catch (e) {
    yield put(ProjectsActions.finalizePipelineFailure(getErrorMessage(e)))
    throw new Error(e)
  }
}

export function * getDataDirectoriesForArtifacts ({ artifactIds }) {
  yield put(ProjectsActions.getDataDirectoriesForArtifactsLoading())
  try {
    const tasks = []
    for (const artifactId of artifactIds) {
      tasks.push(yield fork(getDataDirectories, artifactId))
    }
    const joinedResults = yield joinAll(tasks)
    const allDataDirectories = joinedResults.reduce((accum, result) => {
      const { data: { data: dataDirectories } } = result
      return dataDirectories.length > 0
        ? [
          ...accum,
          ...dataDirectories,
        ]
        : accum
    }, [])
    yield put(ProjectsActions.getDataDirectoriesForArtifactsSuccess(allDataDirectories))
  } catch (e) {
    yield put(ProjectsActions.getDataDirectoriesForArtifactsFailure(e.message || e))
  }
}

function * getProjectsQueried ({ per_page, page, query }) {
  yield put(ProjectsActions.getProjectsQueriedLoading())
  try {
    const [userId] = yield select(state => [getUser(state).id])
    let url = `/users/${userId}/projects?per_page=${per_page}&page=${page}`
    const defaultSort = '&sort=created&order=desc'
    if (query) {
      const { sort, filter } = query
      url = `${url}${sort || defaultSort}${filter || ''}`
    } else {
      url = `${url}${defaultSort}`
    }
    const { data: { data: projects, pagination } } = yield call(axios.get, url)
    yield put(ProjectsActions.getProjectsQueriedSuccess(projects, pagination))
  } catch (e) {
    yield put(ProjectsActions.getProjectsQueriedFailure(e.message || e))
  }
}

// '', methods=['GET']
/*
export function * getMissionsByProject ({ projectId }) {
  yield put(ProjectsActions.getMissionsByProjectLoading())
  try {
    const token = yield select(state => getToken(state))
    let { data: { data: missions } } = yield call(axios.get,
      `/projects/${projectId}/missions`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    yield put(ProjectsActions.getMissionsByProjectSuccess(projectId, missions))
  } catch (e) {
    const error = e.response.data.message || e.message || e
    toast.error(error)
    yield put(ProjectsActions.getMissionsByProjectFailure(error))
  }
}
*/

function * getUTMZone ({ projectId }) {
  yield put(ProjectsActions.getUTMZoneLoading(projectId))
  try {
    const { data: { data: utmZone } } = yield call(axios.get, `/projects/${projectId}/get_utm_zone`)
    yield put(ProjectsActions.getUTMZoneSuccess(projectId, utmZone))
  } catch (e) {
    yield put(ProjectsActions.getUTMZoneFailure(projectId, e.message || e))
  }
}

function * getProjectArtifacts ({ projectId }) {
  yield put(ProjectsActions.getProjectArtifactsLoading(projectId))
  try {
    const { data: { data: artifacts } } = yield call(axios.get, `/projects/${projectId}/artifacts?sort=created`)
    yield put(ProjectsActions.getProjectArtifactsSuccess(projectId, artifacts.map(convertRawArtifact)))
  } catch (e) {
    yield put(ProjectsActions.getProjectArtifactsFailure())
  }
}

function * getArtifactGCPS ({ options, artifactId }) {
  yield put(ProjectsActions.getArtifactGCPSLoading(artifactId))
  try {
    const gcps = yield getGCP(options, false)
    const [currentProject] = yield select(state => [getCurrentProject(state)])
    const { artifacts } = currentProject
    const artifact = findById(artifactId, artifacts)
    let gcpsPoints = gcps.points.map(convertRawPosition)
    if (artifact) {
      const { properties, name } = artifact
      const { startIndex, fileProperties, columnAssignments, fileNames = [] } = properties
      const [fileName] = fileNames
      const { name: nameIndex } = columnAssignments
      const results = fileProperties[fileName].result.slice(startIndex, gcpsPoints.length)
      gcpsPoints = gcpsPoints.map((gcpPoint, index) => {
        const result = results[index]
        return {
          ...gcpPoint,
          name: result
            ? Array.isArray(result)
              ? result[nameIndex]
              : result.okay
                ? result.data[nameIndex]
                : `GCP ${index}`
            : `GCP ${index}`,
        }
      })
      const newProperties = {
        ...properties,
        transformedCoordinates: gcpsPoints,
      }
      // Update artifact properties to not receive the gcps everytime
      yield put(ArtifactsActions.updateArtifact(artifactId, name, newProperties, false))
    }
    yield put(ProjectsActions.getArtifactGCPSSuccess(artifactId, gcpsPoints))
  } catch (e) {
    yield put(ProjectsActions.getArtifactGCPSFailure(getErrorMessage(e), artifactId))
  }
}

function * createProjectCRS ({ projectId, crs: crsBody = {}, useDefaultCRS, isPredefined, redirect, success = false }) {
  yield put(ProjectsActions.createProjectCRSLoading(projectId))
  try {
    const { okay, crs, e } = yield call(createCRS, projectId, crsBody, useDefaultCRS, isPredefined)
    if (!okay) {
      throw new Error(e)
    }
    yield put(ProjectsActions.createProjectCRSSuccess(projectId, crs))
    if (redirect) {
      history.push(redirect)
    }
    if (success) {
      yield put(ProjectsActions.createProjectSuccess())
    }
  } catch (e) {
    showErrorMessage(e)
    yield put(ProjectsActions.createProjectCRSFailure())
  }
}

function * getProjectDownloadToken ({ projectId }) {
  yield put(ProjectsActions.getDownloadTokenLoading(projectId))
  try {
    const { data: { data: { token } } } = yield call(axios.get, `/projects/${projectId}/download_token`)
    yield put(ProjectsActions.getDownloadTokenSuccess(projectId, token))
  } catch (e) {
    showErrorMessage(e)
    yield put(ProjectsActions.getDownloadTokenFailure(projectId))
  }
}

function * downloadProject ({ jsonData, onSuccess }) {
  try {
    const { data: { data: downloadRequest } } = yield call(axios.post, `/downloading_requests`, { data: jsonData })
    const anchor = document.createElement('a')
    anchor.href = getProjectDownloadLink(downloadRequest.id)
    anchor.download = true
    document.body.appendChild(anchor)
    anchor.click()
    document.body.removeChild(anchor)
    toast.success('Download started')
    if (onSuccess) {
      onSuccess()
    }
  } catch (e) {
    showErrorMessage(e)
  }
}

function * getMissionDownloadToken ({ missionId }) {
  yield put(ProjectsActions.getDownloadTokenLoading(missionId))
  try {
    const { data: { data: { token } } } = yield call(axios.get, `/missions/${missionId}/download_token`)
    yield put(ProjectsActions.getDownloadTokenSuccess(missionId, token))
  } catch (e) {
    showErrorMessage(e)
    yield put(ProjectsActions.getDownloadTokenFailure(missionId))
  }
}

function * downloadMission ({ missionId }) {
  try {
    const token = yield call(getDownloadMissionToken, missionId)
    const anchor = document.createElement('a')
    anchor.href = getMissionDownloadLink(token)
    anchor.target = '_blank'
    document.body.appendChild(anchor)
    anchor.click()
    document.body.removeChild(anchor)
  } catch (e) {
    showErrorMessage(e)
  }
}

// Watchers
function * getProjectsWatcher () {
  yield takeLatest(ProjectsTypes.GET_PROJECTS, getProjects)
}

/*
function * getMinimalProjectsWatcher () {
  yield takeLatest(ProjectsTypes.GET_MINIMAL_PROJECTS, getMinimalProjects)
}
/*
/*
function * getProjectsByUserWatcher () {
  yield takeLatest(ProjectsTypes.GET_PROJECTS_BY_USER, getProjectsByUser)
}
*/
/*
function * getProjectWatcher () {
  yield takeLatest(ProjectsTypes.GET_PROJECT, getProject)
}
*/

function * createProjectWatcher () {
  yield takeLatest(ProjectsTypes.CREATE_PROJECT, createProject)
}

function * updateProjectWatcher () {
  yield takeLatest(ProjectsTypes.UPDATE_PROJECT, updateProject)
}

function * deleteProjectWatcher () {
  yield takeEvery(ProjectsTypes.DELETE_PROJECT, deleteProject)
}

function * deleteProjectsWatcher () {
  yield takeEvery(ProjectsTypes.DELETE_PROJECTS, deleteProjects)
}

function * getCurrentProjectDataWatcher () {
  yield takeLatest(ProjectsTypes.GET_CURRENT_PROJECT_DATA, getCurrentProjectData)
}

function * getPipelinesForCurrentProjectWatcher () {
  yield takeLatest(ProjectsTypes.GET_PIPELINES_FOR_CURRENT_PROJECT, getPipelinesForCurrentProject)
}

function * getRecursivePipelinesForCurrentProjectWatcher () {
  yield takeLatest(ProjectsTypes.GET_RECURSIVE_PIPELINES_FOR_CURRENT_PROJECT, getRecursivePipelinesForCurrentProject)
}

function * getAllPipelinesWatcher () {
  yield takeLatest(ProjectsTypes.GET_ALL_PIPELINES, getAllPipelines)
}

function * finalizePipelineWatcher () {
  yield takeLatest(ProjectsTypes.FINALIZE_PIPELINE, finalizePipeline)
}

function * getDataDirectoriesForArtifactsWatcher () {
  yield takeLatest(ProjectsTypes.GET_DATA_DIRECTORIES_FOR_ARTIFACTS, getDataDirectoriesForArtifacts)
}

function * getDataForArtifactWatcher () {
  yield takeEvery(ProjectsTypes.GET_DATA_FOR_ARTIFACT, getDataForArtifact)
}

function * getDataForPipelineWatcher () {
  yield takeEvery(ProjectsTypes.GET_DATA_FOR_PIPELINE, getDataForPipeline)
}

function * getDataForPipelineAndArtifactsWatcher () {
  yield takeEvery(ProjectsTypes.GET_DATA_FOR_PIPELINE_AND_ARTIFACTS, getDataForPipelineAndArtifacts)
}

function * transferProjectWatcher () {
  yield takeLatest(ProjectsTypes.TRANSFER_PROJECT, transferProject)
}

function * getProjectsQueriedWatcher () {
  yield takeLatest(ProjectsTypes.GET_PROJECTS_QUERIED, getProjectsQueried)
}

function * getDataForEditMissionArtifactsWatcher () {
  yield takeLatest(ProjectsTypes.GET_DATA_FOR_EDIT_MISSION_ARTIFACTS, getDataForEditMissionArtifacts)
}

function * getDataForArtifactsWatcher () {
  yield takeLatest(ProjectsTypes.GET_DATA_FOR_ARTIFACTS, getDataForArtifacts)
}

function * getUTMZoneWatcher () {
  yield takeLatest(ProjectsTypes.GET_UTM_ZONE, getUTMZone)
}

function * getProjectArtifactsWatcher () {
  yield takeLatest(ProjectsTypes.GET_PROJECT_ARTIFACTS, getProjectArtifacts)
}

function * deleteUserProjectsWatcher () {
  yield takeLatest(ProjectsTypes.DELETE_USER_PROJECTS, deleteUserProjects)
}

function * getArtifactGCPSWatcher () {
  yield takeEvery(ProjectsTypes.GET_ARTIFACT_GCPS, getArtifactGCPS)
}

function * createProjectCRSWatcher () {
  yield takeLatest(ProjectsTypes.CREATE_PROJECT_CRS, createProjectCRS)
}

function * getProjectDownloadTokenWatcher () {
  yield takeEvery(ProjectsTypes.GET_PROJECT_DOWNLOAD_TOKEN, getProjectDownloadToken)
}

function * getMissionDownloadTokenWatcher () {
  yield takeEvery(ProjectsTypes.GET_MISSION_DOWNLOAD_TOKEN, getMissionDownloadToken)
}

function * downloadProjectWatcher () {
  yield takeLatest(ProjectsTypes.DOWNLOAD_PROJECT, downloadProject)
}

function * downloadMissionWatcher () {
  yield takeLatest(ProjectsTypes.DOWNLOAD_MISSION, downloadMission)
}

export default function * root () {
  // yield fork(getProjectWatcher)
  yield fork(getProjectsWatcher)
  // yield fork(getMinimalProjectsWatcher)
  // yield fork(getProjectsByUserWatcher)
  yield fork(getProjectsQueriedWatcher)
  yield fork(transferProjectWatcher)

  yield fork(getCurrentProjectDataWatcher)

  yield fork(createProjectWatcher)
  yield fork(updateProjectWatcher)
  yield fork(deleteProjectWatcher)
  yield fork(deleteProjectsWatcher)
  yield fork(deleteUserProjectsWatcher)

  yield fork(getPipelinesForCurrentProjectWatcher)
  yield fork(getRecursivePipelinesForCurrentProjectWatcher)
  yield fork(getAllPipelinesWatcher)
  yield fork(getDataForPipelineWatcher)
  yield fork(getDataForPipelineAndArtifactsWatcher)
  yield fork(finalizePipelineWatcher)

  yield fork(getDataForArtifactWatcher)
  yield fork(getDataForArtifactsWatcher)
  yield fork(getDataForEditMissionArtifactsWatcher)

  yield fork(getDataDirectoriesForArtifactsWatcher)

  yield fork(getUTMZoneWatcher)
  yield fork(getProjectArtifactsWatcher)

  yield fork(getArtifactGCPSWatcher)

  yield fork(createProjectCRSWatcher)
  yield fork(getProjectDownloadTokenWatcher)
  yield fork(downloadProjectWatcher)
  yield fork(getMissionDownloadTokenWatcher)
  yield fork(downloadMissionWatcher)
}
