import { call, put, takeLatest, fork, select, all, join } from 'redux-saga/effects'
import history from 'browserHistory'
import { omit, mergeAll } from 'ramda'
import i18next from 'i18n'
import { toast } from 'react-toastify'
// Internal dependencies.
import ArtifactsActions from 'modules/artifacts/actions'
import GrantsActions from 'modules/grants/actions'
import ImportWizardActions from 'modules/importWizard/actions'
import { token as getToken, getLoggedUser } from 'modules/users/selectors'
import { getCurrentProject, getArtifacts } from 'modules/projects/selectors'
import { finalizePipeline } from 'modules/projects/sagas'
import { createArtifact } from 'modules/artifacts/api'
import PipelinesActions from 'modules/pipelines/actions'
import { createPipeline } from 'modules/pipelines/api'
import { createJobRun } from 'modules/jobRuns/api'
import { createJobIO } from 'modules/jobIOs/api'
import { createJob } from 'modules/jobs/api'
import { UrlTypes } from 'modules/url/actions'
import axios from 'utils/axios'
import { getOutputArtifacts, getInputArtifacts } from 'utils/pipelines'
import { PipelineCreationStage, errorsMap } from 'types/pipelines'
import { findById } from 'utils/list'
import { IOType } from 'types/jobIos'
import { prettifyArtifactType } from 'utils/artifacts'
import {
  isTrajectoryArtifact,
  isNavRoverArtifact,
  isRefStationArtifact,
  // isReconDataArtifact,
} from 'types/artifacts'
import { routeProjectPipelines } from 'utils/routing'
import { CRSFieldsList, CRSFields, tabsMap, RefStationCRSFieldsOmitList } from 'templates/CRS/constants'
import { getTabTemplate } from 'templates/CRS/utils'
import { isDummyJob, isNavLabJob } from 'utils/jobs'
import { templateJobOptions } from 'templates/jobOptions'
import { getBackendTransformedValues } from 'utils/templates'
import { getJobIoOptionsForArtifactTemplate } from 'templates/jobIoOptions'
import { getGCP as getGCPCall } from '../utils'
// Local deps
import {
  getCurrentCreationStage,
  getPipelineTemplate,
  getJobOptions,
  getSelectedTemplateArtifacts,
  getAdditionalJobRunOptions,
  getAdditionalJobIoOptions,
  getCloneJobRunOptions,
} from './selectors'
import PipelineWizardActions, { PipelineWizardTypes } from './actions'
import { getAuthentificationHeader, resolveTasks } from 'utils/api'
import { keepProps } from 'utils/dict'

function getJobRunOptions (pipelineTemplate, artifactMapping, jobId, jobIOs, options, artifactIds, additionalJobRunOptionsByArtifacts) {
  const inputArtifactTemplates = getInputArtifacts(pipelineTemplate)
  const jobIOsForJob = jobIOs.filter(jobIO => jobIO.jobId === jobId)
  const artifactIdsForJob = jobIOsForJob
    .filter(jobIO => artifactIds.includes(jobIO.artifactId))
    .map(jobIO => jobIO.artifactId)
  // This will add options from artifact with `mergeToJobRunOptions` value set to `true`
  // into job run options TOO, not just in artifact options
  const mergeableArtifactOptions = Object.keys(artifactMapping)
    .map(templateId => inputArtifactTemplates.find(templateArtifact => templateArtifact.templateId.toString() === templateId))
    .filter(Boolean)
    .reduce((all, templateArtifact) => {
      const { templateId, artifactType } = templateArtifact
      // Contains an array of all created artifact ids of the same type
      const createdArtifactIds = artifactMapping[templateId]
      // Find all job ios for those artifacts which are created for this job
      const createdJobIosForTemplate = jobIOsForJob.filter(jobIo => createdArtifactIds.includes(jobIo.artifactId))
      const [firstJobIosForTemplate] = createdJobIosForTemplate
      const artifactTemplate = getJobIoOptionsForArtifactTemplate(pipelineTemplate.type, artifactType)
      if (artifactTemplate && Object.keys(artifactTemplate).length > 0) {
        return {
          ...all,
          ...Object.keys(artifactTemplate).reduce((all, templateOptionName) => {
            const templateOption = artifactTemplate[templateOptionName]
            if ('mergeToJobRunOptions' in templateOption && firstJobIosForTemplate) {
              return {
                ...all,
                [templateOptionName]: firstJobIosForTemplate.options[templateOptionName],
              }
            }
            return all
          }, {}),
        }
      }
      return all
    }, {})
  return mergeAll([
    mergeableArtifactOptions,
    options,
    ...artifactIdsForJob.map(artifactId => (additionalJobRunOptionsByArtifacts[artifactId] || {})),
  ])
}

function mergeResultsWithTemplates (results, templates, multiple = false) {
  return results.reduce(
    (merged, result, index) => {
      const { id } = result
      const templateId = templates[index]
      return {
        ...merged,
        [templateId]: multiple
          ? [...(merged[templateId] || []), id]
          : id,
      }
    },
    {},
  )
}

/**
 * Create job ios.
 * @param jobMapping The mapping of template ids to real ids of the jobs.
 * @param artifactMapping The mapping of template ids to real ids of the artifacts.
 * @param jobIOs The list of jobIOs to create.
 * @return A value which describes the status of the operation.
 */
function prepareJobIOs (jobMapping, artifactMapping, jobs, artifactOptions) {
  // Convert a job to a list of job ios.
  const templateJobIOs = jobs.flatMap(
    job => [
      ...job.inputs.map(input => ({
        artifactId: input.templateId,
        jobId: job.templateId,
        ioType: IOType.INPUT,
      })),
      ...job.outputs.map(output => ({
        artifactId: output.templateId,
        jobId: job.templateId,
        ioType: IOType.OUTPUT,
      })),
    ],
  )
  // Augment the primitive job io list with the actual artifact/job IDs and the artifact options.
  const substitutedJobIOs = templateJobIOs.flatMap(
    jobIO => {
      const { artifactId, jobId, ioType } = jobIO
      const selected = artifactMapping[artifactId] || []
      return selected.map(artifactUuid => {
        const optionsState = artifactOptions[artifactUuid]
        const options = optionsState ? optionsState.values : {}
        return {
          artifactId: artifactUuid,
          jobId: jobMapping[jobId],
          ioType,
          options,
        }
      })
    },
  )
  return substitutedJobIOs
}

/**
 * Returns the mission id for the artifact that will be created
 * @param {String} artifactType
 * @param {Array} selectedInputArtifacts
 * @param {Array} jobs - List of the created jobs
 */
function getMissionIdForArtifactByType (artifactType, selectedInputArtifacts, jobs) {
  // Output trajectory will have the same mission id as selected Navigation Rover or Recon artifacts
  if (isTrajectoryArtifact(artifactType) && jobs.find(job => isNavLabJob(job.jobType))) {
    const firstArtifact = selectedInputArtifacts.find(artifact => isNavRoverArtifact(artifact.artifactType)) // || isReconDataArtifact(artifact.artifactType)
    return firstArtifact && firstArtifact.missionId
  }
  return undefined
}

function getNewValues (state, options, newFieldName, fieldsTemplates) {
  const { values = {}, rawValues = {} } = options
  const tab = rawValues[CRSFields.LAST_CHANGED_TAB]
  const tabName = tabsMap[tab]
  const tabValue = tabName && getTabTemplate(tabName, rawValues[CRSFields.TABS_TEMPLATE])
  if (tabValue) {
    const { fields, omitFields = [] } = tabValue
    const fieldNames = Object.keys(fields)
    const crsFieldsNames = fieldNames.filter(fieldName => omitFields.indexOf(fieldName) < 0)
    const crsFieldsTemplates = crsFieldsNames.reduce((allFields, fieldName) => ({
      ...allFields,
      [fieldName]: fields[fieldName],
    }), {})
    const crsFields = getBackendTransformedValues(state, values, rawValues, crsFieldsTemplates)
    /*
    const crsFields = fieldNames.filter(fieldName => omitFields.indexOf(fieldName) < 0).reduce((allFields, fieldName) => {
      const fieldOption = fields[fieldName]
      const { backendTransform, sendToBackend = true } = fieldOption
      const originalValue = values[fieldName]
      if (!sendToBackend) return allFields
      const value = typeof backendTransform === 'function'
        ? backendTransform(originalValue, state, rawValues)
        : originalValue
      return {
        ...allFields,
        [fieldName]: value,
      }
    }, {})
    */
    return {
      ...omit(CRSFieldsList, values),
      ...(newFieldName ? { [newFieldName]: crsFields } : crsFields),
    }
  }
  return fieldsTemplates ? getBackendTransformedValues(state, values, rawValues, fieldsTemplates, true) : rawValues
}

/**
 * Returns the transformed options of artifact (any backend transformation and others)
 * @param {*} artifactType
 * @param {*} options
 * @param {*} state
 */
const getValuesByArtifactType = (artifactType, options, state, pipelineTemplate) => {
  const { values: oldValues, rawValues } = options
  const values = getBackendTransformedValues(
    state,
    oldValues || {},
    rawValues || {},
    getJobIoOptionsForArtifactTemplate(pipelineTemplate.type, artifactType),
    true,
  )
  if (isRefStationArtifact(artifactType)) {
    return omit(RefStationCRSFieldsOmitList, values)
  }
  /*
  if (isRefStationArtifact(artifactType)) {
    const coordinateReferenceSystem = values[REF_STATION_BACKEND_NAME]
    if (Object.keys(values[REF_STATION_BACKEND_NAME]).every(key => !coordinateReferenceSystem[key])) {
      values[REF_STATION_BACKEND_NAME] = null
    }
    // console.log(newValues)
    // console.log({ ...getNewValues(state, { rawValues, values }), datum: null })
    // console.log(getNewValues(state, { rawValues, values: coordinateReferenceSystem }))
    return omit(RefStationCRSFieldsOmitList, values)
    //
    return {
      ...(!isCustomAnalyzeSelected(rawValues)
        ? omit(RefStationCRSFieldsOmitList, values)
        : { ...getNewValues(state, { rawValues, values }), datum: null }
      ),
    }
    //
  }
  */
  return values
}

/**
 * Returns the map of transformed artifact options
 * @param {*} state
 * @param {*} pipelineTemplate
 * @param {*} selectedTemplateArtifacts
 * @param {*} artifactOptions
 */
function getCustomArtifactOptions (state, pipelineTemplate, selectedTemplateArtifacts, artifactOptions) {
  const { artifacts: pipelineTemplateArtifacts } = pipelineTemplate
  return {
    ...artifactOptions,
    ...pipelineTemplateArtifacts.reduce((allTemplates, template) => {
      const { fieldName } = template
      const artifactIds = selectedTemplateArtifacts[template.templateId] || []
      return {
        ...allTemplates,
        ...artifactIds.reduce((options, id) => {
          const currentArtifactOptions = artifactOptions[id] || {}
          if (template.split) {
            const allValuesForArtifact = Object.keys(artifactOptions).filter(keyId => keyId.split('.')[0] === id)
            const values = allValuesForArtifact.reduce((all, keyId) => {
              const currentArtifactOptions = artifactOptions[keyId] || {}
              const splittedKeyId = keyId.split('.')
              const dataId = splittedKeyId[1]
              const index = splittedKeyId[2]
              if (template.group) {
                return {
                  ...all,
                  [dataId]: {
                    ...(all[dataId] || {}),
                    [index]: getValuesByArtifactType(dataId, currentArtifactOptions, state, pipelineTemplate),
                  },
                }
              } else {
                return {
                  ...all,
                  ...getValuesByArtifactType(dataId, currentArtifactOptions, state, pipelineTemplate),
                }
              }
            }, {})
            return {
              ...options,
              [id]: {
                ...currentArtifactOptions,
                values: fieldName
                  ? { [fieldName]: values }
                  : values,
              },
            }
          }
          const values = getValuesByArtifactType(template.artifactType, currentArtifactOptions, state, pipelineTemplate)
          return {
            ...options,
            [id]: {
              ...currentArtifactOptions,
              values: fieldName
                ? { [fieldName]: values }
                : values,
            },
          }
        }, {}),
      }
    }, {}),
  }
}

function getCustomJobOptions (state, pipelineTemplate, jobOptions, jobs) {
  const dummyJobs = jobs.filter(isDummyJob)
  // Creating mapping between job and dummyJobs
  const jobMapToDummyJobs = dummyJobs.reduce((mapping, dummyJob) => {
    return {
      ...mapping,
      [dummyJob.mergeTemplateId]: [
        ...(mapping[dummyJob.mergeTemplateId] || []),
        {
          id: dummyJob.templateId,
          fieldName: dummyJob.fieldName,
          job: dummyJob,
          ...dummyJob,
        },
      ],
    }
  }, {})
  // const spatialFuserTemplate = pipelineTemplate.jobs.filter(job => job.jobType === JobType.OUTPUT_CRS)
  return {
    ...jobs.reduce((allTemplates, template) => {
      const { templateId } = template
      if (dummyJobs.find(dummyJob => dummyJob.templateId === templateId)) return allTemplates
      const currentJobOptions = jobOptions[templateId] || {}
      const dummyJobsForCurrentJob = jobMapToDummyJobs[templateId] || []
      const fieldsTemplates = templateJobOptions[template.jobType]
      const transformedValues = getBackendTransformedValues(
        state,
        currentJobOptions.values || {},
        currentJobOptions.rawValues || {},
        fieldsTemplates || {},
      )
      const newValues = dummyJobsForCurrentJob.reduce((allValues, dummyJob) => {
        const { id: dummyJobId, fieldName } = dummyJob
        const dummyJobOptions = jobOptions[dummyJobId]
        const dummyJobFieldsTemplates = templateJobOptions[dummyJob.job.jobType]
        const dummyJobValues = dummyJobOptions ? getNewValues(state, dummyJobOptions, undefined, dummyJobFieldsTemplates) : {}
        return {
          ...allValues,
          ...(dummyJobOptions
            ? fieldName ? { [fieldName]: dummyJobValues } : dummyJobValues
            : {}
          ),
        }
      }, transformedValues)

      return {
        ...allTemplates,
        [templateId]: {
          ...currentJobOptions,
          values: newValues,
        },
      }
    }, {}),
  }
}

function * createMultiplePipelines (args) {
  const {
    jobOptions,
    artifactOptions,
    allSelectedArtifactIds,
    projectId,
    selectedTemplateArtifacts,
    emailNotification,
    name,
    headers,
    appState,
    pipelineTemplate,
    templateJobs,
    templateIdToSplit = [],
    additionalJobRunOptionsByArtifacts,
    additionalJobIoOptionsByArtifacts,
  } = args
  const templateArtifactsToSplit = templateIdToSplit.reduce((all, id) => [
    ...all,
    ...(selectedTemplateArtifacts[id] || []),
  ], [])
  const allSelectedArtifactIdsWithoutSplitted = allSelectedArtifactIds.filter(id => !templateArtifactsToSplit.includes(id))
  const artifactIdsSplittedBy = templateArtifactsToSplit.reduce((allSplitted, artifactId) => ({
    ...allSplitted,
    [artifactId]: [artifactId, ...allSelectedArtifactIdsWithoutSplitted],
  }), {})

  const pipelineTasks = []
  for (let i = 0; i < templateArtifactsToSplit.length; i++) {
    pipelineTasks.push(yield fork(createPipeline, headers, projectId, name, emailNotification))
  }
  const pipelines = yield all(pipelineTasks.map(t => join(t)))
  for (const pipeline of pipelines) {
    yield put(PipelinesActions.getPipelineSuccess(pipeline.id, pipeline))
  }
  const pipelineIds = pipelines.map(({ id }) => id)
  // Create the `Jobs`.
  yield put(PipelineWizardActions.changeCreationStatus(PipelineCreationStage.CREATE_JOBS, true))
  const templateIds = []
  const jobs = []
  for (const pipelineId of pipelineIds) {
    for (const templateJob of templateJobs) {
      if (!isDummyJob(templateJob)) {
        const job = yield call(createJob, headers, pipelineId, templateJob.jobType)
        jobs.push(job)
        templateIds.push(templateJob.templateId)
      }
    }
  }
  const jobMapping = mergeResultsWithTemplates(jobs, templateIds, true)
  // Create output artifacts.
  yield put(PipelineWizardActions.changeCreationStatus(PipelineCreationStage.CREATE_ARTIFACTS, true))
  const outputArtifacts = getOutputArtifacts(pipelineTemplate)
  const artifactsBefore = yield select(state => getArtifacts(state))
  const artifactTemplateIds = []
  const artifacts = []
  const outputArtifactIdsByPipeline = {}
  for (let i = 0; i < templateArtifactsToSplit.length; i++) {
    const artifactId = templateArtifactsToSplit[i]
    const pipeline = pipelines[i]
    const selectedInputArtifacts = artifactsBefore.filter(artifact =>
      artifactIdsSplittedBy[artifactId].indexOf(artifact.id) >= 0,
    )
    const tempPipelineArtifactIds = []
    for (const templateArtifact of outputArtifacts) {
      const { artifactType, getArtifactName, getProperties } = templateArtifact
      const artifactName = typeof getArtifactName === 'function'
        ? getArtifactName(selectedInputArtifacts)
        : prettifyArtifactType(artifactType)
      const properties = typeof getProperties === 'function'
        ? getProperties(appState, jobOptions, artifactOptions)
        : {}
      const artifact = yield call(createArtifact,
        headers,
        projectId,
        artifactName,
        artifactType,
        properties,
        getMissionIdForArtifactByType(artifactType, selectedInputArtifacts, jobs),
      )
      artifactTemplateIds.push(templateArtifact.templateId)
      artifacts.push(artifact)
      tempPipelineArtifactIds.push(artifact.id)
    }
    outputArtifactIdsByPipeline[pipeline.id] = tempPipelineArtifactIds
  }
  const createdArtifactsMapping = mergeResultsWithTemplates(artifacts, artifactTemplateIds, true)
  const artifactMapping = Object.keys(createdArtifactsMapping).reduce(
    (mapping, templateIdString) => {
      const templateId = parseInt(templateIdString)
      return {
        ...mapping,
        [templateId]: [
          ...(mapping[templateId] || []),
          ...createdArtifactsMapping[templateId],
        ],
      }
    },
    selectedTemplateArtifacts,
  )
  // Create the `jobIOs`.
  yield put(PipelineWizardActions.changeCreationStatus(PipelineCreationStage.CREATE_JOB_IOS, true))
  const createdJobIOs = []
  for (let i = 0; i < pipelines.length; i++) {
    const pipeline = pipelines[i]
    const currentSplittedArtifact = templateArtifactsToSplit[i]
    const jobsForPipeline = jobs.filter(job => job.pipelineId === pipeline.id)
    const preparedJobIOs = prepareJobIOs(
      // Only jobs for current pipeline
      Object.keys(jobMapping).reduce((mapping, templateId) => {
        const jobIds = jobMapping[templateId]
        return {
          ...mapping,
          [templateId]: jobIds.filter(jobId => findById(jobId, jobsForPipeline)),
        }
      }, jobMapping),
      // Only artifacts for current jobs
      Object.keys(artifactMapping).map(key => parseInt(key)).reduce((mapping, templateId) => ({
        ...mapping,
        [templateId]: templateIdToSplit.includes(templateId)
          ? [currentSplittedArtifact]
          : outputArtifacts.find(outputArtifact => outputArtifact.templateId === templateId)
            ? outputArtifactIdsByPipeline[pipeline.id]
            : artifactMapping[templateId],
      }), artifactMapping),
      templateJobs,
      artifactOptions,
    )
    // Finally create the job ios using the prepared data.
    for (const preparedJobIO of preparedJobIOs) {
      const { artifactId, jobId, ioType, options } = preparedJobIO
      const jobIO = yield call(createJobIO, headers, jobId, artifactId, ioType, { ...options, ...additionalJobIoOptionsByArtifacts[artifactId] || {} })
      createdJobIOs.push(jobIO)
    }
  }
  // Create the job runs.
  const additionalJobRunOptionsArtifactIds = Object.keys(additionalJobRunOptionsByArtifacts || {})
  yield put(PipelineWizardActions.changeCreationStatus(PipelineCreationStage.CREATE_JOB_RUNS, true))
  for (const templateJobId of Object.keys(jobMapping).map(key => parseInt(key))) {
    const jobIds = jobMapping[templateJobId]
    const optionsState = jobOptions[templateJobId]
    const options = optionsState ? optionsState.values : {}
    for (const jobId of jobIds) {
      const jobRunOptions = getJobRunOptions(pipelineTemplate, artifactMapping, jobId, createdJobIOs, options, additionalJobRunOptionsArtifactIds, additionalJobRunOptionsByArtifacts)
      yield call(createJobRun, headers, jobId, jobRunOptions)
    }
  }
  // Finalize the pipeline.
  yield put(PipelineWizardActions.changeCreationStatus(PipelineCreationStage.FINALIZE_PIPELINE, true))
  for (const pipelineId of pipelineIds) {
    yield call(finalizePipeline, { pipelineId })
    yield put(PipelinesActions.getPipeline(pipelineId))
  }
  // DONE
  history.push(routeProjectPipelines(projectId))
  toast.success(i18next.t(`toast.pipelineWizard.createPipeline${templateArtifactsToSplit.length > 1 ? 's' : ''}Success`))
  return artifacts
}

function * createSinglePipeline (args) {
  const {
    originalJobOptions,
    jobOptions,
    artifactOptions,
    jobRunOptions: allJobRunOptions,
    allSelectedArtifactIds,
    projectId,
    selectedTemplateArtifacts,
    emailNotification,
    name,
    headers,
    appState,
    pipelineTemplate,
    templateJobs,
    additionalJobRunOptionsByArtifacts,
    additionalJobIoOptionsByArtifacts,
  } = args
  // Create the pipeline.
  const pipelineSettingsMergeJobTemplateId = pipelineTemplate.pipelineSettingsMergeJobTemplateId
  const pipelinesSettings = originalJobOptions[pipelineTemplate.pipelineNameTemplateId]
  const pipelineName = pipelineTemplate.pipelineNameInConfigureStep
    ? pipelinesSettings.values.pipelineName
    : name
  const otherPipelineSettings = omit(['pipelineName'], (pipelinesSettings || {}).values) || {}
  const pipeline = yield call(createPipeline, headers, projectId, pipelineName, emailNotification)
  yield put(PipelinesActions.getPipelineSuccess(pipeline.id, pipeline))
  const pipelineId = pipeline.id
  // Create the `Jobs`.
  yield put(PipelineWizardActions.changeCreationStatus(PipelineCreationStage.CREATE_JOBS, true))
  const templateIds = []
  const jobs = []
  for (const templateJob of templateJobs) {
    if (!isDummyJob(templateJob)) {
      const job = yield call(createJob, headers, pipelineId, templateJob.jobType)
      jobs.push(job)
    }
    templateIds.push(templateJob.templateId)
  }
  const jobMapping = mergeResultsWithTemplates(jobs, templateIds)
  // Create output artifacts.
  yield put(PipelineWizardActions.changeCreationStatus(PipelineCreationStage.CREATE_ARTIFACTS, true))
  const outputArtifacts = getOutputArtifacts(pipelineTemplate)
  const artifactsBefore = yield select(state => getArtifacts(state))
  const selectedInputArtifacts = artifactsBefore.filter(artifact => allSelectedArtifactIds.indexOf(artifact.id) >= 0)
  const artifactTemplateIds = []
  const artifacts = []
  for (const templateArtifact of outputArtifacts) {
    const { artifactType, getArtifactName, getProperties, shouldBeCreated } = templateArtifact
    const artifactName = typeof getArtifactName === 'function'
      ? getArtifactName(selectedInputArtifacts, pipeline)
      : prettifyArtifactType(artifactType)
    const properties = typeof getProperties === 'function'
      ? getProperties(appState, jobOptions, artifactOptions)
      : {}
    const artifactShouldBeCreated = typeof shouldBeCreated === 'function'
      ? shouldBeCreated(appState, jobOptions, artifactOptions)
      : true
    if (artifactShouldBeCreated) {
      const artifact = yield call(createArtifact,
        headers,
        projectId,
        artifactName,
        artifactType,
        properties,
        getMissionIdForArtifactByType(artifactType, selectedInputArtifacts, jobs),
      )
      artifactTemplateIds.push(templateArtifact.templateId)
      artifacts.push(artifact)
    }
  }
  const createdArtifactsMapping = mergeResultsWithTemplates(artifacts, artifactTemplateIds)
  const artifactMapping = Object.keys(createdArtifactsMapping).reduce(
    (mapping, templateIdString) => {
      const templateId = parseInt(templateIdString)
      return {
        ...mapping,
        [templateId]: [
          ...(mapping[templateId] || []),
          createdArtifactsMapping[templateId],
        ],
      }
    },
    selectedTemplateArtifacts,
  )
  // Create the `jobIOs`.
  yield put(PipelineWizardActions.changeCreationStatus(PipelineCreationStage.CREATE_JOB_IOS, true))
  const preparedJobIOs = prepareJobIOs(jobMapping, artifactMapping, templateJobs, artifactOptions)
  // Finally create the job ios using the prepared data.
  const createdJobIOs = []
  for (const preparedJobIO of preparedJobIOs) {
    const { artifactId, jobId, ioType, options } = preparedJobIO
    if ('use_photos' in options && !options.use_photos) {
      continue
    }
    const newArtifactOptions = { ...options, ...additionalJobIoOptionsByArtifacts[artifactId] || {} }
    const jobIO = yield call(createJobIO, headers, jobId, artifactId, ioType, newArtifactOptions)
    createdJobIOs.push(jobIO)
  }
  // Create the job runs.
  const additionalJobRunOptionsArtifactIds = Object.keys(additionalJobRunOptionsByArtifacts)
  yield put(PipelineWizardActions.changeCreationStatus(PipelineCreationStage.CREATE_JOB_RUNS, true))
  for (const templateJobId of Object.keys(jobMapping).map(key => parseInt(key))) {
    const jobId = jobMapping[templateJobId]
    const optionsState = jobOptions[templateJobId]
    let options = optionsState ? optionsState.values : {}
    options = {
      ...allJobRunOptions,
      ...options,
      ...(templateJobId === pipelineSettingsMergeJobTemplateId && otherPipelineSettings),
    }
    const jobRunOptions = getJobRunOptions(pipelineTemplate, artifactMapping, jobId, createdJobIOs, options, additionalJobRunOptionsArtifactIds, additionalJobRunOptionsByArtifacts)
    if (jobRunOptions && jobRunOptions.flightlines && jobRunOptions.flightlines.intervals) {
      const intervalsByArtifact = jobRunOptions.flightlines.intervals
      const tasks = []
      const artifactIds = Object.keys(intervalsByArtifact)
      for (let i = 0; i < artifactIds.length; i++) {
        const artifactId = artifactIds[i]
        const intervalsToSend = intervalsByArtifact[artifactId]
        tasks.push(
          yield fork(
            axios.post,
            `/artifacts/${artifactId}/flight_intervals`,
            // Map every interval and remove unnecessary fields because when we clone pipeline with settings
            // it can contain fields like (created, updated, id, etc...) and it will lead to 422 response
            { intervals: intervalsToSend.map(interval => keepProps(['is_flight_line', 'name', 'end', 'beginning'], interval)) },
          ),
        )
      }
      try {
        const createdIntervalsByArtifacts = yield resolveTasks(tasks)
        jobRunOptions.flightlines.intervals = createdIntervalsByArtifacts.reduce((all, intervalContainer, index) => {
          const artifactId = artifactIds[index]
          return {
            ...all,
            [artifactId]: intervalContainer.intervals || [],
          }
        }, {})
      } catch (e) {
        toast.error('Error occurred while initializing trajectory flightlines')
      }
    }
    yield call(createJobRun, headers, jobId, jobRunOptions)
  }
  // Finalize the pipeline.
  yield put(PipelineWizardActions.changeCreationStatus(PipelineCreationStage.FINALIZE_PIPELINE, true))
  yield call(finalizePipeline, { pipelineId })
  // DONE
  yield put(PipelinesActions.getPipeline(pipelineId))
  toast.success(i18next.t('toast.pipelineWizard.createPipelineSuccess'))
  history.push(routeProjectPipelines(projectId, pipelineId))
  return artifacts
}

/**
 * This function is called by the middleware for creating and starting pipelines. It will
 *  - Create the pipeline itself.
 *  - Create all needed jobs.
 *  - Create all intermediate and output artifacts.
 *  - Create the job IOs, linking all artifacts to their corresponding jobs.
 *  - Create an initial job run for each job, including the options.
 *  - Finalize the pipeline.
 */
function * createStartPipeline ({ name, emailNotification, artifactOptions: originalArtifactOptions, onSuccessCallback }) {
  yield put(PipelineWizardActions.createStartPipelineLoading())
  const [
    token,
    currentUser,
    pipelineTemplate,
    originalJobOptions,
    jobRunOptions,
    selectedTemplateArtifacts,
    additionalJobRunOptionsByArtifacts,
    additionalJobIoOptionsByArtifacts,
    appState,
  ] = yield select(state => [
    getToken(state),
    getLoggedUser(state),
    getPipelineTemplate(state),
    getJobOptions(state),
    getCloneJobRunOptions(state),
    getSelectedTemplateArtifacts(state),
    getAdditionalJobRunOptions(state),
    getAdditionalJobIoOptions(state),
    state,
  ])
  const headers = getAuthentificationHeader(token)
  const { artifacts: pipelineTemplateArtifacts } = pipelineTemplate
  const templateArtifactsToCreatePipelines = pipelineTemplateArtifacts.filter(template => template.multiplePipelines)
  const shouldCreateMultiplePipelines = templateArtifactsToCreatePipelines.length > 0
  let templateJobs = pipelineTemplate.jobs || [] // getPipelineTemplateJobs(appState, pipelineTemplate)
  // Filter out templates with the `checkIfNotEmpty` property set to true and no transformed values
  templateJobs = templateJobs.filter(template => {
    const { templateId, checkIfNotEmpty } = template
    const currentJobOptions = originalJobOptions[templateId] || {}
    const fieldsTemplates = templateJobOptions[template.jobType]
    if (checkIfNotEmpty) {
      const transformedValues = getBackendTransformedValues(
        appState,
        currentJobOptions.values || {},
        currentJobOptions.rawValues || {},
        fieldsTemplates || {},
      )
      return Object.keys(transformedValues).length !== 0
    }
    return true
  })
  let jobOptions = {}
  let artifactOptions = {}
  let allSelectedArtifactIds = []
  try {
    jobOptions = getCustomJobOptions(appState, pipelineTemplate, originalJobOptions, templateJobs)
    artifactOptions = getCustomArtifactOptions(
      appState, pipelineTemplate, selectedTemplateArtifacts, originalArtifactOptions,
    )
    allSelectedArtifactIds = Object
      .keys(selectedTemplateArtifacts)
      .reduce((allIds, key) => [...allIds, ...selectedTemplateArtifacts[key]], [])
  } catch (e) {
    toast.error(i18next.t('toast.pipelineWizard.initError'))
    yield put(PipelineWizardActions.createStartPipelineFailure())
    return
  }
  try {
    const { projectId } = yield select(state => getCurrentProject(state))
    yield put(PipelineWizardActions.changeCreationStatus(PipelineCreationStage.CREATE_PIPELINE, true))
    const args = {
      originalJobOptions,
      jobOptions,
      artifactOptions,
      allSelectedArtifactIds,
      projectId,
      selectedTemplateArtifacts,
      emailNotification,
      name,
      headers,
      appState,
      pipelineTemplate,
      templateJobs,
      jobRunOptions,
      additionalJobRunOptionsByArtifacts,
      additionalJobIoOptionsByArtifacts,
      templateIdToSplit: templateArtifactsToCreatePipelines.map(template => template.templateId),
    }
    let artifacts
    if (shouldCreateMultiplePipelines) {
      artifacts = yield call(createMultiplePipelines, args)
    } else {
      artifacts = yield call(createSinglePipeline, args)
    }
    // DONE
    if (typeof onSuccessCallback === 'function') onSuccessCallback()
    yield put(PipelineWizardActions.changeCreationStatus(PipelineCreationStage.DONE, true))
    yield put(PipelineWizardActions.createStartPipelineSuccess())
    yield put(PipelineWizardActions.resetState())
    // Reset state for imported settings files
    yield put(ImportWizardActions.resetSettingsFiles())
    yield put(ImportWizardActions.reset(['recommendedCRS']))
    if (artifacts.length > 0) {
      yield put(ArtifactsActions.getArtifacts(artifacts.map(art => art.id)))
    }
    yield put(GrantsActions.getGrants(currentUser.id))
  } catch (e) {
    const currentStage = yield select(state => getCurrentCreationStage(state))
    console.log(e)
    const errorMessage = errorsMap[currentStage] || i18next.t('toast.pipelineWizard.error')
    toast.error(errorMessage)
    yield put(PipelineWizardActions.changeCreationStatus(currentStage, false, errorMessage))
    yield put(PipelineWizardActions.createStartPipelineFailure())
  }
}

function * getGCP ({ options, id }) {
  yield put(PipelineWizardActions.getGCPLoading())
  try {
    const response = yield getGCPCall(options)
    yield put(PipelineWizardActions.getGCPSuccess(response, id))
  } catch (e) {
    yield put(PipelineWizardActions.getGCPFailure(e.message || e))
  }
}

function * urlChange ({ url }) {
  // we need to reset state of the pipeline wizard when we change the project|go to the another pages
  //
}

// Watchers
function * createPipelineWatcher () {
  yield takeLatest(PipelineWizardTypes.CREATE_START_PIPELINE, createStartPipeline)
}

function * getGCPWatcher () {
  yield takeLatest(PipelineWizardTypes.GET_GCP, getGCP)
}

function * urlChangeWatcher () {
  yield takeLatest(UrlTypes.CHANGE_URL, urlChange)
}


export default function * root () {
  yield fork(createPipelineWatcher)
  yield fork(urlChangeWatcher)
  yield fork(getGCPWatcher)
}
