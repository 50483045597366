import i18n from 'i18n'
import { JobType } from 'types/jobs'

export const REF_STATION_BACKEND_NAME = 'coordinate_reference_system'
export const GCP_BACKEND_NAME = 'coordinate_reference_system'
export const SPATIAL_FUSER_BACKEND_NAME = 'output_crs'
export const LAS_BACKEND_NAME = 'crs'

export const LasSettings = {
  none: '',
  flightlines: 'flightlines',
  laser_id: 'laser_id',
  classification: 'classification',
  normalized_height: 'normalized_height',
  sensor_id: 'sensor_id',
  deviation: 'deviation',
  amplitude: 'amplitude',
  distance: 'distance',
  nir: 'nir',
}

// These options match cloud::Importer::CustomField in SE
export const MapLasSettingsBackendOptionsToFrontend = {
  [LasSettings.none]: 'None',
  [LasSettings.flightlines]: 'Flightlines',
  [LasSettings.laser_id]: 'Laser ID',
  [LasSettings.sensor_id]: 'Sensor ID',
  // [LasSettings.classification]: 'Classification',
  // [LasSettings.normalized_height]: 'Normalized Height',
  // [LasSettings.deviation]: 'Deviation',
  // [LasSettings.amplitude]: 'Amplitude',
  // [LasSettings.distance]: 'Distance',
  // [LasSettings.nir]: 'NIR',
}

export const LasSettingsFrontEndOptionNames = Object.values(MapLasSettingsBackendOptionsToFrontend)

export const MapLasSettingsFrontendOptionsToBackend = Object.keys(MapLasSettingsBackendOptionsToFrontend).reduce((all, key) => ({
  ...all,
  [MapLasSettingsBackendOptionsToFrontend[key]]: key,
}), {})

export const DynamicsModelOptions = [
  'Automatic',
  'Airborne',
  'Ground Vehicle',
  'Marine',
  'Pedestrian',
  'UAV',
]

export const analyzePriority = {
  opus: 1,
  centerpointRtx: 1,
  'opus (WGS84)': 1,
  'centerpointRtx (WGS84)': 1,
  ppp: 2,
  rinex: 3,
  average: 4,

  custom: 1000,
}

export const DatumPriority = [
  { datum: 'ITRF', priority: 0 },
  { datum: 'NAD83', priority: 1 },
  { datum: 'WGS', priority: 2 },
]

export const analyzeToDescription = {
  average: 'Average',
  ppp: 'PPP',
  opus: 'OPUS',
  rinex: 'RINEX Approximate Position',
  centerpointRtx: 'Centerpoint RTX',
  'opus (WGS84)': 'OPUS',
  'centerpointRtx (WGS84)': 'Centerpoint RTX',
  custom: 'Custom',
}

export const processorToFrontendReferenceStationAntennaName = {
  'AbsoluteL1PhaseCenter': i18n.t('templates.artifactOptions.navigationRover.options.heightAntennaReference.l1a'),
  // 'RelativeL1PhaseCenter': 'L1 Relative',
  'AntennaReferencePoint': i18n.t('templates.artifactOptions.navigationRover.options.heightAntennaReference.arp'),
}
export const backendProcessorToFrontendReferenceStationAntennaName = {
  'l1a': i18n.t('templates.artifactOptions.navigationRover.options.heightAntennaReference.l1a'),
  'arp': i18n.t('templates.artifactOptions.navigationRover.options.heightAntennaReference.arp'),
}

export const HeightAntennaOptions = [
  backendProcessorToFrontendReferenceStationAntennaName.l1a,
  backendProcessorToFrontendReferenceStationAntennaName.arp
]
export const HeightAntennaMapping = {
  [processorToFrontendReferenceStationAntennaName.AbsoluteL1PhaseCenter]: 'l1a',
  [processorToFrontendReferenceStationAntennaName.AntennaReferencePoint]: 'arp',
}

export const antennaInitialValue = {
  name: 'Generic (Recommended)',
  value: 'Generic',
  searchValue: 'generic-0',
}

export const Coordinates = {
  LONGITUDE: 'longitude',
  LATITUDE: 'latitude',
  ALTITUDE: 'heightMarker',
}

export const CoordinateTypes = {
  LAT_LON: 'lat/lon',
  NORTHING_EASTING: 'x/y',
  EASTING_NORTHING: 'Easting/Northing',
}

export const MapUnitNameToAbbr = {
  'metre': 'm',
  'foot': 'ft',
  'US survey foot': 'us ft',
}

export const GCPListType = {
  ALIGN: 'gcps_to_align',
  CHECKSHOTS: 'gcps_checkshots',
  IGNORE: 'gcps_to_ignore',
}

export const DeveloperSettingsJob = {
  templateId: 16,
  jobType: JobType.DEVELOPER_SETTINGS,
  fieldName: 'developer_settings',
  adminOnly: true,
  checkIfNotEmpty: true,
  mergeTemplateId: 0,
  inputs: [],
  outputs: [],
}

export const MapBackendNameToFrontend = {
  dataset_type: i18n.t('templates.jobOptions.pipelineSettings.dataset_type'),

  axisOrientation: i18n.t('templates.artifactOptions.navigationRover.axisOrientation'),
  coordinate_reference_systems: i18n.t('templates.crsOptions.coordinateReferenceSystem'),
  coordinate_reference_system: i18n.t('templates.crsOptions.coordinateReferenceSystem'),
  output_crs: i18n.t('templates.crsOptions.outputCoordinateReferenceSystem'),
  processing_datum: i18n.t('templates.crsOptions.processing_datum'),
  epoch: i18n.t('templates.crsOptions.epoch'),
  epochOld: i18n.t('templates.crsOptions.epochOld'),
  grid: i18n.t('templates.crsOptions.geoid'),
  geoid: i18n.t('templates.crsOptions.geoid'),
  geoid_crs: i18n.t('templates.crsOptions.geoid_crs'),
  h_units: i18n.t('templates.crsOptions.units_h'),
  unit_h: i18n.t('templates.crsOptions.units_h'),
  v_units: i18n.t('templates.crsOptions.units_v'),
  unit_v: i18n.t('templates.crsOptions.units_v'),
  horizontal_crs: i18n.t('templates.crsOptions.crs_h'),
  crs_h: i18n.t('templates.crsOptions.crs_h'),
  vertical_crs: i18n.t('templates.crsOptions.crs_v'),
  crs_v: i18n.t('templates.crsOptions.crs_v'),
  is_ellipsoidal: i18n.t('templates.crsOptions.is_ellipsoidal'),
  normalize_axis_order: i18n.t('templates.crsOptions.normalize_axis_order'),
  show_recommended: i18n.t('templates.crsOptions.show_recommended'),
  show_recommended_datums: i18n.t('templates.crsOptions.show_recommended_datums'),
  tooltips: {
    normalize_axis_order: i18n.t('templates.crsOptions.tooltip.normalize_axis_order'),
    show_recommended: i18n.t('templates.crsOptions.tooltip.show_recommended'),
    antennaName: i18n.t('templates.artifactOptions.generic.tooltip.antennaName'),
  },
  crs: i18n.t('templates.crsOptions.crs_c'),
  proj: i18n.t('templates.crsOptions.crs'),

  filtering: i18n.t('templates.jobs.ground_classification'),
  ptd_iteration_angle: i18n.t('templates.jobOptions.ground_classification.ptd_iteration_angle'),
  ptd_iteration_angle_tooltip: i18n.t('templates.jobOptions.ground_classification.tooltip.ptd_iteration_angle'),

  ptd_iteration_distance: i18n.t('templates.jobOptions.ground_classification.ptd_iteration_distance'),
  ptd_iteration_distance_tooltip: i18n.t('templates.jobOptions.ground_classification.tooltip.ptd_iteration_distance'),
  ptd_cell_size: i18n.t('templates.jobOptions.ground_classification.ptd_cell_size'),
  ground_thickness: i18n.t('templates.jobOptions.ground_classification.ground_thickness'),
  feature_size: i18n.t('templates.jobOptions.ground_classification.feature_size'),
  ptd_cell_size_tooltip: i18n.t('templates.jobOptions.ground_classification.tooltip.ptd_cell_size'),
  filter_moving_objects: i18n.t('templates.jobOptions.ground_classification.filter_moving_objects'),
  classify_powerlines: i18n.t('templates.jobOptions.ground_classification.classify_powerlines'),

  ai_classification: 'AI classification',
  useCheckpoint: i18n.t('templates.jobOptions.classification.useCheckpoint'),
  checkpoint: i18n.t('templates.jobOptions.classification.checkpoint'),

  flightlines: i18n.t('templates.jobs.flightlines'),
  min_duration: i18n.t('templates.jobOptions.flightlines.min_duration'),

  lidar_settings: i18n.t('templates.jobs.lidar_settings'),
  downwardfov: i18n.t('templates.jobOptions.lidar_settings.downwardfov'),
  set_full_density: i18n.t('templates.jobOptions.lidar_settings.set_full_density'),
  lidarrangemax: i18n.t('templates.jobOptions.lidar_settings.lidarrangemax'),
  lidarrangemin: i18n.t('templates.jobOptions.lidar_settings.lidarrangemin'),

  optimization: i18n.t('templates.jobs.optimization'),
  laser_intrinsicts: i18n.t('templates.jobOptions.optimization.laser_intrinsicts'),
  laser_orientation: i18n.t('templates.jobOptions.optimization.laser_orientation'),
  laser_ranging: i18n.t('templates.jobOptions.optimization.laser_ranging'),
  trj_optimization: i18n.t('templates.jobOptions.optimization.trj_optimization'),
  trj_optimization_use_gcp: i18n.t('templates.jobOptions.optimization.trj_optimization_use_gcp'),
  trj_optimization_tooltip: i18n.t('templates.jobOptions.optimization.tooltip.trj_optimization'),
  gcp_align_to_ground_only: i18n.t('templates.jobOptions.optimization.gcp_align_to_ground_only'),
  use_photos_in_turns: i18n.t('templates.jobOptions.optimization.use_photos_in_turns'),
  sensor_calibration: i18n.t('templates.jobOptions.optimization.sensor_calibration'),

  // products
  products: i18n.t('templates.jobs.products'),
  base_name: i18n.t('templates.jobOptions.products.base_name'),
  create_tiles: i18n.t('templates.jobOptions.products.create_tiles'),
  export_curves: i18n.t('templates.jobOptions.products.export_curves'),
  subsample_pointcloud: i18n.t('templates.jobOptions.products.subsample_pointcloud'),
  contours: i18n.t('templates.jobOptions.products.contours'),
  dsm_dem: i18n.t('templates.jobOptions.products.dsm_dem'),
  dsm_dem_pixel_size: i18n.t('templates.jobOptions.products.dsm_dem_pixel_size_wm'),
  geotiff_rgb: i18n.t('templates.jobOptions.products.geotiff_rgb'),
  geotiff_rgb_pixel_size: i18n.t('templates.jobOptions.products.geotiff_rgb_pixel_size_wm'),
  dtm_max_edge_length: i18n.t('templates.jobOptions.products.dtm_max_edge_length_wm'),
  dtm_grid_stride: i18n.t('templates.jobOptions.products.dtm_grid_stride_wm'),
  create_pointcloud_viewer: i18n.t('templates.jobOptions.products.create_pointcloud_viewer'),
  create_dtm_grid: i18n.t('templates.jobOptions.products.create_dtm_grid'),
  contour_interval: i18n.t('templates.jobOptions.products.contour_interval_wm'),
  tile_size: i18n.t('templates.jobOptions.products.tile_size_wm'),
  subsample_pointcloud_grid_stride: i18n.t('templates.jobOptions.products.subsample_pointcloud_grid_stride_wm'),
  use_laz: i18n.t('templates.jobOptions.products.use_laz'),
  use_laz_prod: i18n.t('templates.jobOptions.products.use_laz_prod'),
  export_las_1_4: i18n.t('templates.jobOptions.products.export_las_1_4'),
  export_las_1_2: i18n.t('templates.jobOptions.products.export_las_1_2'),
  export_las: i18n.t('templates.jobOptions.products.export_las'),
  smart_decimation: i18n.t('templates.jobOptions.products.smart_decimation'),
  smart_decimation_ppm: i18n.t('templates.jobOptions.products.smart_decimation_ppm'),
  create_orthomosaic: i18n.t('templates.jobOptions.products.create_orthomosaic'),
  tin_mesh: i18n.t('templates.jobOptions.products.tin_mesh'),
  tin_mesh_tooltip: i18n.t('templates.jobOptions.products.tooltip.tin_mesh'),
  custom: i18n.t('templates.jobOptions.products.custom'),
  secore_git_commit: i18n.t('templates.jobOptions.products.secore_git_commit'),
  worker_host: i18n.t('templates.jobOptions.products.worker_host'),
  profile_views: i18n.t('templates.jobOptions.products.profile_views'),
  export_pix4d_dat: i18n.t('templates.jobOptions.products.export_pix4d_dat'),
  // imu
  imu: i18n.t('templates.jobs.imu'),
  orientation: i18n.t('templates.artifactOptions.navigationRover.bodyToImuRotation'),
  local_crs: i18n.t('templates.jobs.local_crs'),

  calibration: i18n.t('templates.jobs.calibration'),

  output_datum: i18n.t('templates.jobOptions.navlab.output_datum'),
  time_range: 'Time range',

  index: i18n.t('templates.artifactOptions.camera.sensorIndex'),
  overrideTransform: i18n.t('templates.artifactOptions.generic.overrideTransform'),

  calibrateCamera: i18n.t('templates.artifactOptions.camera.calibrateCamera'),
  focal_length: i18n.t('templates.artifactOptions.camera.focalLength'),
  camera_pose_correction: i18n.t('templates.artifactOptions.camera.camera_pose_correction'),

  analyze: i18n.t('templates.artifactOptions.referenceStation.position'),
  datum: i18n.t('templates.artifactOptions.referenceStation.datum'),

  antennaName: i18n.t('templates.artifactOptions.generic.antennaName'),
  antennaRadome: i18n.t('templates.artifactOptions.referenceStation.antennaRadome'),

  heightAntenna: i18n.t('templates.artifactOptions.referenceStation.heightAntenna'),
  heightAntennaReference: i18n.t('templates.artifactOptions.generic.heightAntennaReference'),
  measuredTo: i18n.t('templates.artifactOptions.generic.measuredTo'),

  heightMarker: i18n.t('templates.artifactOptions.referenceStation.heightMarker'),
  latitude: i18n.t('templates.artifactOptions.referenceStation.latitude'),
  longitude: i18n.t('templates.artifactOptions.referenceStation.longitude'),

  leverArm1: i18n.t('templates.artifactOptions.navigationRover.leverArm1'),
  leverArm2: i18n.t('templates.artifactOptions.navigationRover.leverArm2'),

  dynamicsModel: i18n.t('templates.artifactOptions.navigationRover.dynamicsModel'),
  bodyToImuRotation: i18n.t('templates.artifactOptions.navigationRover.bodyToImuRotation'),
  min_gnss_velocity: i18n.t('templates.artifactOptions.navigationRover.min_gnss_velocity'),

  use_las: i18n.t('templates.jobOptions.orthoimage_generation.use_las'),
  use_reference_file: i18n.t('templates.jobOptions.orthoimage_generation.use_reference_file'),

  // crs options
  crsName: i18n.t('templates.crsOptions.name'),
  units: i18n.t('templates.crsOptions.units'),
  unit: i18n.t('templates.crsOptions.unit'),
  height_type: i18n.t('templates.crsOptions.height_type'),
  ellipsoidal: i18n.t('templates.crsOptions.options.height_type.ellipsoidal'),
  orthometric: i18n.t('templates.crsOptions.options.height_type.orthometric'),
}
