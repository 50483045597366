// External dependencies.
import React from 'react'
import { path } from 'ramda'
import _ from 'lodash'
import { Trans } from 'react-i18next'
import i18n from 'i18n'
// Material Ui dependencies.
// Icons
import NavIcon from '@material-ui/icons/Flight'
import CamIcon from '@material-ui/icons/CameraAlt'
import LdrIcon from '@material-ui/icons/Flare'
import FileIcon from '@material-ui/icons/InsertDriveFile'
import ImageIcon from '@material-ui/icons/Image'
import ReferenceStationIcon from '@material-ui/icons/Place'
import GcpIcon from '@material-ui/icons/GpsFixed'
import PlpIcon from '@material-ui/icons/Explore'
import LogIcon from '@material-ui/icons/Assignment'
import LasIcon from '@material-ui/icons/Map'
import TrjIcon from '@material-ui/icons/ShowChart'
import PolygonIcon from '@material-ui/icons/TabUnselected'
import DropBoxIcon from 'assets/images/DropboxIcon.svg'
// Internal dependencies.
import {
  ArtifactTypes,
  getCamFilePhotosNumber,
  isCameraArtifact,
  isPointcloudArtifact,
  isReconDataArtifact,
  isTrajectoryArtifact,
} from 'types/artifacts'
import {
  isRxpFileName,
  isOutFileName,
  isSmrFileName,
  isPoqFileName,
  isPofFileName,
  isPfsFileName,
  isTrjFileType,
  isCreatedFromPlp,
  isCamFileType,
  isLdrFileType,
  isGcpFileType,
  isPlpFileType,
  isLasFileType,
  isNavFileType,
  shouldDisplayTrajectoryImportForm,
  allowTrajectoryUploading,
  RieglHeaderFileSize,
  isRieglLdrFile,
  isRefStationFileType,
  isLogFileType,
  isSbetLogFileName,
  isImageFileType,
  isNavApplanixFileType,
  isTrjFileName,
} from 'modules/upload/file-types'
import { partition, reducePartition } from 'utils/list'
import { getArtifactsOfType, isCameraArtifactHasLadybugSensor } from 'utils/artifacts'
import { isBaslerOrPylonCamera } from 'modules/upload/file-types/utils'
import { validateJobOptionsForm } from '../TemplatedForm/utils'
import { isDropboxFile } from 'modules/importWizard/utils'
import { SystemType } from 'types/missions'
import { isSpatialExplorerV3SystemType, isSpatialExplorerV4V5SystemType, isHyspexSystemType } from 'utils/missions'
import { theme } from 'HOC/withRoot'
import { getBaseName, isFileNameEndsWith, replaceMultipleWhiteSpaces } from 'utils/baseName'
import { LasAllSettingsTemplate } from 'templates/CRS/LasCRSTemplate'
import { GCPCRSTemplate } from 'templates/CRS/GCPCRSTemplate'
import { LAS_BACKEND_NAME } from 'templates/constants'
import { isDataFileType } from 'modules/upload/file-types/data'
import { isDocFileType } from 'modules/upload/file-types/doc'
import { sortByField } from 'utils/sort'
import { isGeotiffFileType } from 'modules/upload/file-types/geotiff'
import { isKMLFileName, isKMLFileType } from 'modules/upload/file-types/kml'
import { isShapeFileName, isShapeFileOptional, isShapeFileRequired, isShapeFileType, RequiredPointcloudShapeFiles } from 'modules/upload/file-types/shape'
import { isXMLFileName, isXMLFileType } from 'modules/upload/file-types/xml'
import { isOBJFileType } from 'modules/upload/file-types/obj'
import { isDXFFileType } from 'modules/upload/file-types/dxf'
import { isPolygonFileType } from 'modules/upload/file-types/polygon'
// Local dependencies.
// import { ImportWizardProps } from '.'

// function getFileTypeIcon(fileType: string)
export function getFileTypeIcon (fileType) {
  if (isCamFileType(fileType)) {
    return <CamIcon />
  }
  if (isLdrFileType(fileType)) {
    return <LdrIcon />
  }
  if (isNavFileType(fileType) || isNavApplanixFileType(fileType)) {
    return <NavIcon />
  }
  if (isImageFileType(fileType)) {
    return <ImageIcon />
  }
  if (isRefStationFileType(fileType)) {
    return <ReferenceStationIcon />
  }
  if (isGcpFileType(fileType)) {
    return <GcpIcon />
  }
  if (isPlpFileType(fileType)) {
    return <PlpIcon />
  }
  if (isLogFileType(fileType)) {
    return <LogIcon />
  }
  if (isLasFileType(fileType)) {
    return <LasIcon />
  }
  if (isTrjFileType(fileType)) {
    return <TrjIcon />
  }
  if (isPolygonFileType(fileType)) {
    return <PolygonIcon/>
  }
  return <FileIcon />
}

export function getAnalyzedFileDescription (file) {
  if (isCamFileType(file.fileType)) {
    const info = file.parseResult.result
    return {
      key: 'importWizard.fileType.cam',
      values: {
        model: info.cameraModel,
        sensorIndex: info.sensorIndex,
        triggerMode: info.cameraTriggerMode,
        pinIndex: info.pin.index,
        pinBoard: info.pin.board,
      },
    }
  }
  if (isLdrFileType(file.fileType)) {
    if (!file.parseResult) {
      return {
        key: 'importWizard.fileType.ldrAuxiliary',
      }
    }
    const info = file.parseResult.result
    return {
      key: 'importWizard.fileType.ldr',
      values: {
        model: info.lidarModel,
        sensorIndex: info.sensorIndex,
      },
    }
  }
  if (isNavFileType(file.fileType) || isNavApplanixFileType(file.fileType)) {
    return { key: 'importWizard.fileType.nav' }
  }
  if (isImageFileType(file.fileType)) {
    return { key: 'importWizard.fileType.image' }
  }
  if (isRefStationFileType(file.fileType)) {
    return { key: 'importWizard.fileType.referenceStation' }
  }
  if (isGcpFileType(file.fileType)) {
    const info = file.parseResult.result
    return {
      key: 'importWizard.fileType.gcp',
      values: { numPoints: info.length },
    }
  }
  if (isPlpFileType(file.fileType)) {
    return { key: 'importWizard.fileType.plp' }
  }
  if (file.fileType === 'log') {
    return { key: 'importWizard.fileType.log' }
  }
  if (isTrjFileType(file.fileType)) {
    return { key: 'importWizard.fileType.trj' }
  }
  if (isLasFileType(file.fileType)) {
    return { key: 'importWizard.fileType.las' }
  }
  if (isDataFileType(file.fileType)) {
    return { key: 'importWizard.fileType.data' }
  }
  if (isDocFileType(file.fileType)) {
    return { key: 'importWizard.fileType.doc' }
  }
  if (isGeotiffFileType(file.fileType)) {
    return { key: 'importWizard.fileType.tiff' }
  }
  if (isKMLFileType(file.fileType)) {
    return { key: 'importWizard.fileType.kml' }
  }
  if (isXMLFileType(file.fileType)) {
    return { key: 'importWizard.fileType.xml' }
  }
  if (isOBJFileType(file.fileType)) {
    return { key: 'importWizard.fileType.obj' }
  }
  if (isDXFFileType(file.fileType)) {
    return { key: 'importWizard.fileType.dxf' }
  }
  if (isShapeFileType(file.fileType)) {
    return { key: 'importWizard.fileType.shape' }
  }
  if (isPolygonFileType(file.fileType)) {
    return { key: 'importWizard.fileType.polygon' }
  }
}

// function hasUnknownFiles(files: AnalyzedFile[]): boolean
export function hasUnknownFiles (files) {
  return files.some(file => file.fileType === 'unknown')
}

// function noBrokenFiles(files: AnalyzedFile[]): boolean
export function noBrokenFiles (files) {
  return files.every(file => file.okay)
}

// export interface BaseNameMap {
//     [baseName: string]: File[]
// }

// function getRinexBaseName(fileName: string)
export function getRinexBaseName (fileName) {
  const result = /^(.+?\.\d{2})[a-zA-Z]$/i.exec(fileName)
  return result === null ? undefined : result[1] // tslint:disable-line
}

// function getGpbEppBaseName(fileName: string)
export function getGpbEppBaseName (fileName) {
  const result = /^(.+?)\.(?:gpb|epp|sta)$/i.exec(fileName)
  return result === null ? undefined : result[1] // tslint:disable-line
}

// function getLdrBaseName(fileName: string)
export function getLdrBaseName (fileName) {
  const result = /^(.+?)\.(?:ldr|rxp|sdc|sdcx)$/i.exec(fileName)
  return result === null ? undefined : result[1]
}

// function getBaseNameMap(files: File[], getBaseName: (fileName: string) => string): BaseNameMap
export function getBaseNameMap (files, getBaseName) {
  return files.reduce(
    (result, file) => {
      const currentBaseName = getBaseName(file.name)
      if (typeof currentBaseName === 'undefined') {
        return result
      }
      const currentContent = result[currentBaseName]
      return {
        ...result,
        [currentBaseName]: [...(currentContent || []), file],
      }
    },
    {}
  )
}

const filterSuggestions = (suggestions, dataFiles) => {
  return suggestions.filter(suggestion => {
    const fileNames = suggestion.fileNames
    return fileNames.filter(fileName => !dataFiles.find(dataFile => dataFile.fileName === replaceMultipleWhiteSpaces(fileName))).length > 0
  })
}

// function getMissingRinexFiles(files: File[]): Suggestion[]
export function getMissingRinexFiles (files, dataFiles = []) {
  // A map of ('basename.15': ['basename.15o', 'basename.15n', 'basename.15r', ...]).
  const rinexMap = getBaseNameMap(files, getRinexBaseName)
  // Iterate over each collection of Rinex files.
  const rinexSuggestions = Object.keys(rinexMap).flatMap(
    baseName => {
      // A list of all files in this collection of rinex files.
      const filesPresent = rinexMap[baseName].map(file => file.name)
      const hasOD = filesPresent.some(name => Boolean(/^.*[od]$/i.exec(name)))
      const hasNP = filesPresent.some(name => Boolean(/^.*[np]$/i.exec(name)))
      const hasG = filesPresent.some(name => Boolean(/^.*g$/i.exec(name)))
      return [
        // If neither 'o' nor 'd' is present, prompt the user to upload 'basename.15(o/d)'.
        ...(hasOD ? [] : [{ fileNames: [`${baseName}o`, `${baseName}d`] }]),
        // If no 'n' file is present, prompt the user to upload 'basename.15n'.
        ...(hasNP ? [] : [{ fileNames: [`${baseName}n`, `${baseName}p`] }]),
        // If no 'g' file is present, suggest the user to upload 'basename.15g'.
        ...(hasG ? [] : [{ fileNames: [`${baseName}g`], optional: true }]),
      ]
    },
  )
  // Filter rinex suggestion if file is already uploaded
  return filterSuggestions(rinexSuggestions, dataFiles)
}

// function getMissingGpbEppFiles(files: File[]): Suggestion[]
export function getMissingGpbEppFiles (files, dataFiles = []) {
  const gpbEppMap = getBaseNameMap(files, getGpbEppBaseName)
  const gpbEppSuggestions = Object.keys(gpbEppMap).flatMap(
    baseName => {
      const filesPresent = gpbEppMap[baseName].map(file => file.name)
      const hasGPB = filesPresent.some(name => isFileNameEndsWith(name, 'gpb'))
      const hasEPP = filesPresent.some(name => isFileNameEndsWith(name, 'epp'))
      return [
        ...(hasGPB ? [] : [{ fileNames: [`${baseName}.gpb`] }]),
        ...(hasEPP ? [] : [{ fileNames: [`${baseName}.epp`] }]),
      ]
    },
  )
  // Filter gpbEpp suggestion if file is already uploaded
  return filterSuggestions(gpbEppSuggestions, dataFiles)
}

const getLdrSuggestionBaseData = (baseName, files) => {
  const filesPresent = files.filter(({ file }) => getLdrBaseName(file.name) === baseName)
  const ldr = filesPresent.find(({ file }) => isFileNameEndsWith(file.name, 'ldr'))
  const hasLdr = typeof ldr !== 'undefined'
  // A .ldr file can either be just the header, or the header + data.
  // The header size is 2KB.
  const ldrHasData = hasLdr && (ldr.file.size > RieglHeaderFileSize || (
    ldr.file.size <= RieglHeaderFileSize && !isRieglLdrFile(ldr)
  ))
  // .rxp, .sdc, .sdcx files
  const rxpFiles = filesPresent.filter(({ file }) => isRxpFileName(file.name))

  return {
    hasLdr,
    ldrHasData,
    ldr,
    rxpFiles,
  }
}

const getFilesList = files => {
  return files.map(file => file instanceof File
    ? { file: file }
    : file.file
      ? file
      : { file: file }
  )
}

// function getMissingLdrFiles(files: File[]): Suggestion[]
export function getMissingTrjFiles (files, dataFiles = []) {
  const filesPresent = getFilesList(files).map(file => file.file)
  const missingTrjSuggestions = []
  const poqFile = filesPresent.find(file => isPoqFileName(file.name))
  const pofFile = filesPresent.find(file => isPofFileName(file.name))
  // if (poqFile && !pofFile) missingTrjSuggestions.push({ fileNames: ['.pof'] })
  if (!poqFile && pofFile) missingTrjSuggestions.push({ fileNames: ['.poq'], optional: true })
  const sbetOutFile = filesPresent.find(file => isOutFileName(file.name))
  const smrmsgOutFile = filesPresent.find(file => isSmrFileName(file.name))
  // if (smrmsgOutFile && !sbetOutFile) missingTrjSuggestions.push({ fileNames: ['sbet.out'] })
  if (!smrmsgOutFile && sbetOutFile) missingTrjSuggestions.push({ fileNames: ['SMRMSG.out'], optional: true })
  return filterSuggestions(missingTrjSuggestions, dataFiles)
}

export function getMissingTrjFilesByMission (missionType, files, dataFiles = []) {
  if (isSpatialExplorerV3SystemType(missionType)) { return getMissingTrjFiles(files, dataFiles) }
  if (isSpatialExplorerV4V5SystemType(missionType)) { return getMissingTrjFiles(files, dataFiles) }
  if (isHyspexSystemType(missionType)) { return getHyspexMissingTrjFiles(files, dataFiles) }
  return getMissingTrjFiles(files, dataFiles)
}

export function getMissingPointcloudFilesByMission (missionType, files, dataFiles = []) {
  return getMissingPointcloudFiles(files, dataFiles)
}

// function getMissingLdrFiles(files: File[]): Suggestion[]
export function getMissingPointcloudFiles (files, dataFiles = []) {
  const filesPresent = getFilesList(files).map(file => file.file)
  const missingPointcloudSuggestions = []
  const someOptionalShape = filesPresent.find(file => isShapeFileOptional(file.name))
  const requiredShapeFiles = filesPresent.filter(file => isShapeFileRequired(file.name))
  const addedRequiredFiles = RequiredPointcloudShapeFiles.filter(
    fileExtension => requiredShapeFiles.some(shape => shape.name.endsWith(fileExtension))
  )
  const isAllRequiredShapeFilesValid = addedRequiredFiles.length === RequiredPointcloudShapeFiles.length
  // Optional shape files requires two more shape files to be uploaded
  // If we have no optional shape files we need to check if both all this files added
  if (
    (someOptionalShape && !isAllRequiredShapeFilesValid) ||
    (!someOptionalShape && requiredShapeFiles.length > 0 && !isAllRequiredShapeFilesValid)
  ) {
    RequiredPointcloudShapeFiles
      .filter(shapeExtension => !addedRequiredFiles.includes(shapeExtension))
      .forEach(shapeExtension => missingPointcloudSuggestions.push({ fileNames: ['*' + shapeExtension] }))
  }
  return filterSuggestions(missingPointcloudSuggestions, dataFiles)
}

export function getHyspexMissingTrjFiles (files, dataFiles = []) {
  const filesPresent = getFilesList(files).map(file => file.file)
  const missingTrjSuggestions = []
  const sbetOutFile = filesPresent.find(file => isOutFileName(file.name))
  const sbetLogFile = files.find(file => isLogFileType(file.fileType) && isSbetLogFileName(file.file.name))
  // if (smrmsgOutFile && !sbetOutFile) missingTrjSuggestions.push({ fileNames: ['sbet.out'] })
  if (!sbetLogFile && sbetOutFile) {
    const baseName = getBaseName(sbetOutFile.name)
    missingTrjSuggestions.push({ fileNames: [`${baseName}.log`], optional: true })
  }
  return filterSuggestions(missingTrjSuggestions, dataFiles)
}

/**
 * Returns the array of suggestions of the missing ldr files
 * @param {*} files
 * @param {*} dataFiles
 * @param {*} fileSuggestions
 */
// function getMissingLdrFiles(files: File[]): Suggestion[]
export function getMissingLdrFiles (files, dataFiles = [], fileSuggestions = []) {
  const transformedFiles = getFilesList(files)
  const filesList = transformedFiles.map(file => file.file)
  const ldrMap = getBaseNameMap(filesList, getLdrBaseName)
  const ldrSuggestions = Object.keys(ldrMap).flatMap(
    // Check each lidar base name.
    baseName => {
      const filesByBaseName = ldrMap[baseName]
      const filteredTransformedFiles = transformedFiles.filter(({ file }) => filesByBaseName.find(fileByBaseName => fileByBaseName.name === file.name))
      const { hasLdr, ldrHasData, rxpFiles } = getLdrSuggestionBaseData(baseName, filteredTransformedFiles)
      if (hasLdr) {
        // If there is an .ldr with no data, then we require either an .sdcx, .sdc or an .rxp.
        const noMoreDataRequired = ldrHasData || rxpFiles.length > 0
        return noMoreDataRequired ? [] : [{
          fileNames: [`${baseName}.rxp`, `${baseName}.sdc`, `${baseName}.sdcx`],
        }]
      } else {
        // If we don't have an .ldr, but .rxp or .sdc then we require the .ldr.
        if (!rxpFiles.every(file => fileSuggestions.indexOf(file.name) >= 0)) {
          return [{ fileNames: [`${baseName}.ldr`] }]
        }
        return []
      }
    },
  )
  // Filter ldr suggestion if file is already uploaded
  return filterSuggestions(ldrSuggestions, dataFiles)
}

// Helper function to filter unique suggestions
function filterUniqueSuggestions(suggestions) {
  const seen = new Set();
  return suggestions.filter(suggestion => {
    const key = JSON.stringify(suggestion); // generate a unique key for each suggestion
    if (seen.has(key)) {
      return false;
    } else {
      seen.add(key);
      return true;
    }
  });
}

/**
 * Returns the array of suggestions of the missing ldr files based on spatial explorer v4/v5 mission
 * @param {*} files
 * @param {*} dataFiles
 * @param {*} fileSuggestions
 */
export function getMissionV4MissingLdrFiles(files, dataFiles = [], fileSuggestions, duplicateFiles) {
  const transformedFiles = getFilesList(files)
  const filesList = transformedFiles.map(file => file.file)
  const ldrMap = getBaseNameMap(filesList, getLdrBaseName)
  const ldrSuggestions = Object.keys(ldrMap).flatMap(
    // Check each lidar base name.
    baseName => {
      const filesByBaseName = ldrMap[baseName]
      const filteredTransformedFiles = transformedFiles.filter(({ file }) => filesByBaseName.find(fileByBaseName => fileByBaseName.name === file.name))
      const { hasLdr, ldrHasData, rxpFiles, ldr } = getLdrSuggestionBaseData(baseName, filteredTransformedFiles)
      const dataToAdd = []
      // If there is an .ldr and and .rxp|.sdc|.sdcx files, then we recommend just to use just .rxp|.sdc|.sdcx files
      if (hasLdr && rxpFiles.length > 0) {
        dataToAdd.push({
          type: 'v3suggestion',
          fileNames: [ldr.name],
          values: {
            rxpFile: rxpFiles[0].name,
            ldrFile: [ldr.name],
          },
          optional: true,
        })
      } else if (hasLdr) {
        // If there is an .ldr with no data, then we require either an .sdcx, .sdc or an .rxp.
        const noMoreDataRequired = ldrHasData || rxpFiles.length > 0
        // If the file is .rxp, .sdc, .sdcx we need only one with such baseName
        if (!noMoreDataRequired) dataToAdd.push({ fileNames: [`${baseName}.rxp`, `${baseName}.sdc`, `${baseName}.sdcx`] })
      }
      // If we have multiple RXP files with the same baseName we upload only one from them
      if (duplicateFiles && duplicateFiles.length > 0) {
        duplicateFiles.map(file => {
          const fileNames = [getLdrBaseName(file.file.name)]
          dataToAdd.push({
            type: 'multipleRxp',
            fileNames,
            values: {
              fileNames: fileNames[0],
            },
            optional: true,
          })
        });
      }
      return dataToAdd
    },
  )
  const uniqueLdrSuggestions = filterUniqueSuggestions(ldrSuggestions);
  // Filter ldr suggestion if file is already uploaded
  return filterSuggestions(uniqueLdrSuggestions, dataFiles)
}

/**
 * Returns the array of file names of the missing ldr files based on spatial explorer v4/v5 mission
 * @param {*} files
 * @param {*} fileSuggestions
 */
function getMissionV4MissingLdrFileNamesSuggestions (files, fileSuggestions) {
  const filesList = getFilesList(files)
  return filesList.reduce((missingSuggestions, { file }) => {
    const fileName = file.name
    const baseName = getLdrBaseName(fileName)
    const { hasLdr, ldrHasData, rxpFiles, ldr } = getLdrSuggestionBaseData(baseName, filesList)
    let dataToAdd = missingSuggestions
    if (hasLdr && rxpFiles.length > 0) {
      dataToAdd.push([ldr.name])
    } else if (hasLdr) {
      // If there is an .ldr with no data, then we require either an .sdcx, .sdc or an .rxp.
      const noMoreDataRequired = ldrHasData || rxpFiles.length > 0
      if (!noMoreDataRequired) dataToAdd = dataToAdd.concat([`${baseName}.rxp`, `${baseName}.sdc`, `${baseName}.sdcx`])
    }
    // If we have multiple RXP files with the same baseName we should upload only one from them
    if (rxpFiles.length > 1) dataToAdd = dataToAdd.concat(rxpFiles.map(file => file.name))
    return dataToAdd
  }, [])
}

/**
 * Returns the object { [BASENAME]: `[FILENAME1, FILENAME2]`, ... }
 * that shows which files required for the file with the [BASENAME]
 * @param {*} files
 * @param {*} fileSuggestions
 */
export function getMissingLdrFilesBaseNameSuggestions (files, fileSuggestions = []) {
  const filesList = getFilesList(files)
  return filesList.reduce((missingSuggestions, { file }) => {
    const fileName = file.name
    const baseName = getLdrBaseName(fileName)
    const { hasLdr, ldrHasData, rxpFiles } = getLdrSuggestionBaseData(baseName, filesList)
    if (hasLdr) {
      // If there is an .ldr with no data, then we require either an .sdcx, .sdc or an .rxp.
      const noMoreDataRequired = ldrHasData || rxpFiles.length > 0
      if (noMoreDataRequired) {
        return missingSuggestions
      } else {
        return {
          ...missingSuggestions,
          [baseName]: [`${baseName}.rxp`, `${baseName}.sdc`, `${baseName}.sdcx`],
        }
      }
    } else {
      // If we don't have an .ldr, but .rxp or .sdc then we require the .ldr.
      if (!rxpFiles.every(file => fileSuggestions.indexOf(file.name) >= 0)) {
        return {
          ...missingSuggestions,
          [baseName]: [`${baseName}.ldr`],
        }
      }
      return missingSuggestions
    }
  }, {})
}

// function checkRinexDependencies (files: File[])
export function checkRinexDependencies (files, dataFiles = []) {
  return getMissingRinexFiles(files, dataFiles).every(suggestion => suggestion.optional)
}

export function checkGpbEppDependencies (files, dataFiles = []) {
  return getMissingGpbEppFiles(files, dataFiles).every(suggestion => suggestion.optional)
}

/**
 * We should continue uploading if every suggestions is optional for the selected mission type
 * @param {*} mission
 * @param {*} files
 * @param {*} dataFiles
 */
export function checkLdrDependencies (mission, files, dataFiles = []) {
  let ldrDependencies = getMissingLdrFiles(files, dataFiles)
  if (isSpatialExplorerV3SystemType(mission.missionType)) ldrDependencies = getMissingLdrFiles(files, dataFiles)
  if (isSpatialExplorerV4V5SystemType(mission.missionType)) ldrDependencies = getMissionV4MissingLdrFiles(files, dataFiles)
  if (isHyspexSystemType(mission.missionType)) ldrDependencies = getMissionV4MissingLdrFiles(files, dataFiles)
  return ldrDependencies.every(suggestion => suggestion.optional)
}

// function allReferenceStationsValid(protoArtifacts: ProtoArtifact[]): boolean
export function allReferenceStationsValid (protoArtifacts) {
  return getArtifactsOfType(ArtifactTypes.REFERENCE_STATION, protoArtifacts)
    .every(protoArtifact => {
      const files = protoArtifact.files.map(analyzedFile => analyzedFile.file)
      return files.length > 0 &&
        checkRinexDependencies(files) &&
        checkGpbEppDependencies(files) &&
        protoArtifact.properties.valid
    })
}

/*
export function isCameraArtifactValid(artifactType: string, files: File[]): boolean {
    if (artifactType !== ArtifactTypes.CAMERA_DATA) {
        return true
    }
    return files.some(file => isCamFileName(file.name))
}
*/
export function isCameraArtifactValid (artifactType, files) {
  if (!isCameraArtifact(artifactType)) {
    return true
  }
  return true// files.some(file => isCamFileName(file.name))
}

/**
 * Basically, this function is used when you try to upload a file,
 * but this file needs some other file to be uploaded too.
 * @param files
 * @param functionToFindDependentFileName Function to recognize dependent file among all files
 * @param functionToFindRequiredFileName  Function to recognize required file among all files
 */
// function isFileRequireAnotherFile (
//   files: AnalyzedFile[],
//   functionToFindDependentFileName: (fileName: string) => boolean,
//   functionToFindRequiredFileName: (fileName: string) => boolean
//   ): boolean
function isFileRequireAnotherFile (
  files,
  functionToFindDependentFileName,
  functionToFindRequiredFileName
) {
  const dependentFile = files.find(file => functionToFindDependentFileName(file.file.name))
  const requiredFile = dependentFile && files.find(file =>
    functionToFindRequiredFileName(file.file.name) && file.file.name !== dependentFile.file.name
  )
  // !! in order to make sure it returns boolean
  return !!(dependentFile && !requiredFile)
}

/**
 * Checks is some trajectory artifact from the list is invalid
 * @param {array} artifacts
 */
export const isSomeTrajectoryArtifactInvalid = (artifacts, fileSuggestions = []) => {
  const invalidPointcloudArtifacts = getArtifactsOfType(ArtifactTypes.POINTCLOUD, artifacts).filter(protoArtifact => {
    const { files } = protoArtifact
    return files.some(file => isTrjFileType(file.fileType))
  }).some(artifact => !isValidTrajectoriesPointcloudArtifact(artifact, fileSuggestions))
  const invalidTrajectoryArtifacts = getArtifactsOfType(ArtifactTypes.TRAJECTORY, artifacts)
    .some(artifact => !isValidTrajectoryArtifact(artifact, fileSuggestions))
  return invalidTrajectoryArtifacts || invalidPointcloudArtifacts
}

/**
 * Checks is some recon artifact from the list is invalid
 * @param {array} artifacts
 */
export const isSomeReconArtifactInvalid = (artifacts, fileSuggestions = []) => {
  const invalidReconArtifacts = getArtifactsOfType(ArtifactTypes.RECON_DATA, artifacts).filter(protoArtifact => {
    const { files } = protoArtifact
    return files.some(file => isDataFileType(file.fileType))
  }).some(artifact => !isValidReconArtifact(artifact, fileSuggestions))
  return invalidReconArtifacts
}

/**
 * Returns true if the given `ProtoArtifact has files in it that:
 * 0) All files are recon data files (fileType === "data")
 * 1) And there is no gaps between file names (e.g. RECON-001.data, RECON-003.data -> RECON-002.data is missing)
 * @params The `ProtoArtifact` to check
 */
// function isValidTrajectoryArtifact(protoArtifact: ProtoArtifact): boolean
export function isValidReconArtifact (protoArtifact) {
  const { files } = protoArtifact
  const reconDataFiles = files.filter(file => isDataFileType(file.fileType))
  const indices = _.sortBy(reconDataFiles.reduce((all, file) => {
    const baseName = getBaseName(file.file.name)
    const splitted = baseName.split('-')
    const index = +splitted[splitted.length - 1]
    return [...all, index]
  }, []).filter(index => Number.isInteger(index) && !Number.isNaN(index)))

  let isReconDataFileMissing = false
  for (let i = 1; i < indices.length; i++) {
    const index = indices[i]
    const prevIndex = indices[i - 1]
    if (index - prevIndex !== 1) {
      isReconDataFileMissing = true
      break
    }
  }
  const isAllFileSizesValid = reconDataFiles.every((file, index) => isDataFileSizeValid(file, index))
  return reconDataFiles.length === files.length && !isReconDataFileMissing && isAllFileSizesValid
}

/**
 * Returns true if the given `ProtoArtifact has files in it that:
 * 0) All files are trajectory data files (fileType === "trj")
 * 1) If there is .poq file, so we need .pof file
 * 2) If there is SMRMSG.out file, so we need .out file
 * 3) One and only one trajectory path file
 * 4) If there is .pof or .out files added the properties should be filled
 * @params The `ProtoArtifact` to check
 */
// function isValidTrajectoryArtifact(protoArtifact: ProtoArtifact): boolean
export function isValidTrajectoriesPointcloudArtifact (protoArtifact, fileSuggestions = []) {
  const { files, properties } = protoArtifact
  const trajectoryFiles = files.filter(file => isTrjFileType(file.fileType))
  const filteredFiles = trajectoryFiles.filter(file => fileSuggestions.indexOf(file.file.name) < 0)
  // If there is a .poq file we need at least one .pof
  const isPofFileRequired = isFileRequireAnotherFile(filteredFiles, isPoqFileName, isPofFileName)
  // If there is a .SMRMSG file we need .out
  const isOutFileRequired = isFileRequireAnotherFile(filteredFiles, isSmrFileName, isOutFileName)
  // Finding all pof, out, path files
  const trajectoryPathFileNames = filteredFiles
    .map(file => file.file.name)
    .filter(fileName => allowTrajectoryUploading(fileName))

  // Condition 4
  const shouldFilesBeFilledIn = files.find(({ file: { name } }) => shouldDisplayTrajectoryImportForm(name))
  const propertiesValid = shouldFilesBeFilledIn ? properties.valid : true

  return (
    !isPofFileRequired &&
    !isOutFileRequired &&
    trajectoryPathFileNames.length === 1 &&
    propertiesValid
  )
}

/**
 * Returns true if the given `ProtoArtifact has files in it that:
 * 0) All files are trajectory data files (fileType === "trj")
 * 1) If there is .poq file, so we need .pof file
 * 2) If there is SMRMSG.out file, so we need .out file
 * 3) One and only one trajectory path file
 * 4) If there is .pof or .out files added the properties should be filled
 * @params The `ProtoArtifact` to check
 */
// function isValidTrajectoryArtifact(protoArtifact: ProtoArtifact): boolean
export function isValidTrajectoryArtifact (protoArtifact, fileSuggestions = []) {
  const { files, properties, createdFrom = '' } = protoArtifact
  const createdFromPlp = isCreatedFromPlp(createdFrom)
  const allTrajectoryFiles = files.every(file => isTrjFileType(file.fileType))
  const filteredFiles = files.filter(file => fileSuggestions.indexOf(file.file.name) < 0)
  // If there is a .poq file we need at least one .pof
  const isPofFileRequired = isFileRequireAnotherFile(filteredFiles, isPoqFileName, isPofFileName)
  // If there is a .SMRMSG file we need .out
  const isOutFileRequired = isFileRequireAnotherFile(filteredFiles, isSmrFileName, isOutFileName)
  // Finding all pof, out, path files
  const trajectoryPathFileNames = filteredFiles
    .map(file => file.file.name)
    .filter(fileName => allowTrajectoryUploading(fileName))

  // Condition 4
  const shouldFilesBeFilledIn = files.find(({ file: { name } }) => shouldDisplayTrajectoryImportForm(name))
  const propertiesValid = shouldFilesBeFilledIn ? properties.valid : true

  return (
    allTrajectoryFiles &&
    !isPofFileRequired &&
    !isOutFileRequired &&
    (createdFromPlp ? true : trajectoryPathFileNames.length === 1) &&
    propertiesValid
  )
}

export function getReconDataFilesConflictMessagesByMission (missionType, inputFiles, protoArtifacts = [], fileSuggestions = []) {
  switch (missionType) {
    case SystemType.PHOENIX_V3:
    case SystemType.PHOENIX_V4_V5:
    case SystemType.POINTCLOUD_PROCESSINGS:
    case SystemType.HYSPEX:
      return {}
    default:
      return getReconDataFilesConflictMessages(inputFiles, protoArtifacts, fileSuggestions)
  }
}

export const ReconDataFileSize = 268435456
/**
 * For recon dataset all .data files but the last are enforced to be exactly 268,435,456 bytes
 * Issue #1084
 */
export function isDataFileSizeValid (file, index) {
  return !(file.file.size !== ReconDataFileSize && index !== 0)
}

// function getTrajectoryFilesConflictMessages(inputFiles: AnalyzedFile[]): JSX.Element[]
export function getReconDataFilesConflictMessages (inputFiles, protoArtifacts = [], fileSuggestions = []) {
  return protoArtifacts
    .filter(protoArtifact => isReconDataArtifact(protoArtifact.artifactType))
    .reduce((allTrajectoryArtifactsMessages, protoArtifact) => {
      const { files } = protoArtifact
      const reconDataFiles = files.filter(file => isDataFileType(file.fileType))
      const indices = reconDataFiles.reduce((all, file) => {
        const baseName = getBaseName(file.file.name)
        const splitted = baseName.split('-')
        const index = +splitted[splitted.length - 1]
        return [
          ...all,
          {
            index,
            splittedBaseName: splitted,
          },
        ]
      }, []).filter(({ index }) => Number.isInteger(index) && !Number.isNaN(index)).sort(sortByField('index'))
      const errors = []
      for (let i = 1; i < indices.length; i++) {
        const index = indices[i]
        const prevIndex = indices[i - 1]
        const diff = index.index - prevIndex.index
        if (diff !== 1) {
          const splittedBaseName = index.splittedBaseName
          const startOfFileName = splittedBaseName.slice(0, splittedBaseName.length - 1)
          const maxCharsToPad = index.splittedBaseName[index.splittedBaseName.length - 1].length
          for (let j = prevIndex.index + 1; j < index.index; j++) {
            const missingIndexString = j.toString()
            const zeroCharsToPad = maxCharsToPad - missingIndexString.length
            const fileNameToUpload = [
              ...startOfFileName,
              missingIndexString.padStart(missingIndexString.length + zeroCharsToPad, '0'),
            ].join('-') + `.data`
            errors.push(
              <Trans
                i18nKey='importWizard.step1.missingFile'
                values={{ fileName: fileNameToUpload }}
              />
            )
          }
        }
      }
      const filesWithInvalidSize = reconDataFiles.filter((file, index) => !isDataFileSizeValid(file, index))
      if (filesWithInvalidSize.length > 0) {
        errors.push(
          <Trans i18nKey='importWizard.step1.invalidSize' values={{ files: filesWithInvalidSize.length }}/>
        )
      }
      return {
        ...allTrajectoryArtifactsMessages,
        [protoArtifact.name]: errors.filter(Boolean),
      }
    }, {})
}

export function getTrajectoryFilesConflictMessagesByMission (missionType, inputFiles, protoArtifacts = [], fileSuggestions = []) {
  switch (missionType) {
    case SystemType.PHOENIX_V3:
    case SystemType.PHOENIX_V4_V5:
    case SystemType.HYSPEX:
      return getTrajectoryFilesConflictMessages(inputFiles, protoArtifacts, fileSuggestions)
    default:
      return getTrajectoryFilesConflictMessages(inputFiles, protoArtifacts, fileSuggestions)
  }
}

// function getTrajectoryFilesConflictMessages(inputFiles: AnalyzedFile[]): JSX.Element[]
export function getTrajectoryFilesConflictMessages (inputFiles, protoArtifacts = [], fileSuggestions = []) {
  // const someTrajectoryArtifactInvalid = isSomeTrajectoryArtifactInvalid(protoArtifacts, fileSuggestions)
  // const { true: trjFiles } = partition(file => isTrjFileType(file.fileType), inputFiles)
  return protoArtifacts
    .filter(protoArtifact =>
      isTrajectoryArtifact(protoArtifact.artifactType) || (
        isPointcloudArtifact(protoArtifact.artifactType) &&
        protoArtifact.files.some(file => isTrjFileType(file.fileType))
      )
    )
    .reduce((allTrajectoryArtifactsMessages, protoArtifact) => {
      const { files, createdFrom = '' } = protoArtifact
      const trjFiles = files.filter(file => isTrjFileType(file.fileType))
      // If there is a .poq file we need at least one .pof
      const isPofFileRequired = isFileRequireAnotherFile(trjFiles, isPoqFileName, isPofFileName)
      // If there is a .SMRMSG file we need .out
      const isOutFileRequired = isFileRequireAnotherFile(trjFiles, isSmrFileName, isOutFileName)
      const trajectoryPathFileNames = trjFiles
        .map(file => file.file.name)
        .filter(fileName => allowTrajectoryUploading(fileName))
      const createdFromPlp = isCreatedFromPlp(createdFrom)
      return {
        ...allTrajectoryArtifactsMessages,
        [protoArtifact.name]: [
          trajectoryPathFileNames.length > 1 && !createdFromPlp
            ? <Trans
              i18nKey='importWizard.step1.multiFileConflict'
              values={{ fileNames: trajectoryPathFileNames.join(', ') }}
            />
            : undefined,
          isPofFileRequired
            ? <Trans
              i18nKey='importWizard.step1.oneMoreFileRequired'
              values={{ fileName: '.pof' }}
            />
            : undefined,
          isOutFileRequired
            ? <Trans
              i18nKey='importWizard.step1.oneMoreFileRequired'
              values={{ fileName: '.out' }}
            />
            : undefined,
        ].filter(Boolean),
      }
    }, {})
}

/**
 *  Returns true iff the given `ProtoArtifact has files in it that:
 * 1) Include one and only one `AnalyzedCamFile`
 * 2) If camera model is Basler or Pylon - can include one and only one `.pfs` file
 * 3) and all other files in it are `AnalyzedImageFile`
 * @params The `ProtoArtifact` to check
 */
// function isValidCamArtifact(protoArtifact: ProtoArtifact): boolean
function isValidCamArtifact (protoArtifact) {
  const { files: protoArtifactFiles } = protoArtifact
  const protoArtifactFilesCount = protoArtifactFiles.length
  const files = partition(file => isImageFileType(file.fileType), protoArtifactFiles)
  const { true: imageFiles, false: otherFiles } = files
  const camFile = otherFiles.length === 1 && otherFiles[0] && isCamFileType(otherFiles[0].fileType) && otherFiles[0]
  if (camFile) {
    const camFilePhotosNumber = getCamFilePhotosNumber(camFile)
    const numberOfFilesWithCamFile = camFilePhotosNumber + 1
    const model = path(['parseResult', 'result', 'cameraModel'], camFile)
    const pfsFiles = imageFiles.filter(file => isPfsFileName(file.file.name))
    // If cam file is parsed and the model is Basler or Pylon we can add one more file - .pfs
    if (isBaslerOrPylonCamera(model)) {
      return pfsFiles.length > 1
        ? false
        : pfsFiles.length === 1
          // 1 stands for .pfs file
          ? numberOfFilesWithCamFile + 1 === protoArtifactFilesCount
          : numberOfFilesWithCamFile === protoArtifactFilesCount
    }
    return numberOfFilesWithCamFile === protoArtifactFilesCount
  }
  return false
}

// function allCameraProtoArtifactsValid(protoArtifacts: ProtoArtifact[]): boolean
export function allCameraProtoArtifactsValid (protoArtifacts) {
  return getArtifactsOfType(ArtifactTypes.CAMERA_DATA, protoArtifacts)
    .every(protoArtifact => {
      const { files, fileSuggestions } = protoArtifact
      const camFile = files.find(file => isCamFileType(file.fileType))
      let fileSuggestionsCount = fileSuggestions.length
      let filesCount = files.length
      if (!camFile) {
        for (let i = fileSuggestions.length - 1; i >= 0; i--) {
          const isPfsFileAdded = files.find(file => isPfsFileName(file.file.name))
          const isPfsFile = isPfsFileName(fileSuggestions[i])
          // Pfs file doesn't need to go in count
          if (isPfsFile) {
            fileSuggestionsCount -= 1
            filesCount -= isPfsFileAdded ? 1 : 0
            break
          }
        }
      }
      if (files.some(file => file.file.size <= 0)) {
        return false
      }
      return (
      // The `protoArtifact` isn't a `CAMERA_DATA` `Artifact`.
        !isCameraArtifact(protoArtifact.artifactType) ||
      // The `protoArtifact` has no suggestions and has been created by the user (requires .cam file).
      // and photos number required by the .cam file is equal to files count
      (
        fileSuggestionsCount === 0 && isValidCamArtifact(protoArtifact)
      ) ||
      (
        fileSuggestionsCount > 0 && (
          // ... and (has same number of files and suggestions OR has no files).
          fileSuggestionsCount === filesCount ||
          filesCount === 0
        )
      ) ||
      // The `protoArtifact` that has `Ladybug` sensor
      (
        isCameraArtifactHasLadybugSensor(protoArtifact) && (
          (fileSuggestionsCount !== 0 && filesCount === fileSuggestionsCount) ||
          // Allow to upload mission with 0 images for ladybug camera??
          (fileSuggestionsCount === 0 && filesCount === 0) ||
          (fileSuggestionsCount === 0 && filesCount > 0)
        )
      )
      )
    }
    )
}

// function allGroundControlPointsConfigured (protoArtifacts: ProtoArtifact[]): boolean
export function allGroundControlPointsConfigured (protoArtifacts, state = {}) {
  return getArtifactsOfType(ArtifactTypes.GROUND_CONTROL_POINTS, protoArtifacts)
    .every(protoArtifact => {
      const { properties, files } = protoArtifact
      if (files.length <= 0) {
        return true
      }
      if (typeof properties === 'undefined') {
        return false
      }
      const { startIndex, columnAssignments } = properties
      // The `name` column may be undefined.
      return typeof startIndex !== 'undefined' &&
        typeof columnAssignments !== 'undefined' &&
        typeof columnAssignments.x !== 'undefined' &&
        typeof columnAssignments.y !== 'undefined' &&
        typeof columnAssignments.z !== 'undefined' &&
        validateJobOptionsForm(properties, GCPCRSTemplate, state, protoArtifact.id)
    })
}

export function allPointcloudsConfigured (protoArtifacts, state = {}, files) {
  return getArtifactsOfType(ArtifactTypes.POINTCLOUD, protoArtifacts)
    .every(protoArtifact => {
      const { properties } = protoArtifact
      const actualFiles = files || protoArtifact.files || []
      if (actualFiles.length <= 0) {
        return true
      }
      if (typeof properties === 'undefined') {
        return false
      }
      const { fileProperties = {} } = properties
      const lasSettings = path(['las_settings', 'fields_mapping'], properties) || {}
      const visualizationSettings = path(['las_settings', 'visualization'], properties) || {}
      actualFiles.forEach(file => {
        file.fileName = path(['file', 'name'], file)
      })
      const someOptionalShape = actualFiles.find(file => isShapeFileOptional(file.fileName))
      // All files that is required to upload shape files
      const requiredShapeFiles = actualFiles.filter(file => isShapeFileRequired(file.fileName))
      const isAllRequiredShapeFilesValid = RequiredPointcloudShapeFiles.every(
        fileExtension => requiredShapeFiles.some(shape => shape.fileName.endsWith(fileExtension))
      )
      let isShapeFilesValid = true
      // Optional shape files requires two more shape files to be uploaded
      // If we have no optional shape files we need to check if both of this files added
      if (
        someOptionalShape ||
        (!someOptionalShape && requiredShapeFiles.length > 0)
      ) {
        isShapeFilesValid = isAllRequiredShapeFilesValid
      }
      return isShapeFilesValid && actualFiles.every(file => {
        const fileName = file.fileName
        // We should check for correct CRS only for LAS file, because for trajectory files it will be autopopulated from LAS file crs
        // For KML we don't have any properties, so just skip
        if (
          isTrjFileName(fileName) ||
          isKMLFileName(fileName) ||
          isShapeFileName(fileName) ||
          isXMLFileName(fileName)
        ) {
          return true
        }
        const valuesProperties = fileProperties[fileName]
        const crsValues = valuesProperties && valuesProperties[LAS_BACKEND_NAME]
        const values = { [LAS_BACKEND_NAME]: crsValues, ...lasSettings, ...visualizationSettings }
        return validateJobOptionsForm(values, LasAllSettingsTemplate, state, protoArtifact.id)
      })
    })
}

/**
 * Defines whether the user can, in theory, visit the `step`.
 */
/*
function isStepEnabled (step, props) {
  const { anyProtoArtifactsGenerated, noGroundControlPoints, noCamArtifacts } = props
  if (step === filesStep) {
    return true
  }
  if (step === imageStep) {
    return !noCamArtifacts
  }
  if (step === referenceStationStep) {
    return true
  }
  if (step === groundControlPointsStep) {
    return !noGroundControlPoints
  }
  if (step === summaryStep) {
    return anyProtoArtifactsGenerated
  }
}
*/

/**
 * Defines whether the user can continue forward from `step`.
 */
// function isStepValid (step: number, props: ImportWizardProps)
/*
export function isStepValid (step, props) {
  const {
    filesValid,
    cameraArtifactsValid,
    referenceStationsValid,
    groundControlPointsConfigured,
  } = props
  if (step === filesStep) {
    return filesValid
  }
  // Recursively check the previous steps.
  if (!isStepValid(step - 1, props)) {
    return false
  }
  if (step === imageStep) {
    return cameraArtifactsValid
  }
  if (step === referenceStationStep) {
    return referenceStationsValid
  }
  if (step === groundControlPointsStep) {
    return groundControlPointsConfigured
  }
  if (step === summaryStep) {
    return true
  }
}
*/

// function getBackStep(props: ImportWizardProps): number {
/*
export function getBackStep (props) {
  const { step } = props
  const prevStep = step - 1
  if (step === filesStep) {
    return undefined
  }
  if (!isStepEnabled(prevStep, props)) {
    return getBackStep({ ...props, step: prevStep })
  }
  return prevStep
}
*/

// function getForwardStep(props: ImportWizardProps): number
/*
export function getForwardStep (props) {
  const { step } = props
  const nextStep = step + 1
  if (step === summaryStep) {
    return undefined
  }
  console.log('isStepEnabled', nextStep, isStepEnabled(nextStep, props))
  if (!isStepEnabled(nextStep, props)) {
    return getForwardStep({ ...props, step: nextStep })
  }
  return nextStep
}

export function canContinue (props) {
  const { step } = props
  console.log('isStepValid', step, isStepValid(step, props))
  return isStepValid(step, props) && (step === summaryStep || typeof getForwardStep(props) !== 'undefined')
}
*/

/*
function mergeGCPColumnAssignments(
    prevAssignments: { [columnName: string]: number },
    columnName: string,
    columnIndex: number
)
*/
export function mergeGCPColumnAssignments (prevAssignments, columnName, columnIndex) {
  // Iterate over all existing assignments and drop all contradictong ones.
  return Object.keys(prevAssignments).reduce(
    (filtered, oldColumnName) => {
      const oldColumnIndex = prevAssignments[oldColumnName]
      // If the old assignment contradicts the new one - drop the old one.
      return oldColumnIndex === columnIndex
        ? filtered
        : { [oldColumnName]: oldColumnIndex, ...filtered }
    },
    // Start with the new assignment.
    { [columnName]: columnIndex }
  )
}

/**
 * This function returns an object that contains unmatchedFileSuggestions and unmatchedFiles arrays
 * @param files
 * @param protoArtifacts
 * @returns {unmatchedFileSuggestions, unmatchedFiles}
 */
// function getUnmatched(files: AnalyzedFile[], protoArtifacts: ProtoArtifact[])
export function getUnmatched (files, protoArtifacts) {
  const nonCameraArtifacts = protoArtifacts.filter(
    protoArtifact => !isCameraArtifact(protoArtifact.artifactType))
  let unmatchedFiles = files
  /*
  .filter(
    analyzedFile => nonCameraArtifacts.every(protoArtifact =>
      protoArtifact.fileSuggestions.every(suggestion => analyzedFile.file.name !== suggestion)
    )
  )
  */

  const unmatchedFileSuggestions = nonCameraArtifacts.flatMap(
    protoArtifact => protoArtifact.fileSuggestions.filter(suggestion =>
      suggestion !== '' && !files.some(analyzedFile => analyzedFile.file.name === suggestion)
    ),
  )

  /*
    Here we need to check if the suggestion file is rxp|sdc|sdcx.
    If yes, we can upload not exactly the same file with, for example .rxp type
    but .sdc or .sdcx to match this file suggestion.
    For example:
    We have an array of unmatchedFileSuggestions = ["20180926-201925_0.sdc"].
    So we can upload the files with the baseName equal to "20180926-201925_0":
    1) "20180926-201925_0.sdc"
    2) "20180926-201925_0.sdcx"
    3) "20180926-201925_0.rxp"
  */
  const { false: unmatchedSuggestions } = reducePartition(suggestion => {
    if (isRxpFileName(suggestion)) {
      const suggestionBaseName = getLdrBaseName(suggestion)
      const correspondFile = unmatchedFiles.find(file => {
        const fileName = path(['file', 'name'], file)
        return fileName
          ? isRxpFileName(fileName) && getLdrBaseName(fileName) === suggestionBaseName
          : false
      })

      // Here we need to remove file from unmatchedFiles
      if (correspondFile) {
        unmatchedFiles = unmatchedFiles.filter(file => file.file !== correspondFile.file)
      }
      return Boolean(correspondFile)
    }
    return false
  }, unmatchedFileSuggestions)

  return {
    unmatchedFileSuggestions: unmatchedSuggestions,
    unmatchedFiles,
  }
}

/**
 * detect IE
 */
export function isBrowserIE () {
  const ua = window.navigator.userAgent
  if (
    window.navigator.appName === 'Microsoft Internet Explorer' ||
      !!(
        ua.match(/Trident/) ||
          ua.match(/MSIE/) ||
          ua.match(/Edge/) ||
          ua.match(/rv:11/)
      )
  ) {
    return true
  }
  return false
}

export function getUploadIcon (file) {
  const { spacing } = theme
  if (isDropboxFile(file)) {
    return <img style={{ width: spacing(2), marginRight: spacing() }} src={DropBoxIcon} alt={'uploaded from dropbox'}/>
  }
  return null
}

export function getPrettyUploadType (file) {
  if (isDropboxFile(file)) {
    return i18n.t('uploaderType.fromDropbox')
  }
  return i18n.t('uploaderType.fromLocal')
}

export const getMissingLdrSuggestions = (missionType, files, dataFiles, suggestions, duplicateFiles) => {
  let missingFileSuggestionsForCurrentMission = {}
  let missingFileSuggestions = []
  const missionLdrFunctions = {
    [SystemType.PHOENIX_V3]: {
      getMissingLdrFilesBaseNameSuggestions: getMissingLdrFilesBaseNameSuggestions,
      getMissingLdrFiles: getMissingLdrFiles,
    },
    [SystemType.PHOENIX_V4_V5]: {
      getMissingLdrFilesBaseNameSuggestions: getMissionV4MissingLdrFileNamesSuggestions,
      getMissingLdrFiles: getMissionV4MissingLdrFiles,
    },
    [SystemType.HYSPEX]: {
      getMissingLdrFilesBaseNameSuggestions: getMissionV4MissingLdrFileNamesSuggestions,
      getMissingLdrFiles: getMissionV4MissingLdrFiles,
    },
  }

  if (missionType) {
    const functions = missionLdrFunctions[missionType] || missionLdrFunctions[SystemType.PHOENIX_V3]
    missingFileSuggestionsForCurrentMission = functions.getMissingLdrFilesBaseNameSuggestions(
      files,
      suggestions
    )
    missingFileSuggestions = functions.getMissingLdrFiles(
      files,
      dataFiles,
      suggestions,
      duplicateFiles
    )
  }
  return {
    missingFileSuggestionsForCurrentMission,
    missingFileSuggestions,
  }
}
