import { createActions } from 'reduxsauce'

const { Types, Creators } = createActions({
  // Retrieve the transformed coordinates
  getArtifactGCPS: ['options', 'artifactId'],
  getArtifactGCPSLoading: ['artifactId'],
  getArtifactGCPSSuccess: ['artifactId', 'gcps'],
  getArtifactGCPSFailure: ['errorMessage', 'artifactId'],

  // getProjects by CURRENT user
  getProjects: ['query'],
  getProjectsLoading: null,
  getProjectsSuccess: ['projects'],
  getProjectsFailure: ['errorMessage'],

  // getProjects with minimal info by CURRENT user
  /*
  getMinimalProjects: null,
  getMinimalProjectsLoading: null,
  getMinimalProjectsSuccess: ['projects'],
  getMinimalProjectsFailure: ['errorMessage'],
  */

  // getProjects by user
  /*
  getProjectsByUser: ['userId'],
  getProjectsByUserLoading: null,
  getProjectsByUserSuccess: ['projects'],
  getProjectsByUserFailure: ['errorMessage'],
  */

  // getProject a single Project
  /*
  getProject: ['projectId'],
  getProjectLoading: null,
  getProjectSuccess: ['project'],
  getProjectFailure: ['errorMessage'],
  */

  // create a new Project
  createProject: ['userId', 'projectData', 'crsData', 'gcpType', 'polygonType', 'onSuccess'],
  createProjectLoading: null,
  createProjectSuccess: null,
  createProjectFailure: ['errorMessage'],

  // transfer project from production to develop
  transferProject: ['projectUrl', 'transferType', 'projectIdToMerge', 'userId'],
  transferProjectLoading: null,
  transferProjectSuccess: ['companyId', 'project'],
  transferProjectFailure: ['errorMessage'],

  // updateProject
  updateProject: ['projectId', 'data'],
  updateProjectSuccess: ['projectId', 'project'],
  // deleteProject
  deleteProject: ['companyId', 'projectId'],
  deleteProjectLoading: ['projectId'],
  deleteProjectSuccess: ['projectId', 'companyId'],
  deleteProjectFailure: ['errorMessage', 'projectId'],
  // deleteProjects
  deleteProjects: ['companyId', 'projectIds', 'redirect'],
  deleteProjectsLoading: ['projectIds'],
  deleteProjectsSuccess: ['projectIds', 'companyId'],
  deleteProjectsFailure: ['errorMessage', 'projectIds'],

  // deleteUserProjects
  deleteUserProjects: ['companyId', 'userId', 'projectIds', 'redirect'],
  deleteUserProjectsLoading: ['userId', 'projectIds'],
  deleteUserProjectsSuccess: ['userId', 'projectIds', 'companyId'],
  deleteUserProjectsFailure: ['userId', 'errorMessage', 'projectIds'],

  // search index generator
  generateSearchIndex: ['projects'],

  // get pipelines for a project
  getPipelines: ['projectId'],
  getPipelinesLoading: null,
  getPipelinesSuccess: ['pipelines'],
  getPipelinesFailure: ['errorMessage'],

  // get current project data
  getCurrentProjectData: ['projectId'],
  getCurrentProjectDataLoading: null,
  getCurrentProjectDataSuccess: [
    'projectId',
    'pipelines',
    'artifacts',
    'missions',
    'project',
    'jobs',
    'jobIos',
  ],
  getCurrentProjectDataFailure: ['errorMessage'],

  /*
  // get current project data for pipeline
  getCurrentProjectDataForPipeline: ['projectId'],
  getCurrentProjectDataForPipelineLoading: null,
  getCurrentProjectDataForPipelineSuccess: [
    'projectId',
    'pipelines',
    'artifacts',
    'project',
    'dataDirectories',
  ],
  getCurrentProjectDataForPipelineFailure: ['errorMessage'],

  // get current project data for artifact
  getCurrentProjectDataForArtifact: ['projectId'],
  getCurrentProjectDataForArtifactLoading: null,
  getCurrentProjectDataForArtifactSuccess: ['project', 'artifacts', 'missions'],
  getCurrentProjectDataForArtifactFailure: ['errorMessage'],
  */

  // get all pipelines for a user
  getAllPipelines: null,
  getAllPipelinesLoading: null,
  getAllPipelinesSuccess: ['allPipelines'],
  getAllPipelinesFailure: ['errorMessage'],

  // finalize pipeline for a project
  finalizePipeline: ['pipelineId'],
  finalizePipelineLoading: null,
  finalizePipelineSuccess: ['pipelineId'],
  finalizePipelineFailure: ['errorMessage'],

  getPipeline: ['pipelineId'],
  getPipelineLoading: null,
  getPipelineSuccess: ['pipeline'],
  getPipelineFailure: ['errorMessage'],

  // get data directories for artifacts
  getDataDirectoriesForArtifacts: ['artifactIds'],
  getDataDirectoriesForArtifactsLoading: null,
  getDataDirectoriesForArtifactsSuccess: ['dataDirectoriesList'],
  getDataDirectoriesForArtifactsFailure: ['errorMessage'],

  // get dataFiles and dataDirectories for artifact
  getDataForArtifact: ['artifactId', 'withLoading', 'projectId'],
  getDataForArtifactLoading: ['artifactId'],
  getDataForArtifactSuccess: ['artifactId', 'artifact', 'dataDirectories', 'dataFiles'],
  getDataForArtifactFailure: ['artifactId', 'errorMessage'],

  // get additional data for pipeline (jobs, job_ios, job_runs)
  getDataForPipelineAndArtifacts: ['pipelineId'],
  getDataForPipelineAndArtifactsSuccess: ['pipelineId', 'pipeline'],
  // get additional data for pipeline (jobs, job_ios, job_runs)
  getDataForPipeline: ['pipelineId', 'withLoading', 'jobRunId', 'onSuccess'],
  getDataForPipelineLoading: ['pipelineId'],
  getDataForPipelineSuccess: ['pipelineId', 'pipeline'],
  getDataForPipelineFailure: ['pipelineId', 'errorMessage'],

  // getProjectsQueried
  getProjectsQueried: ['per_page', 'page', 'query'],
  getProjectsQueriedLoading: null,
  getProjectsQueriedSuccess: ['projects', 'pagination'],
  getProjectsQueriedFailure: ['errorMessage'],

  createMissionForCurrentProjectSuccess: ['mission'],
  createMissionForCurrentProjectFailure: ['errorMessage'],

  getDataForArtifacts: ['artifacts', 'projectId'],
  getDataForArtifactsLoading: null,
  getDataForArtifactsSuccess: ['artifacts', 'dataDirectories', 'dataFiles'],
  getDataForArtifactsFailure: ['errorMessage'],

  getDataForEditMissionArtifacts: ['mission', 'artifacts'],
  getDataForEditMissionArtifactsLoading: null,
  getDataForEditMissionArtifactsSuccess: null,
  getDataForEditMissionArtifactsFailure: ['errorMessage'],

  getPipelinesForCurrentProject: ['projectId', 'withLoading'],
  getPipelinesForCurrentProjectLoading: null,
  getPipelinesForCurrentProjectSuccess: ['projectId', 'pipelines'],
  getPipelinesForCurrentProjectFailure: ['errorMessage'],

  getRecursivePipelinesForCurrentProject: ['projectId', 'pipelinesIds'],
  getRecursivePipelinesForCurrentProjectLoading: ['projectId', 'pipelinesIds'],
  getRecursivePipelinesForCurrentProjectSuccess: ['projectId', 'pipelinesIds', 'pipelines'],
  getRecursivePipelinesForCurrentProjectFailure: ['projectId', 'pipelinesIds', 'errorMessage'],

  getUTMZone: ['projectId'],
  getUTMZoneLoading: ['projectId'],
  getUTMZoneSuccess: ['projectId', 'utmZone'],
  getUTMZoneFailure: ['projectId', 'errorMessage'],

  getProjectArtifacts: ['projectId'],
  getProjectArtifactsLoading: null,
  getProjectArtifactsSuccess: ['projectId', 'artifacts'],
  getProjectArtifactsFailure: ['errorMessage'],

  createProjectCRS: ['projectId', 'crs', 'useDefaultCRS', 'isPredefined', 'redirect', 'success'],
  createProjectCRSLoading: ['projectId'],
  createProjectCRSSuccess: ['projectId', 'crs'],
  createProjectCRSFailure: ['projectId'],

  getProjectDownloadToken: ['projectId'],
  getMissionDownloadToken: ['missionId'],
  getDownloadTokenLoading: ['id'],
  getDownloadTokenSuccess: ['id', 'token'],
  getDownloadTokenFailure: ['id'],
  downloadProject: ['jsonData', 'onSuccess'],
  downloadMission: ['missionId'],

  addMissions: ['missions'],

  setArtifactProperties: ['artifactId', 'properties'],
})

export const ProjectsTypes = Types
export default Creators
