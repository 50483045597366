import React from 'react'
import { isLocalEnvironment } from 'config'
import { removeStorageItem } from 'utils/localStorage'
import { removeAuthCookies } from 'api/pages/api'
import { Loader } from 'components/Loader'
import { getUserAuthToken } from 'utils/users'
import { showErrorMessage } from 'utils/api'

/**
 * RemoveCookies component to handle the removal of authentication tokens
 * from local storage and cookies, and to notify the parent window.
 *
 * This function performs the following actions:
 * - If running in a local environment, removes the 'tokenAccounts' item from local storage.
 * - Removes a specific cookie by name across the '.lidarmill.com' domain.
 * - Sends a 'token-remove' message to the parent window, if it exists.
 *
 */
const RemoveCookies = props => {
  if (isLocalEnvironment()) {
    removeStorageItem('tokenAccounts')
  } else {
    const { location } = props
    const urlParams = new URLSearchParams(location.search)
    const redirectUrl = urlParams.get('redirectUrl')
    const token = getUserAuthToken()
    if (token) {
      removeAuthCookies(token)
        .then(() => {
          if (redirectUrl) {
            window.location.href = redirectUrl
          }
        })
        .catch(e => showErrorMessage(e))
    } else {
      window.location.replace(redirectUrl)
    }
  }

  return <Loader />
}

export default RemoveCookies