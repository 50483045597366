export function baseName (path) {
  // https://stackoverflow.com/questions/3820381/need-a-basename-function-in-javascript
  return path.split(/[\\/]/).pop()
}

/**
 * Returns the file extension from the fileName
 * @param {string} fileName
 */
export function getFileExtension (fileName) {
  const splittedFileName = fileName.split('.')
  return splittedFileName[splittedFileName.length - 1]
}

export function getBaseName (fileName) {
  // const result = /(?:\.([^.]+))?$/i.exec(fileName)
  // return result === null ? undefined : result[1]
  return fileName.substr(0, fileName.lastIndexOf('.'))
}

export function isFileNameEndsWith (filename, extension) {
  return (filename || '').toLowerCase().endsWith((extension || '').toLowerCase())
}

// TODO: use it everywhere where datafile name and filename are compared
export function replaceMultipleWhiteSpaces (fileName) {
  return (fileName || '')
    .normalize('NFC')
    // we need to replace ascii chars (0-31 and 127) with empty space because we can't use
    // file names with such chars in s3 in ContentDesposition header
    // which will lead to the crash on the backend and thus on the frontend
    .replace(/[\x00-\x1F\x7F]/g, '')
    // Also replace multiple whitespaces with one
    .replace(/\s+/g, ' ')
    // Trim leading and trailing spaces
    .trim()
}
