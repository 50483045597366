import { call, put, takeLatest, fork, select } from 'redux-saga/effects'
import axios from 'utils/axios'
import _ from 'lodash'
import { getErrorMessage } from 'utils/api'
// Local deps
import JobsActions, { JobsTypes } from './actions'
import { getProgressJobs } from './selectors'
import { areArraysEqual } from 'utils/sort'

// Sagas
function * getJobsForUser ({ userId, filter }) {
  yield put(JobsActions.getJobsForUserLoading())
  const progressJobs = yield select(state => getProgressJobs(state))
  try {
    const filterString = filter || 'status=running,waiting,scheduled,waiting_for_artifact'
    const { data: { data: jobs } } = yield call(axios.get, `/users/${userId}/jobs?${filterString}`)
    const newJobs = Array.isArray(jobs) ? jobs : jobs.data.data
    // Update the progress jobs only if they are changed
    if (!areArraysEqual(newJobs, progressJobs)) {
      yield put(JobsActions.getJobsForUserSuccess(newJobs))
    }
  } catch (e) {
    yield put(JobsActions.getJobsForUserFailure(getErrorMessage(e)))
  }
}

function * getJobsForUserWatcher () {
  yield takeLatest(JobsTypes.GET_JOBS_FOR_USER, getJobsForUser)
}

export default function * root () {
  yield fork(getJobsForUserWatcher)
}
